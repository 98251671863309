import { useState } from 'react'
import axios from 'axios'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, } from '@pankod/refine-mui'
import Swal from "sweetalert2"
import { Columns, Info, PropsInfo,PropsDialog } from 'interfaces/datagrid'
import DataGridFournisseurs from 'components/Datagrids/DataGridFournisseurs'
import { useCtxt } from 'contexts/app.context'
const infoRole: Info = {
    addText: 'Nouveau fournisseur',
    addIcon: <AddIcon />,
    DialogTitle: 'Ajouter un fournisseur',
    DialogUpdate: "Modifier un fournisseur",
    DialogDescription: "La création d'un profile fournisseur et sa configuration",
    DialogUpdateDescription: "La modification d'un profile fournisseur et sa configuration"
  }
  
const Fournisseurs = () => {
  const { ctxt:{user} } = useCtxt();
  const propsInfo: PropsInfo = {
    add: (user?.role === "admin" || user?.permission.fournisseurs[3]=== "1") ? true : false,
    delete: (user?.role === "admin" || user?.permission.fournisseurs[2]=== "1") ? true : false,
    edit: (user?.role === "admin" || user?.permission.fournisseurs[1]=== "1") ? true : false
  }
    const [refresh, setrefresh] = useState(false)
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<any>({});
    
    const propsDialog: PropsDialog = {
      openUpdate: open,
      setOpenUpdate: setOpen,
      item: item,
      setItem: setItem,
    }
    const columns: Columns[] = [
        { field: 'id', headerName: 'ID', type: 'number', hide: true },
        {
          field: 'raisonSocial',
          headerName: 'Raison social',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          width: 200,
        },
        {
            field: 'gerant',
            headerName: 'Gérant',
            type: 'string',
            editable: false,
            add: true,
            edit: true,
            width: 200,
          },
          {
            field: 'conditionPaiement',
            headerName: 'Condition de paiment',
            type: 'select',
            editable: false,
            add: true,
            edit: true,
            width: 200,
            hide: true,
          },
        {
          field: 'adresse',
          headerName: 'Lieu',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          width: 200
        },
          {
            field: 'phone_number',
            headerName: 'Numéro de téléphone',
            type: 'string',
            editable: false,
            add: true,
            edit: true,
            flex: 1
          },
        {
          field: "actions",
          headerName: "Actions",
          type: 'string',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfo.edit && !propsInfo.delete,
          width: 100,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
                let a = {...params.row, ...params.row.Client}
              setItem(a)
              setOpen(true);
            }}>
              { infoRole.editIcon ? infoRole.editIcon : <EditIcon />  }
            </div>}
            {propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
              deleteOne(params.row.id,params.row.raisonSocial)
            }}>
              { infoRole.deleteIcon ? infoRole.deleteIcon : <DeleteIcon />  }
            </div>}
            </Box>;
          }
        }
      ]
      const deleteOne = (id : Number, name: string) => {
        Swal.fire({
          titleText: `Supprimer ${name}  ?`,
          text: `Souhaitez-vous vraiment supprimer ${name} ? Cette opération est irréversible.`,
          showCancelButton: true,
          cancelButtonText: `Annuler`,
          confirmButtonText: 'Supprimer',
          reverseButtons: true,
          customClass: {
            title: 'sw-delete-title',
            cancelButton: 'sw-delete-cancel',
            confirmButton: 'sw-delete-confirm',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete(`/api/fournisseurs/${id}`,{withCredentials: true})
              .then((res) => {
                if (res.status === 200) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `${name} a bien été supprimé`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  setrefresh(!refresh)
                } else {
                  Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `${name} n'a pas été supprimé`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              })
              .catch((err) => {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `${name} n'a pas été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          } 
        })
        
      };
      
      const addOne = (values: any) => {
        console.log(values)
        axios.post('/api/fournisseurs', values, {withCredentials: true}).then((res) => {
          if (res.status === 201) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.raisonSocial} a bien été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          } 
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.raisonSocial} n'a pas été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }).catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.raisonSocial} n'a pas été ajouté`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
      const updateOne = (values: any) => {
        console.log(values)
        axios.put(`/api/fournisseurs/${values.id}`, values, {withCredentials: true}).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.raisonSocial} a bien été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          }
           else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.raisonSocial} n'a pas été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            if (res.status === 400) {
              console.log('bad req')
            }
           } 
        }).catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.raisonSocial} n'a pas été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
  return (
    <Box >
        <DataGridFournisseurs
            refreshParent={refresh}
            {...propsInfo}
            {...propsDialog}
            addFunction={addOne}
            editFunction={updateOne}
            fetchurl="/api/fournisseurs"
            columns={columns}
            info={infoRole}
        />
    </Box>
  )
}

export default Fournisseurs