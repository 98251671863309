import * as React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,DialogContentText, DataGrid,TextField, GridRenderCellParams,
   InputAdornment,IconButton, Typography, FormControl, Grid,GridRowId, frFR } from "@pankod/refine-mui";
import axios, { AxiosResponse } from 'axios'
import { BACKENDINFO, IData } from 'interfaces/datagrid'
import InsertChartIcon from '@mui/icons-material/InsertChart';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Swal from "sweetalert2"
const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
}

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      }

    default:
      return state
  }
}

export default function DataGridRole({
  columns,
  datagridProps,
  info,
  add,
  edit,
  fetchurl,
  refreshParent,
  addFunction,
  editFunction,
  item,
  setItem,
  openUpdate,
  setOpenUpdate,
  setrefreshParent

}: Partial<IData>) {
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const links = [
        {id: 1,label: "Dashboard",name:"dashboard", icon: <InsertChartIcon />, },
        {id: 2,label: "Commande",name:"commande", icon: <InsertChartIcon />,},
        {id: 3,label: "Produit",name:"produits", icon: <InsertChartIcon />},
        {id: 4,label: "Mouvement de stock",name:"mouvementStock", icon: <InsertChartIcon />,},
        {id: 5,label: "Etat de stock",name:"etatStock", icon: <InsertChartIcon />, },
        {id: 6,label: "Trésorie",name:"tresorerie", icon: <InsertChartIcon />,},
        {id: 7,label: "Clients",name:"clients", icon: <InsertChartIcon />},
        {id: 8,label: "fournisseur",name:"fournisseurs", icon: <InsertChartIcon />},
        {id: 9,label: "Gestion utilisateurs",name:"gestionUtilisateurs", icon: <InsertChartIcon />},
        {id: 10,label: "paramètres",name:"parametres", icon: <InsertChartIcon />},
      ] 
  const [state, dispatch] = React.useReducer(reducer, initState)
  const [open, setOpen] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [states, setStates] = React.useState<any>()
  const [v,setV] = React.useState<number[][]>([[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0]])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setStates({})
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStates({ ...states, [event.target.name]: event.target.value })
  }
  const handleCheck = (a: number,b: number) => {
    const t = v
    if (b === 0 && t[a][0] === 0) {
      for (let i=0;i<5;i++){
        t[a][i] = 1
      }
    }
    else {
     t[a][b] = (t[a][b] === 0 ? 1 : 0)
      if (b > 1 && t[a][b]===1 && t[a][1]===0){
        t[a][1] = 1
      }
    }
    let check = true;
    for (let i=1;i<5;i++){
      if (t[a][i] === 0) check = false
    }
    if (check){
      t[a][0] = 1
    }
    else {
      t[a][0] = 0
    }
    setV(t)
    setRefresh(!refresh)
  }
  //Update dependacies
  
  
  const handleChangeUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const getData = () => {
    axios
      .get(
        `${fetchurl}/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`, {withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          dispatch({ type: 'ROWS', payload: res.data })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    getData()
    
  }, [state.refresh, refreshParent])

  React.useEffect(() => {
    if (Object.keys(item).length > 0) {
      let t : any[] = []
      links.map((l) => {
        let sub = Array.from(item[l.name], Number)
        if (item[l.name] === "1111"){
          sub.unshift(1)
        }
        else {
          sub.unshift(0)
        }
        t.push(sub)
      })
      setV(t)
    }
  }, [item])
  const rights = [{id: 1, label:"afficher"},
  {id: 2, label:"modifier"},
  {id: 3, label:"supprimer"},
  {id: 4, label:"ajouter"},
]
const deleteBulk = () => {
  Swal.fire({
    titleText: `Supprimer les roles sélectionnés  ?`,
    text: `Souhaitez-vous vraiment supprimer ces roles ? Cette opération est irréversible.`,
    showCancelButton: true,
    cancelButtonText: `Annuler`,
    confirmButtonText: 'Supprimer',
    reverseButtons: true,
    customClass: {
      title: 'sw-delete-title',
      cancelButton: 'sw-delete-cancel',
      confirmButton: 'sw-delete-confirm',
    },
  }).then((result) => {
    if (result.isConfirmed) {
      axios.delete(`/api/roles/roles/bulk?rolesId=${JSON.stringify(selectionModel)}`,{withCredentials: true})
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `Les roles ont bien été supprimé`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefreshParent(!refreshParent)
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Les roles n'ont pas été supprimé`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `Les roles n'ont pas été supprimé`,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } 
  })
    
  };
  return (
    <Box sx={{ width: '100%' }}>
      {/* The Dialog Section */}
      {/* Dialog Button */}
      {info?.title && <h1>{info?.title}</h1>}
      {info?.description && <p>{info?.description}</p>}
      {add && (
        <>
          <Box sx={{width: "100%", display: "flex", alignItems:"center",gap:"20px", justifyContent:"flex-end", padding:"15px 0"}}>
            {selectionModel.length > 0 && <Button
                variant="contained"
                sx={{ background:"#F7EEEE", border:"2px solid #F4433680",borderRadius:"4px", color:"#F44336", fontWeight:"bold"}}
                onClick={deleteBulk}
            >
                Supprimer la sélection ({selectionModel.length})
            </Button>}
            <Button
                variant="contained"
                startIcon={info?.addIcon}
                onClick={handleClickOpen}
            >
                {info?.addText || 'Ajouter'}
            </Button>
          </Box>

          <Dialog open={open} onClose={handleClose} className="role-container" >
            <DialogTitle>{info?.DialogTitle}</DialogTitle>

            <DialogContent>
              <DialogContentText sx={{marginBottom:"10px"}}>{info?.DialogDescription}</DialogContentText>
              {columns
                ?.filter((e) => e.add)
                .map((column) => (
                  <TextField
                  key={column.field}
                    name={column.field}
                    label={column.headerName}
                    onChange={handleChange}
                    {...column.TextFieledProps}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                  />
                ))}
                <Grid container spacing={1} sx={{marginTop:"10px"}}>
                {links.map((item : any,index) => {
                    return (
                      <Grid item xs={6} key={item.id}>
                        <Box>
                            <Box sx={{display:"flex",gap:"0px", alignItems:"center", background:"#EFEFEF", borderRadius:"10px",padding:"9px 13px"}}>
                                <FormControlLabel
                                control={
                                  <Checkbox checked={v[index][0] === 0 ? false : true} onChange={() => {
                                    handleCheck(index,0)
                                  }} name="link" />
                                }
                                label=""
                              />
                              <Typography sx={{display:"flex",gap:"5px"}}> <Typography>{item.icon}</Typography> {item.label}</Typography>
                            </Box>
                            <Box sx={{ display:"flex", gap:"20px", paddingLeft:"50px"}}>
                                {rights.map((r) => {
                                    return (
                                        <Box key={r.id} sx={{display:"flex",gap:"0px", alignItems:"center"}}>
                                            <FormControlLabel
                                            control={
                                              <Checkbox checked={v[index][r.id]=== 0 ? false : true} onChange={() => {
                                                handleCheck(index,r.id)
                                              }} name="" />
                                            }
                                            label=""
                                          />
                                          <Typography sx={{ textTransform:"capitalize"}}>  {r.label}</Typography>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                        </Grid>
                    )
                })}
                </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (addFunction) addFunction({states,v})
                  handleClose()
                  setV([[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0]])
                }}
              >
                Enregistrer
              </Button>
            </DialogActions>
          </Dialog>
          {/* Update dialog */}
          {edit && <Dialog open={openUpdate || false} onClose={handleCloseUpdate} className="role-container" >
        <DialogTitle>{info?.DialogUpdate}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom:"10px"}}>{info?.DialogUpdateDescription}</DialogContentText>
          {columns?.map((column) => (
            <Box key={column.field}>
            {column.edit && (column.type==="string" || column.type==="number" ) && <TextField
                
                type={column.type}
                name={column.field}
                value={item[column.field]}
                label={column.headerName}
                onChange={handleChangeUpdate}
                {...column.TextFieledProps}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{''}</InputAdornment>
                  ),
                }}
              /> }
            </Box>
            
            
          ))}
          <Grid container spacing={1} sx={{marginTop:"10px"}}>
                {links.map((link : any,index) => {
                    return (
                      <Grid item xs={6} key={link.id}>
                        <Box>
                            <Box sx={{display:"flex",gap:"0px", alignItems:"center", background:"#EFEFEF", borderRadius:"10px",padding:"9px 13px"}}>
                                <FormControlLabel
                                control={
                                  <Checkbox checked={v[index][0]=== 0 ? false : true} onChange={() => {
                                    handleCheck(index,0)
                                  }} name="link" />
                                }
                                label=""
                              />
                              <Box>
                              <Typography sx={{display:"flex",gap:"5px"}}> <Typography>{link.icon}</Typography> {link.label}</Typography>
                              </Box>
                            </Box>
                            <Box sx={{ display:"flex", gap:"20px", paddingLeft:"50px"}}>
                                {rights.map((r) => {
                                    return (
                                        <Box key={r.id} sx={{display:"flex",gap:"0px", alignItems:"center"}}>
                                            <FormControlLabel
                                            control={
                                              <Checkbox checked={v[index][r.id]=== 0 ? false : true} onChange={() => {
                                                handleCheck(index,r.id)
                                              }} name="" />
                                            }
                                            label=""
                                          />
                                          <Typography sx={{ textTransform:"capitalize"}}>  {r.label}</Typography>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                        </Grid>
                    )
                })}
                </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdate}>Annuler</Button>
          <Button onClick={() => {
            if (editFunction){
              editFunction({item,v})
            }
            handleCloseUpdate()
            setV([[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0],[0,0,0,0,0]])
          }}>Mettre a jour</Button>
        </DialogActions>
      </Dialog>}
        </>
      )}
      {/* The Data Grid Section */}
      <Box sx={{width:"100%",height:500}}>
      <DataGrid
        checkboxSelection 
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={(data) =>
          dispatch({ type: 'FILTER', payload: data.items })
        }
        onSortModelChange={(data) =>
          dispatch({
            type: 'SORT',
            payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
          })
        }
        pagination
        paginationMode="server"
        onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
        onPageSizeChange={(data) =>
          dispatch({ type: 'PAGESIZE', payload: data })
        }
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rows={state.rows}
        rowCount={state.total}
        page={state.page}
        columns={columns}
        pageSize={state.pageSize}
        autoHeight={state.total < 5 ? true: false}
        rowsPerPageOptions={[5, 10, 20, 50]}
        onSelectionModelChange={(selection) => {
          setSelectionModel(selection);
        }}
        {...datagridProps}
      />
      </Box>
    </Box>
  )
}
