import { useState } from 'react'
import axios from 'axios'
import DataGridSettings from 'components/Datagrids/DataGridSettings'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box } from '@pankod/refine-mui'
import Swal from "sweetalert2"
import { Columns, Info, PropsInfo,PropsDialog } from 'interfaces/datagrid'
import DataGridSettingsCompte from 'components/Datagrids/DataGridSettingsCompte'
import {  useCtxt } from "contexts/app.context";
import DataGridSettingsCondition from 'components/Datagrids/DataGridSettingsCondition'
const infoCondition: Info = {
    title: "Condition de paiement",
    addText: 'Ajouter',
    addIcon: <AddIcon />,
    DialogTitle: 'Nouvelle condition de paiement',
    DialogUpdate: "Mettre a jour la condition de paiement",
    type: "condition_paiement",
  }
  const infoCompte: Info = {
    title: "Compte",
    addText: 'Ajouter',
    addIcon: <AddIcon />,
    DialogTitle: "Nouveau compte",
    DialogUpdate: "Mettre a jour le compte",
    type: "compte"
  }
  const infoCategorie: Info = {
    title: "Catégorie de paiement",
    addText: 'Ajouter',
    addIcon: <AddIcon />,
    DialogTitle: "Nouvelle catégorie de paiement",
    DialogUpdate: "Mettre a jour la catégorie de paiement",
    type: "catégorie_paiement"
  }
  const infoBeneficaire: Info = {
    title: "Employés",
    addText: 'Ajouter',
    addIcon: <AddIcon />,
    DialogTitle: "Nouveau employé",
    DialogUpdate: "Mettre a jour un employé",
    type: "bénéficiaire"
  }
  const infoMode: Info = {
    title: "Mode de paiement",
    addText: 'Ajouter',
    addIcon: <AddIcon />,
    DialogTitle: "Nouveau mode de paiement",
    DialogUpdate: "Mettre a jour un mode de paiement",
    type: "mode_paiement"
  }
  
const Paiments = () => {
  const { ctxt:{user} } = useCtxt();
  const propsInfo: PropsInfo = {
    add: (user?.role === "admin" || user?.permission.parametres[3]=== "1") ? true : false,
    delete: (user?.role === "admin" || user?.permission.parametres[2]=== "1") ? true : false,
    edit: (user?.role === "admin" || user?.permission.parametres[1]=== "1") ? true : false
  }
  const propsInfoMode : PropsInfo = {
    add:  false,
    delete:  false,
    edit:  false
  }
    const [refresh, setrefresh] = useState(false)
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [item, setItem] = useState<any>({});
    
    const propsDialog1: PropsDialog = {
      openUpdate: open1,
      setOpenUpdate: setOpen1,
      item: item,
      setItem: setItem,
    }
    const propsDialog2: PropsDialog = {
      openUpdate: open2,
      setOpenUpdate: setOpen2,
      item: item,
      setItem: setItem,
    }
    const propsDialog3: PropsDialog = {
      openUpdate: open3,
      setOpenUpdate: setOpen3,
      item: item,
      setItem: setItem,
    }
    const propsDialog4: PropsDialog = {
      openUpdate: open4,
      setOpenUpdate: setOpen4,
      item: item,
      setItem: setItem,
    }
    const propsDialog5: PropsDialog = {
      openUpdate: open5,
      setOpenUpdate: setOpen5,
      item: item,
      setItem: setItem,
    }
    
    
    const deleteOne = (id : Number, name: string) => {
      Swal.fire({
        titleText: `Supprimer ${name}  ?`,
        text: `Souhaitez-vous vraiment supprimer ${name} ? Cette opération est irréversible.`,
        showCancelButton: true,
        cancelButtonText: `Annuler`,
        confirmButtonText: 'Supprimer',
        reverseButtons: true,
        customClass: {
          title: 'sw-delete-title',
          cancelButton: 'sw-delete-cancel',
          confirmButton: 'sw-delete-confirm',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/settings/${id}`,{withCredentials: true})
            .then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `${name} a bien été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
                setrefresh(!refresh)
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `${name} n'a pas été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              console.log(err)
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${name} n'a pas été supprimé`,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } 
      })
      
    };
    
    const addOne = (values: any, type: string) => {
      console.log({...values, type: type})
      axios.post('/api/settings', {...values, type: type}, {withCredentials: true}).then((res) => {
        if (res.status === 201) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `${values.name} a bien été ajouté`,
            showConfirmButton: false,
            timer: 1500,
          });
          setrefresh(!refresh)
        } 
        else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.name} n'a pas été ajouté`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }).catch((e) => {
        console.log(e)
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${values.name} n'a pas été ajouté`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
  
    }
    const updateOne = (values: any) => {
      axios.put(`/api/settings/${values.id}`, {name:values.name}, {withCredentials: true}).then((res) => {
        if (res.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `${values.name} a bien été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
          setrefresh(!refresh)
        }
         else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.name} n'a pas été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
          if (res.status === 400) {
            console.log('bad req')
          }
         } 
      }).catch((e) => {
        console.log(e)
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${values.name} n'a pas été mis a jour`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
  
    }
    const columnsCondition: Columns[] = [
      { field: 'id', headerName: 'ID', type: 'number', hide: true },
      {
        field: 'name',
        headerName: 'Jours',
        type: 'number',
        editable: false,
        add: true,
        edit: true,
        flex: 1,
        renderCell: (params) => {
          return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
            {params.row.name} jours
          </Box>;
        }
      },
      {
        field: "actions",
        headerName: "Actions",
        type: 'string',
        sortable: false,
        filterable: false,
        editable: false,
        hide: !propsInfo.edit && !propsInfo.delete,
        renderCell: (params) => {
          return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
            {propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
            setItem(params.row)
            setOpen1(true);
          }}>
            { infoCondition.editIcon ? infoCondition.editIcon : <EditIcon />  }
          </div>}
          {propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
           deleteOne(params.row.id, params.row.name)
          }}>
            { infoCondition.deleteIcon ? infoCondition.deleteIcon : <DeleteIcon />  }
          </div>}
          </Box>;
        }
      }
    ]
    
    
    
    const columnsCompte: Columns[] = [
        { field: 'id', headerName: 'ID', type: 'number', hide: true },
        {
          field: 'name',
          headerName: 'Nom',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
        },
        {
          field: 'balance',
          headerName: 'Balance',
          type: 'number',
          editable: false,
          add: true,
          edit: false,
          flex: 1,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {params.row.balance} DZD
            </Box>;
          }
        },
        {
          field: "actions",
          headerName: "",
          type: 'string',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfo.edit && !propsInfo.delete,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
              setItem(params.row)
              setOpen2(true);
            }}>
              { infoCompte.editIcon ? infoCompte.editIcon : <EditIcon />  }
            </div>}
            {propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
              deleteOneCompte(params.row.id, params.row.name)
            }}>
              { infoCompte.deleteIcon ? infoCompte.deleteIcon : <DeleteIcon />  }
            </div>}
            </Box>;
          }
        }
      ]
      const columnsCategorie: Columns[] = [
        { field: 'id', headerName: 'ID', type: 'number', hide: true },
        {
          field: 'name',
          headerName: 'Nom',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          flex: 1,
        },
        {
          field: "actions",
          headerName: "",
          type: 'string',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfo.edit && !propsInfo.delete,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
              setItem(params.row)
              setOpen3(true);
            }}>
              { infoCategorie.editIcon ? infoCategorie.editIcon : <EditIcon />  }
            </div>}
            {propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
              deleteOne(params.row.id, params.row.name)
            }}>
              { infoCategorie.deleteIcon ? infoCategorie.deleteIcon : <DeleteIcon />  }
            </div>}
            </Box>;
          }
        }
      ]
      const columnsBeneficaire: Columns[] = [
        { field: 'id', headerName: 'ID', type: 'number', hide: true },
        {
          field: 'name',
          headerName: 'Nom',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          flex: 1,
        },
        {
          field: "actions",
          headerName: "",
          type: 'string',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfo.edit && !propsInfo.delete,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
              setItem(params.row)
              setOpen4(true);
            }}>
              { infoBeneficaire.editIcon ? infoBeneficaire.editIcon : <EditIcon />  }
            </div>}
            {propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
              deleteOne(params.row.id, params.row.name)
            }}>
              { infoBeneficaire.deleteIcon ? infoBeneficaire.deleteIcon : <DeleteIcon />  }
            </div>}
            </Box>;
          }
        }
      ]
      const columnsMode: Columns[] = [
        { field: 'id', headerName: 'ID', type: 'number', hide: true },
        {
          field: 'name',
          headerName: 'Type',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          flex: 1,
        },
        {
          field: "actions",
          headerName: "",
          type: 'string',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfoMode.edit && !propsInfoMode.delete,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {propsInfoMode.edit && <div style={{"cursor":"pointer"}} onClick={() => {
              setItem(params.row)
              setOpen5(true);
            }}>
              { infoMode.editIcon ? infoMode.editIcon : <EditIcon />  }
            </div>}
            {propsInfoMode.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
              deleteOne(params.row.id, params.row.name)
            }}>
              { infoMode.deleteIcon ? infoMode.deleteIcon : <DeleteIcon />  }
            </div>}
            </Box>;
          }
        }
      ]
      const deleteOneCompte = (id : Number, name: string) => {
        Swal.fire({
          titleText: `Supprimer ${name}  ?`,
          text: `Souhaitez-vous vraiment supprimer ${name} ? Cette opération est irréversible.`,
          showCancelButton: true,
          cancelButtonText: `Annuler`,
          confirmButtonText: 'Supprimer',
          reverseButtons: true,
          customClass: {
            title: 'sw-delete-title',
            cancelButton: 'sw-delete-cancel',
            confirmButton: 'sw-delete-confirm',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete(`/api/comptes/${id}`,{withCredentials: true})
              .then((res) => {
                if (res.status === 200) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `${name} a bien été supprimé`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  setrefresh(!refresh)
                } else {
                  Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `${name} n'a pas été supprimé`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              })
              .catch((err) => {
                console.log(err)
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `${name} n'a pas été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          } 
        })
        
      };
      
      const addOneCompte = (values: any, type: string) => {
        console.log(values)
        axios.post('/api/comptes', {...values, balance: Number(values.balance)}, {withCredentials: true}).then((res) => {
          if (res.status === 201) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.name} a bien été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          } 
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.name} n'a pas été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }).catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.name} n'a pas été ajouté`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
      const updateOneCompte = (values: any) => {
        axios.put(`/api/comptes/name/${values.id}`, values, {withCredentials: true}).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.name} a bien été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          }
           else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.name} n'a pas été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            if (res.status === 400) {
              console.log('bad req')
            }
           } 
        }).catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.name} n'a pas été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
      
  return (
    <Box sx={{ display:"flex", flexDirection:"column", gap:"20px"}}>
        <Box sx={{display:"flex",gap:"20px"}}>
          
            <Box sx={{ flex:"1"}}>
                <DataGridSettingsCondition
                    refreshParent={refresh}
                    {...propsInfo}
                    {...propsDialog1}
                    addFunction={addOne}
                    editFunction={updateOne}
                    fetchurl="/api/settings/?type=condition_paiement&"
                    columns={columnsCondition}
                    info={infoCondition}
                />
            </Box>
            <Box sx={{ flex:"1"}}>
                <DataGridSettings
                    refreshParent={refresh}
                    {...propsInfoMode}
                    {...propsDialog5}
                    addFunction={addOne}
                    editFunction={updateOne}
                    fetchurl="/api/settings/?type=mode_paiement&"
                    columns={columnsMode}
                    info={infoMode}
                />
            </Box>
        </Box>
        <Box sx={{display:"flex",gap:"20px"}}>
            <Box sx={{ flex:"1"}}>
                <DataGridSettings
                    refreshParent={refresh}
                    {...propsInfo}
                    {...propsDialog3}
                    addFunction={addOne}
                    editFunction={updateOne}
                    fetchurl="/api/settings/?type=catégorie_paiement&"
                    columns={columnsCategorie}
                    info={infoCategorie}
                />
            </Box>
            <Box sx={{ flex:"1"}}>
            <DataGridSettings
                refreshParent={refresh}
                {...propsInfo}
                {...propsDialog4}
                addFunction={addOne}
                editFunction={updateOne}
                fetchurl="/api/settings/?type=bénéficiaire&"
                columns={columnsBeneficaire}
                info={infoBeneficaire}
            />
            </Box>
        </Box>
        <Box sx={{display:"flex"}}>
          <Box sx={{ flex:"1"}}>
            <DataGridSettingsCompte
                refreshParent={refresh}
                {...propsInfo}
                {...propsDialog2}
                addFunction={addOneCompte}
                editFunction={updateOneCompte}
                fetchurl="/api/comptes/?"
                columns={columnsCompte}
                info={infoCompte}
            />
            </Box>
          </Box>
    </Box>
  )
}

export default Paiments