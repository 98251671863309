import * as React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,DialogContentText, DataGrid, FormHelperText, GridToolbarContainer, GridToolbarExport  ,TextField, GridRenderCellParams,
   InputAdornment,IconButton, Typography, FormControl, Select, Checkbox, MenuItem, InputLabel,GridRowId, frFR } from "@pankod/refine-mui";
import axios, { AxiosResponse } from 'axios'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { BACKENDINFO, IData } from 'interfaces/datagrid'
import { useCustom } from '@pankod/refine-core';
import {  useForm, SubmitHandler } from "@pankod/refine-react-hook-form";
import {  number, object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
}

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      }

    default:
      return state
  }
}
const registerSchema = object({
  raisonSocial: string()
    .nonempty('La raison sociale est obligatoire')
    .max(32, 'Maximum de characters est 32'),
    gerant: string()
    .nonempty('Le gerant est obligatoire')
    .max(32, 'Maximum de characters est 32'),
    conditionPaiement: string()
    .nonempty('Condition de paiement est obligatoire'),
    phone_number: string()
    .nonempty('Ce champ est obligatoire').min(10, 'format invalide')
    .max(10, 'format invalide'),
    adresse: string().nonempty('Adresse est obligatoire'),
});
type RegisterInput = TypeOf<typeof registerSchema>;

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
interface PostUniqueCheckResponse {
  isAvailable: boolean;
}
export default function DataGridFournisseurs({
  columns,
  datagridProps,
  info,
  add,
  edit,
  fetchurl,
  refreshParent,
  addFunction,
  editFunction,
  item,
  setItem,
  openUpdate,
  setOpenUpdate

}: Partial<IData>) {
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });
  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);
  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {

    if (addFunction && Object.keys(errors).length === 0) {
      addFunction({...values,latitude: "37.7749° N",longitude: "122.4194° W"})
      handleClose()
    } 
    
  };
  const [state, dispatch] = React.useReducer(reducer, initState)
  const [open, setOpen] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [checked, setChecked] = React.useState(true);

 
  const { data : modeData } = useCustom<PostUniqueCheckResponse>({
    url: `/api/settings/`,
    method: "get",
    config: {
        query: {
          type: "mode_paiement",
        },
    },
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
 
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setChecked(false)
    reset();
  }
  //Update dependacies
  
  const handleChangeUpdate = (event: any) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const getData = () => {
    axios
      .get(
        `${fetchurl}/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`,{withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          dispatch({ type: 'ROWS', payload: res.data })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    getData()
  }, [state.refresh, refreshParent])
  React.useEffect(() => {
    
  }, [item])
  return (
    <Box sx={{ width: '100%' }}>
      {/* The Dialog Section */}
      {/* Dialog Button */}
      {info?.title && <h1>{info?.title}</h1>}
      {info?.description && <p>{info?.description}</p>}
      {add && (
        <>
        <Box sx={{width: "100%", display:"flex", justifyContent:"space-between"}}>
            <Box>
                {/* <Button
                    variant="contained"
                    startIcon={info?.addIcon}
                    color="secondary"
                    onClick={() => {}}
                >
                    Exporter
                </Button> */}
            </Box>
            <Box sx={{ display: "flex", alignItems:"center",gap:"20px", justifyContent:"flex-end", padding:"15px 0"}}>
            {/* <Button
                variant="contained"
                sx={{ background:"#F7EEEE", border:"2px solid #F4433680",borderRadius:"4px", color:"#F44336", fontWeight:"bold"}}
                onClick={() => {}}
            >
                Supprimer ({selectionModel.length})
            </Button> */}
            <Button
                variant="contained"
                startIcon={info?.addIcon}
                onClick={handleClickOpen}
                color="secondary"
            >
                {info?.addText || 'Ajouter'}
            </Button>
          </Box>
        </Box>
          

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{info?.DialogTitle}</DialogTitle>
            {/*@ts-ignore */}
            <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}
              >
            <DialogContent>
              <DialogContentText>{info?.DialogDescription}</DialogContentText>
              <Box sx={{width:"100%", display:"grid", gridTemplateColumns:"1fr 1fr", gap:"10px 20px",marginTop:"10px"}}>
              {columns
                ?.filter((e) => e.add)
                .map((column) => (
                  <Box key={column.field}>
                  {column.add && (column.type==="string" || column.type==="number" ) && <TextField
                    label={column.headerName}
                    type={column.type}
                    required
                    error={!!errors[column?.field]}
                    helperText={errors[column?.field] && ` ${errors[column?.field]?.message}`}
                    {...register(column?.field)}
                    {...column.TextFieledProps}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                  />}
              {column.add && column.type==="select" && 
              <FormControl fullWidth>
              <InputLabel id={column.field}>{column.headerName}</InputLabel>
                <Select
                  labelId={column.field}
                  label="Condition de paiement"
                  required
                    error={!!errors[column?.field]}
                    {...register(column?.field)}
                  
                >
                  {/*@ts-ignore */}
                  {modeData?.data?.rows.map((v: any) => {
                      return (
                          <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                      )
                  })}
                  
                </Select>
                <FormHelperText error>{errors[column?.field] && ` ${errors[column?.field]?.message}`}</FormHelperText>
                </FormControl>
               }
              </Box>
                  
                ))}
                
                
                </Box>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
              <Button
                variant="contained"
                type="submit"
              >
                Enregistrer
              </Button>
            </DialogActions>
            </Box>
          </Dialog>
          {/* Update dialog */}
          {edit && <Dialog open={openUpdate || false} onClose={handleCloseUpdate} >
        <DialogTitle>{info?.DialogUpdate}</DialogTitle>
        <DialogContent>
          <DialogContentText>{info?.DialogUpdateDescription}</DialogContentText>
          <Box sx={{width:"100%", display:"grid", gridTemplateColumns:"1fr 1fr", gap:"10px 20px",marginTop:"10px"}}>
              {columns
                ?.filter((e) => e.edit)
                .map((column) => (
                  <Box key={column.field}>
                  {column.edit && (column.type==="string" || column.type==="number" ) && <TextField
                    id="outlined-start-adornment"
                    name={column.field}
                    label={column.headerName}
                    type={column.type}
                    value={item[column.field]}
                    onChange={handleChangeUpdate}
                    {...column.TextFieledProps}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                  />}
                  {column.edit && column.type==="password" && <TextField
                id="outlined-start-adornment"
                type={showPassword ? 'text' : 'password'}
                name={column.field}
                value={item[column.field]}
                label={column.headerName}
                onChange={handleChangeUpdate}
                {...column.TextFieledProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> }
              {column.edit && column.type==="select" && 
              <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                <Select
                key={column.field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="conditionPaiement"
                  label="Condition"
                  value={item.conditionPaiement}
                  onChange={handleChangeUpdate}
                >
                  {/*@ts-ignore */}
                  {modeData?.data?.rows.map((v: any) => {
                      return (
                          <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                      )
                  })}
                  
                </Select>
                </FormControl>
               }
              
              </Box>
                  
                ))}
                
                
                </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdate}>Annuler</Button>
          <Button onClick={() => {
            if (editFunction){
              delete item.Client
              editFunction({...item,latitude: "37.7749° N",
              longitude: "122.4194° W"})
            }
            handleCloseUpdate()
          }}>Mettre a jour</Button>
        </DialogActions>
      </Dialog>}
        </>
      )}
      {/* The Data Grid Section */}
      <Box sx={{width:"100%",height:500}}>
      <DataGrid
        checkboxSelection 
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={(data) =>
          dispatch({ type: 'FILTER', payload: data.items })
        }
        onSortModelChange={(data) =>
          dispatch({
            type: 'SORT',
            payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
          })
        }
        pagination
        paginationMode="server"
        onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
        onPageSizeChange={(data) =>
          dispatch({ type: 'PAGESIZE', payload: data })
        }
        rows={state.rows}
        rowCount={state.total}
        page={state.page}
        columns={columns}
        pageSize={state.pageSize}
        autoHeight={state.total < 5 ? true: false}
        rowsPerPageOptions={[5, 10, 20, 50]}
        {...datagridProps}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        onSelectionModelChange={(selection) => {
          setSelectionModel(selection);
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      </Box>
    </Box>
  )
}
