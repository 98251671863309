import * as React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,DialogContentText, DataGrid,TextField, 
   InputAdornment, Typography, frFR } from "@pankod/refine-mui";
import axios, { AxiosResponse } from 'axios'
import { BACKENDINFO, IDataSettings } from 'interfaces/datagrid'
import {  useForm, SubmitHandler } from "@pankod/refine-react-hook-form";
import {  number, object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
}

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      }

    default:
      return state
  }
}
const registerSchema = object({
  name: string()
    .nonempty('Ce champ est obligatoire')
    .max(32, 'Maximum de characters est 32'),
    balance: number({
        required_error: "balance est obligatoire",
      invalid_type_error: "balance est obligatoire",
    }).min(0,"Minimum 0 dans la balance")
});
type RegisterInput = TypeOf<typeof registerSchema>;

export default function DataGridSettingsCompte({
  columns,
  datagridProps,
  info,
  add,
  edit,
  fetchurl,
  refreshParent,
  addFunction,
  editFunction,
  item,
  setItem,
  openUpdate,
  setOpenUpdate

}: Partial<IDataSettings>) {
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });
  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);
  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    let type = info?.type || ""
    if (addFunction && Object.keys(errors).length === 0) {
      addFunction(values, type)
      handleClose()
    } 
    
  };
  const [state, dispatch] = React.useReducer(reducer, initState)
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    reset();
  }

  
  const handleChangeUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  const getData = () => {
    axios
      .get(
        `${fetchurl}filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state?.sort)}&size=${state.pageSize}&page=${
          state.page
        }`,{ withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          if (res.data.rows.length > 0){
            dispatch({ type: 'ROWS', payload: res.data })
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    getData()
  }, [state.refresh, refreshParent])
  return (
    <Box sx={{ width: '100%' }}>
      {/* The Dialog Section */}
      {/* Dialog Button */}
      <Box sx={{display:"flex", width: "100%", justifyContent:"space-between", padding:"30px 0"}}>
      {info?.title && <Typography sx={{ textTransform:"uppercase", fontSize:"1.2rem", fontWeight:"bold"}}>{info?.title}</Typography>}
        {add && <Button
            variant="contained"
            startIcon={info?.addIcon}
            onClick={handleClickOpen}
          >
            {info?.addText || 'Ajouter'}
          </Button>}
      </Box>
      
      {add && (
        <>
          

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{info?.DialogTitle}</DialogTitle>
            {/*@ts-ignore */}
            <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}
              >
            <DialogContent>
              <DialogContentText>{info?.DialogDescription}</DialogContentText>
              <Box sx={{ display:"flex", flexDirection:"column", gap:"10px", marginTop: "10px"}}>
              
              {columns
                ?.filter((e) => e.add)
                .map((column) => (
                  <Box  key={column.field}>
                  {column.type ==="string" && <TextField
                    fullWidth
                    label={column.headerName}
                    type={column.type}
                    {...column.TextFieledProps}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                    required
                    error={!!errors[column?.field]}
                    helperText={errors[column?.field] && ` ${errors[column?.field]?.message}`}
                    {...register(column?.field)}
                      />}
                      {column.add && (column.type==="number" ) && <TextField fullWidth
                      id={column.field}
                      label={column.headerName}
                      type={column.type}
                      required
                      error={!!errors[column?.field]}
                      helperText={errors[column?.field] && ` ${errors[column?.field]?.message}`}
                    {...register(column?.field,{ valueAsNumber: true })}
                      {...column.TextFieledProps}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">{'DZD'}</InputAdornment>
                        ),
                      }}
                    />}
                  </Box>
                ))}
                </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
              <Button
                type="submit"
                variant="contained"
              >
                Enregistrer
              </Button>
            </DialogActions>
            </Box>
          </Dialog>
          {/* Update dialog */}
          {edit && <Dialog open={openUpdate || false} onClose={handleCloseUpdate} >
        <DialogTitle>{info?.DialogUpdate}</DialogTitle>
        <DialogContent>
          <DialogContentText>{info?.DialogUpdateDescription}</DialogContentText>
          <Box sx={{ display:"flex", flexDirection:"column", gap:"10px", marginTop: "10px"}}>
          {columns?.map((column) => (
            <Box key={item.id}>
            {column.edit && (column.type==="string" || column.type==="number" ) && <TextField
                
                type={column.type}
                name={column?.field}
                value={item[column?.field]}
                label={column.headerName}
                onChange={handleChangeUpdate}
                {...column.TextFieledProps}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{''}</InputAdornment>
                  ),
                }}
              /> }
            </Box>
          ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdate}>Annuler</Button>
          <Button onClick={() => {
            if (editFunction){
              editFunction(item)
            }
            handleCloseUpdate()
          }}>Mettre a jour</Button>
        </DialogActions>
      </Dialog>}
        </>
      )}
      {/* The Data Grid Section */}
      <Box sx={{width:"100%",height:350}}>
      <DataGrid
        
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={(data) =>
          dispatch({ type: 'FILTER', payload: data.items })
        }
        onSortModelChange={(data) =>
          dispatch({
            type: 'SORT',
            payload: [[data[0]?.field, data[0]?.sort?.toLocaleUpperCase()]],
          })
        }
        pagination
        paginationMode="server"
        onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
        onPageSizeChange={(data) =>
          dispatch({ type: 'PAGESIZE', payload: data })
        }
        rows={state.rows}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rowCount={state.total}
        page={state.page}
        columns={columns}
        pageSize={state.pageSize}
        autoHeight={state.total < 5 ? true: false}
        rowsPerPageOptions={[5, 10, 20, 50]}
        {...datagridProps}
      />
      </Box>
      
    </Box>
  )
}
