import React, { useContext, useState, useEffect } from "react";
import {  useLogout ,useGetIdentity } from "@pankod/refine-core";
import {
  AppBar,
  Stack,
  Toolbar,
  Typography,
  TextField,InputAdornment,Box,Link
} from "@pankod/refine-mui";
import SearchIcon from '@mui/icons-material/Search';
import {  useCtxt } from "contexts/app.context";
import { IUser} from "interfaces/common"
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from "../../../assets/logo.svg"
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
export const Header: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:705px)');
  const { mutate: logout } = useLogout();
  const { ctxt, dispatch } = useCtxt();
  const { data: userInfo } = useGetIdentity<IUser>();

  const [show,setShow] = useState(false)
  
  useEffect(() => {
    dispatch({ type: "setUser",payload: userInfo })
  }, [userInfo])
  
  const { ctxt: {user, produitPanier} } = useCtxt();
  
  const [value, setValue] = React.useState('');
  const handleChange = (event :  React.ChangeEvent<HTMLInputElement>) => {
    setValue( event.target.value );
    dispatch({ type: "setRecherche",payload: value })
  };
  const handleRecherche = () => {
    dispatch({ type: "setRecherche",payload: value })
  }
  const shouldRenderHeader = true; // since we are using the dark/light toggle; we don't need to check if user is logged in or not.
  
  return shouldRenderHeader ? (
    <AppBar color="default" position="sticky" elevation={1} sx={{background: "#F6F6F6"}}>
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
          padding="10px 0"
          gap={isMobile ? "5px":"15px"}
          bgcolor="#F6F6F6"
          position="relative"
        >
          <Box sx={{width: "95px"}}>
            <img src={logo} alt="logo img" />
          </Box>
          <TextField
            id="input-with-icon-textfield"
            label="Recherche"
            name="recherche"
            placeholder="Recherche"
            style={{flex: "auto", background: "#ffffff"}}
            onChange={handleChange}
            InputProps={{
            endAdornment: (
                <InputAdornment position="start">
                <SearchIcon sx={{ cursor: "pointer"}} onClick={() => {
                  handleRecherche()
                }} />
                </InputAdornment>
            ),
            }}
            variant="outlined"
        />
          <Stack
            direction="row"
            gap="16px"
            alignItems="center"
            justifyContent="center"
          >
            {user? (
              <Box sx={{ backgroundColor: "#fff", padding: "12px", borderRadius: "10px", cursor: "pointer"}} color="#92CE08" onClick={() => {
                setShow(!show)
              }}>
                <Typography style={{ textTransform: "uppercase"}} >{user?.first_name.substring(0,2)}</Typography>
            </Box>
            ) : null}
          </Stack>
          {show && <Box sx={{ position:"absolute", top:"90%", right:"0%", display:"flex", flexDirection:"column", background:"#fff",minWidth:"350px", borderRadius:"16px", padding:"18px 14px", gap:"15px"}}>
              <Box sx={{ display:"flex", justifyContent:"space-between", gap:"50px"}}>
                <Typography sx={{ fontWeight:"bold"}}>Profile</Typography>
                <Typography sx={{color:"#6D6D6D", textTransform:"uppercase"}}>{user?.last_name} {user?.first_name}</Typography>
              </Box>
              <Box sx={{ display:"flex", justifyContent:"space-between", gap:"50px"}}>
                <Typography sx={{ fontWeight:"bold"}}>Email</Typography>
                <Typography sx={{color:"#6D6D6D"}}>{user?.email}</Typography>
              </Box>
              <Box sx={{ display:"flex", justifyContent:"space-between", gap:"50px"}}>
                <Typography sx={{ fontWeight:"bold"}}>Rôle</Typography>
                <Typography sx={{color:"#6D6D6D"}}>{user?.permission ? user.permission.role : "Admin"}</Typography>
              </Box>
              <Box sx={{ display:"flex", justifyContent:"space-between", gap:"50px"}}>
                <Typography sx={{ fontWeight:"bold"}}>Téléphone</Typography>
                <Typography sx={{color:"#6D6D6D"}}>{user?.phone_number}</Typography>
              </Box>
              <Box sx={{ display:"flex", gap:"10px", alignItems:"center", justifyContent: "space-between",background:"#FFE8E8",color:"#C80000",borderRadius:"9px", padding:"13px 17px",cursor:"pointer"}} onClick={() => {logout()}}>
                <Box sx={{display:"flex", gap:"10px", alignItems:"center"}}>
                <LogoutOutlinedIcon sx={{color:"#C80000"}} />
                <Typography sx={{fontSize: "12px", width:"max-content", textTransform: "uppercase"}}>Déconnexion</Typography>
                </Box>
                <KeyboardArrowRightOutlinedIcon />
            </Box>
          </Box>}
        </Stack>
      </Toolbar>
    </AppBar>
  ) : null;
};
