import { useState } from 'react'
import axios from 'axios'
import { Box } from '@pankod/refine-mui'
import Swal from "sweetalert2"
import { Columns, Info, PropsInfo,PropsDialog } from 'interfaces/datagrid'
import DataGridEtatStock from 'components/Datagrids/DataGridEtatStock'
import { useCtxt } from 'contexts/app.context'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
const infoRole: Info = {
    addText: 'régularisation de stock',
    addIcon: <CompareArrowsIcon />,
    DialogTitle:"régularisation stock"
  }
 
const EtatStock = () => {
  const { ctxt:{user} } = useCtxt();
  const propsInfo: PropsInfo = {
    add: (user?.role === "admin" || user?.permission.etatStock[3]=== "1") ? true : false,
    delete: (user?.role === "admin" || user?.permission.etatStock[2]=== "1") ? true : false,
    edit: (user?.role === "admin" || user?.permission.etatStock[1]=== "1") ? true : false
  }
    const [refresh, setrefresh] = useState(false)
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<any>({});
    
    const propsDialog: PropsDialog = {
      openUpdate: open,
      setOpenUpdate: setOpen,
      item: item,
      setItem: setItem,
    }
    const columns: Columns[] = [
        { field: 'id', headerName: 'N°', type: 'number', hide: true },
        {
          field: 'name',
          headerName: "Article",
          type: 'string',
          editable: false,
          width: 200,
          valueGetter: (params) => {
            return params.row.product?.name ?? ""
          }
        },
        {
          field: 'unit',
          headerName: "Unité de mesure",
          type: 'string',
          editable: false,
          width: 200,
          valueGetter: (params) => {
            return params.row.product?.unit ?? ""
          }
        },
        {
          field: 'collisage',
          headerName: 'Colisage',
          type: 'string',
          editable: false,
          width: 200,
          valueGetter: (params) => {
            return params.row.product?.collisage ?? ""
          }
        },
       
        {
          field: 'enStock',
          headerName: 'En stock',
          type: 'number',
          editable: false,
          width: 200,
        },
          {
            field: 'commandeClient',
            headerName: 'Commande Client',
            type: 'number',
            editable: false,
            width: 200,
          },
          {
            field: 'stockTheorique',
            headerName: 'Stock théorique',
            type: 'number',
            editable: false,
            width: 200,
          },
        
      ]
      
      const addOne = (values: any) => {
        console.log(values)
        axios.post('/api/auth/addClient', values, {withCredentials: true}).then((res) => {
          if (res.status === 201) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.name} a bien été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          } 
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.name} n'a pas été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }).catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.name} n'a pas été ajouté`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
      const updateOne = (values: any) => {
        console.log(values)
        axios.put(`/api/auth/updateClient/${values.userId}`, values, {withCredentials: true}).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.gerant} a bien été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          }
           else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.gerant} n'a pas été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
           } 
        })
        .catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.gerant} n'a pas été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
  return (
    <Box >
        <DataGridEtatStock
            refreshParent={refresh}
            {...propsInfo}
            {...propsDialog}
            addFunction={addOne}
            editFunction={updateOne}
            fetchurl="/api/stock"
            columns={columns}
            info={infoRole}
            setrefreshParent={setrefresh}
        />
    </Box>
  )
}

export default EtatStock