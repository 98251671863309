import React, { useState } from 'react'
import {  Grid, Box, Typography } from '@pankod/refine-mui'
import CachedIcon from '@mui/icons-material/Cached';
import imageInput from "../assets/imageInput.svg"
interface ImageUploadProps {
  onUpload: (file: File) => void
  image: string;
}


const FileUploadComponentUpdate: React.FC<ImageUploadProps> = ({ onUpload, image }) => {
  const [file, setFile] = useState<File | null>(null)
    console.log(image)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files
    if (fileList && fileList.length > 0) {
      const selectedFile = fileList[0]
      setFile(selectedFile)
      onUpload(selectedFile)
    }
  }

  return (
    <Grid container direction="column" alignItems="center" sx={{ position:"relative", width:"100%"}}>
         <label htmlFor="image-upload">
            <input
            accept="image/*"
            id="image-upload"
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            />
            { !file && image === undefined && <Box>
            <Box sx={{ padding:"10px", borderRadius:"50px", cursor:"pointer", background:"#E4E6EB", width:"fit-content", margin:"0 auto 10px"}}>
                <img src={imageInput} alt="image svg" />
            </Box>
                <Typography>Ajouter une photo</Typography> 
                <Typography sx={{ color:"#757575", fontSize:"0.8rem"}}>1000 px par 1000 px </Typography>
            </Box>}

            {(file || image) && <Box sx={{ position:"absolute",top:"20px", right:"20px", background:"#fff", borderRadius:"50%", cursor:"pointer", padding:"4px 7px 0px" }}>
                <CachedIcon />
            </Box>}
        </label>
      {file && (
        <Grid item>
            <Box sx={{ maxWidth:"100%"}} >
                <img
                src={URL.createObjectURL(file)}
                alt="Preview"
                style={{ width:"100%"}}
            />
            </Box>
          
        </Grid>
      )}
      {
        image && !file  && <img
        src={`/uploads/${image}`} 
        alt="produit"
        style={{ width:"100%"}}
        />
      }
    </Grid>
  )
}

export default FileUploadComponentUpdate
