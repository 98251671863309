import * as React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,DialogContentText, DataGrid,TextField,
   InputAdornment, Typography, FormControl, Select, MenuItem, InputLabel,GridRowId, frFR, FormHelperText, Switch } from "@pankod/refine-mui";
import axios, { AxiosResponse } from 'axios'
import { BACKENDINFO, IData } from 'interfaces/datagrid'

import { useCustom } from '@pankod/refine-core';
import Swal from 'sweetalert2';
import {  useForm, SubmitHandler } from "@pankod/refine-react-hook-form";
import {  number, any, object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import FileUploadComponent from 'components/FileUploadComponent';
import FileUploadComponentUpdate from 'components/FileUploadComponentUpdate';
const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
}

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      }

    default:
      return state
  }
}

const registerSchema = object({
  name: string()
    .nonempty('le nom est obligatoire'),
    unit: string()
    .nonempty("L'unité de mesure est obligatoire")
    .max(32, 'Maximum de characters est 32'),
    collisage: string()
    .nonempty('Collisage est obligatoire'),
    price: number({
      required_error: "prix est obligatoire",
      invalid_type_error: "prix est obligatoire",
    }).min(0,"prix doit etre > 0"),
    pricePromotion: any(),
    categoryId: number({
      invalid_type_error: "catégorie est obligatoire",
    }),
    note: any(),
    image: any()
})
type RegisterInput = TypeOf<typeof registerSchema>;
  interface PostUniqueCheckResponse {
    isAvailable: boolean;
  }
export default function DataGridProduits({
  columns,
  datagridProps,
  info,
  add,
  edit,
  fetchurl,
  refreshParent,
  addFunction,
  editFunction,
  item,
  setItem,
  openUpdate,
  setOpenUpdate,
  setrefreshParent

}: Partial<IData>) {
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });
  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);
  const onSubmitHandler: SubmitHandler<RegisterInput> = (values : any) => {
    console.log(values)
    console.log(errors)
    if (addFunction && Object.keys(errors).length === 0) {
      if (Number.isNaN(values.pricePromotion)){
        delete values.pricePromotion
      }
      addFunction({...values,image: image,active: checked})
      
      
      handleClose()
    } 
    
  };
  const [state, dispatch] = React.useReducer(reducer, initState)
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const [open, setOpen] = React.useState(false)
  const [uploaded, setUploaded] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [checked, setChecked] = React.useState(true);
  const [image, setImage] = React.useState<any>()
  const handleChangeCheck = (event : any) => {
    setChecked(event.target.checked)
  };
  const handleChangeCheckUpdate = (event : any) => {
    setItem({ ...item, [event.target.name]: event.target.checked })
  };
  
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setImage({})
    setUploaded(false)
    reset();
  }

  
  //Update dependacies
  
  const handleChangeUpdate = (event: any) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    setUploaded(false)
    setImage({})
    setUploaded(false)
  };
  
  
  const uploadImage = (file: File) => {
    console.log(file)
    if (file) {
      setImage(file)
    }
    else {
      console.log("error upload image")
    }
  }
  const uploadImageUpdate = (file: File) => {
    console.log(file)
    if (file) {
      setImage(file)
    }
    else {
      console.log("error upload image")
    }
  }
  const getData = () => {
    axios
      .get(
        `${fetchurl}/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`, {withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          dispatch({ type: 'ROWS', payload: res.data })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    getData()
  }, [state.refresh, refreshParent])
  React.useEffect(() => {
    if (Object.keys(item).length > 0){
      console.log(item)
      setRefresh(!refresh)
    }

  }, [item])
  const { data : uniteData } = useCustom<PostUniqueCheckResponse>({
    url: `/api/settings`,
    method: "get",
    config: {
        query: {
          type: "unité_mesure",
        },
    },
  });
  const { data : colisageData } = useCustom<PostUniqueCheckResponse>({
    url: `/api/settings`,
    method: "get",
    config: {
        query: {
          type: "colisage",
        },
    },
  });
  const { data : categorieData } = useCustom<PostUniqueCheckResponse>({
    url: `/api/settings`,
    method: "get",
    config: {
        query: {
          type: "catégorie_article",
        },
    },
  });
  const deleteBulk = () => {
    Swal.fire({
      titleText: `Supprimer les produits sélectionnés  ?`,
      text: `Souhaitez-vous vraiment supprimer ces produits ? Cette opération est irréversible.`,
      showCancelButton: true,
      cancelButtonText: `Annuler`,
      confirmButtonText: 'Supprimer',
      reverseButtons: true,
      customClass: {
        title: 'sw-delete-title',
        cancelButton: 'sw-delete-cancel',
        confirmButton: 'sw-delete-confirm',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`/api/products/bulk/products/?productsId=${JSON.stringify(selectionModel)}`,{withCredentials: true})
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: `Les produits ont bien été supprimé`,
                showConfirmButton: false,
                timer: 1500,
              });
              setrefreshParent(!refreshParent)
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: `Les produits n'ont pas été supprimé`,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Les produits n'ont pas été supprimé`,
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } 
    })
      
    };
    
  return (
    <Box sx={{ width: '100%' }} className="produits-container">
      {/* The Dialog Section */}
      {/* Dialog Button */}
      {info?.title && <h1>{info?.title}</h1>}
      {info?.description && <p>{info?.description}</p>}
      {add && (
        <>

        <Box sx={{width: "100%", display:"flex", justifyContent:"flex-end"}}>
            <Box sx={{ display: "flex", alignItems:"center",gap:"20px", justifyContent:"flex-end", padding:"15px 0"}}>
            {selectionModel.length > 0 && <Button
                variant="contained"
                sx={{ background:"#F7EEEE", border:"2px solid #F4433680",borderRadius:"4px", color:"#F44336", fontWeight:"bold"}}
                onClick={deleteBulk}
            >
                Supprimer ({selectionModel.length})
            </Button>}
            <Button
                variant="contained"
                startIcon={info?.addIcon}
                color="secondary"
                onClick={handleClickOpen}
            >
                {info?.addText || 'Ajouter'}
            </Button>
          </Box>
        </Box>
          

          <Dialog open={open} onClose={handleClose} className="produits-container">
            <DialogTitle>{info?.DialogTitle}</DialogTitle>
               {/*@ts-ignore */}
            <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}
              >
            <DialogContent>
              <DialogContentText>{info?.DialogDescription}</DialogContentText>
              <Box sx={{display:"grid", gridTemplateColumns:"1fr 2fr", gap:"10px 20px",marginTop:"10px", width:"100%"}}>
                    
                    <FormControl fullWidth>
                      <InputLabel id="unite">Unité de mesure</InputLabel>
                        <Select
                        labelId='unite'
                          label="Unité de mesure"
                          required
                          error={!!errors["unit"]}
                          {...register("unit")}
                        >
                          {/*@ts-ignore */}
                          {uniteData?.data?.rows.map((v: any) => {
                              return (
                                  <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                              )
                          })}
                          
                        </Select>
                        <FormHelperText error>{errors["unit"] && ` ${errors["unit"]?.message}`}</FormHelperText>
                      </FormControl> 
                      
                      <FormControl fullWidth>
                      <InputLabel id="collisage">Collisage</InputLabel>
                        <Select
                        labelId='collisage'
                          label="Collisage"
                          required
                          error={!!errors["collisage"]}
                          {...register("collisage")}
                        >
                          {/*@ts-ignore */}
                          {colisageData?.data?.rows.map((v: any) => {
                              return (
                                  <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                              )
                          })}
                          
                        </Select>
                        <FormHelperText error>{errors["collisage"] && ` ${errors["collisage"]?.message}`}</FormHelperText>
                      </FormControl> 

                      <FormControl fullWidth>
                      <InputLabel id="categorie">Catégorie</InputLabel>
                        <Select
                        labelId='colisage'
                          label="Catégorie"
                          required
                          error={!!errors["categoryId"]}
                          {...register("categoryId")}
                        >
                          {/*@ts-ignore */}
                          {categorieData?.data?.rows.map((v: any) => {
                              return (
                                  <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
                              )
                          })}
                          
                        </Select>
                        <FormHelperText error>{errors["categoryId"] && ` ${errors["categoryId"]?.message}`}</FormHelperText>
                      </FormControl> 
                      {columns
                        ?.filter((e) => e.add)
                        .map((column) => (
                          <Box key={column.field} sx={{gridRow:column.field ==="image"? "span 6":"span 1"}}>
                            {
                              column.add && column.field ==="image" && <Box sx={{  height:"100%", width:"100%", border:"1px solid #CCCCCC", borderRadius:"5px", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center",gap:"10px"}}>
                              <FileUploadComponent onUpload={uploadImage} />
                            </Box>
                            }
                          {column.add && (column.type==="string" ) &&  <TextField fullWidth
                            id={column.field}
                            label={column.headerName}
                            type={column.type}
                            required={column.field !== "note"}
                            error={!!errors[column?.field]}
                            helperText={errors[column?.field] && ` ${errors[column?.field]?.message}`}
                          {...register(column?.field)}
                            {...column.TextFieledProps}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">{''}</InputAdornment>
                              ),
                            }}
                          />}
                          {column.add && (column.type==="number" ) && <TextField fullWidth
                            id={column.field}
                            label={column.headerName}
                            type={column.type}
                            required={column.field !== "pricePromotion"}
                            error={!!errors[column?.field]}
                            helperText={errors[column?.field] && ` ${errors[column?.field]?.message}`}
                          {...register(column?.field,{ valueAsNumber: true })}
                            {...column.TextFieledProps}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">{'DZD'}</InputAdornment>
                              ),
                            }}
                          />}
                          
                          
                      {column.add && column.type==="checkbox" && 
                      <Box sx={{display:"flex", gap:"10px",alignItems:"center"}}>
                        <Typography>{column.headerName}</Typography>
                        <Switch
                        checked={checked}
                        onChange={handleChangeCheck}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        />
                      </Box>
                      }
                      </Box>
                        ))}
                
              </Box>
              
                
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
              <Button
                variant="contained"
                type="submit"
              >
                Enregistrer
              </Button>
            </DialogActions>
            </Box>
          </Dialog>
          {/* Update dialog */}
          {edit && <Dialog open={openUpdate || false} onClose={handleCloseUpdate} className="produits-container" >
        <DialogTitle>{info?.DialogUpdate}</DialogTitle>
        <DialogContent>
          <DialogContentText>{info?.DialogUpdateDescription}</DialogContentText>
          <Box sx={{ display:"flex", gap:"10px", marginTop:"20px", width:"100%"}}>
                <Box sx={{ display:"flex", flexDirection:"column", flex:1, gap:"20px"}}>
                    
                    <FormControl fullWidth>
                      <InputLabel id="unite">Unité de mesure</InputLabel>
                        <Select
                        labelId='unite'
                          name="unit"
                          value={item.unit?.toLowerCase()}
                          label="Unité de mesure"
                          onChange={handleChangeUpdate}
                        >
                          {/*@ts-ignore */}
                          {uniteData?.data?.rows.map((v: any) => {
                              return (
                                  <MenuItem key={v.id} value={v.name?.toLowerCase()}>{v.name}</MenuItem>
                              )
                          })}
                          
                        </Select>
                      </FormControl> 
                      <FormControl fullWidth>
                      <InputLabel id="colisage">Colisage</InputLabel>
                        <Select
                        labelId='colisage'
                        value={item.collisage}
                          name="collisage"
                          label="Colisage"
                          onChange={handleChangeUpdate}
                        >
                          {/*@ts-ignore */}
                          {colisageData?.data?.rows.map((v: any) => {
                              return (
                                  <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                              )
                          })}
                          
                        </Select>
                      </FormControl> 
                      <Box sx={{ flex:"auto", position:"relative", width:"100%", border:"1px solid #CCCCCC", borderRadius:"5px", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center",gap:"10px"}}>
                        <FileUploadComponentUpdate onUpload={uploadImageUpdate} image={item.image} />
                      </Box>
                    </Box>
                <Box sx={{ display:"flex", flexDirection:"column", gap:"10px",flex:2}}>
                <FormControl fullWidth>
                      <InputLabel id="categorie">Catégorie</InputLabel>
                        <Select
                        labelId='colisage'
                          name="categoryId"
                          label="Catégorie"
                          value={item.categoryId}
                          onChange={handleChangeUpdate}
                        >
                          {/*@ts-ignore */}
                          {categorieData?.data?.rows.map((v: any) => {
                              return (
                                  <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
                              )
                          })}
                          
                        </Select>
                      </FormControl> 
                {columns
                  ?.filter((e) => e.add)
                  .map((column) => (
                    <>
                    {column.add && (column.type==="string" || column.type==="number" ) && <Box key={column.field}> <TextField fullWidth
                      id={column.field}
                      name={column.field}
                      label={column.headerName}
                      type={column.type}
                      value={item[column.field] || ""}
                      onChange={handleChangeUpdate}
                      {...column.TextFieledProps}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">{column.type==="number"? "DZD":""}</InputAdornment>
                        ),
                      }}
                    />
                    </Box>
                    }
                    
                    
                {column.add && column.type==="checkbox" && 
                <Box key={column.field} sx={{display:"flex", gap:"10px",alignItems:"center"}}>
                  <Typography>{column.headerName}</Typography>
                  <Switch
                    name={column.field}
                    checked={item[column.field]}
                    onChange={handleChangeCheckUpdate}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    />
                </Box>
                }
                </>
                  ))}
                </Box>
                
              </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdate}>Annuler</Button>
          <Button onClick={() => {
            if (editFunction){
              editFunction({...item, image: image})
            }
            handleCloseUpdate()
          }}>Mettre a jour</Button>
        </DialogActions>
      </Dialog>}
        </>
      )}
      {/* The Data Grid Section */}
      <Box sx={{width:"100%",height:500 }}>
      <DataGrid
      checkboxSelection
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={(data) =>
          dispatch({ type: 'FILTER', payload: data.items })
        }
        onSortModelChange={(data) =>
          dispatch({
            type: 'SORT',
            payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
          })
        }
        pagination
        paginationMode="server"
        onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
        onPageSizeChange={(data) =>
          dispatch({ type: 'PAGESIZE', payload: data })
        }
        onSelectionModelChange={(selection) => {
          setSelectionModel(selection);
        }}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rows={state.rows}
        rowCount={state.total}
        page={state.page}
        columns={columns}
        pageSize={state.pageSize}
        autoHeight={state.total < 5 ? true: false}
        rowsPerPageOptions={[5, 10, 20, 50]}
        {...datagridProps}
      />
      </Box>
    </Box>
  )
}
