import React, { useState } from 'react'
import {  Grid, Box, Typography } from '@pankod/refine-mui'
import CachedIcon from '@mui/icons-material/Cached';
import imageInput from "../assets/imageInput.svg"
interface ImageUploadProps {
  onUpload: (file: File) => void
}


const FileUploadComponent: React.FC<ImageUploadProps> = ({ onUpload }) => {
  const [file, setFile] = useState<File | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files
    if (fileList && fileList.length > 0) {
      const selectedFile = fileList[0]
      setFile(selectedFile)
      onUpload(selectedFile)
    }
  }

  return (
    <Grid container direction="column" alignItems="center" sx={{ position:"relative", maxHeight: 300}}>
         <label htmlFor="image-upload">
            <input
            accept="image/*"
            id="image-upload"
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            />
            { !file && <Box>
            <Box sx={{ padding:"10px", borderRadius:"50px", cursor:"pointer", background:"#E4E6EB", width:"fit-content", margin:"0 auto 10px"}}>
                <img src={imageInput} alt="image svg" />
            </Box>
                <Typography>Ajouter une photo</Typography> 
                <Typography sx={{ color:"#757575", fontSize:"0.8rem"}}>1000 px par 1000 px </Typography>
            </Box>}
            {file && <Box sx={{ position:"absolute",top:"20px", right:"20px", background:"#fff", borderRadius:"50%", cursor:"pointer" }}>
                <CachedIcon />
            </Box>}
        </label>
      {file && (
        <Grid item>
            <Box sx={{ maxWidth:"100%"}} >
                <img
                src={URL.createObjectURL(file)}
                alt="Preview"
                style={{ width:"100%"}}
            />
            </Box>
          
        </Grid>
      )}
    </Grid>
  )
}

export default FileUploadComponent
