import * as React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,DialogContentText, DataGrid,TextField,
   InputAdornment, FormControl, Select, MenuItem, InputLabel, frFR } from "@pankod/refine-mui";
import axios, { AxiosResponse } from 'axios'
import { BACKENDINFO, IData } from 'interfaces/datagrid'
import { useCustom } from '@pankod/refine-core';
const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [["createdAt","desc"]],
  rows: [],
  refresh: false,
}

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      }

    default:
      return state
  }
}
interface PostUniqueCheckResponse {
  isAvailable: boolean;
}
export default function DataGridTresorie({
  columns,
  datagridProps,
  info,
  add,
  fetchurl,
  refreshParent,
  addFunction,
  editFunction,
  item,
  setItem,
  setOpenUpdate,
  openUpdate,
  edit

}: Partial<IData>) {
  
  

const { data : clientsData } = useCustom<PostUniqueCheckResponse>({
  url: `/api/auth/clients`,
  method: "get",
  config: {
    query: {
        orders: [["raisonSocial","asc"]],
    },
},
});
const { data : fournisseurData } = useCustom<PostUniqueCheckResponse>({
  url: `/api/fournisseurs`,
  method: "get",
});
const { data : categorieData } = useCustom<PostUniqueCheckResponse>({
  url: `/api/settings`,
  method: "get",
  config: {
      query: {
        type: "catégorie_paiement",
      },
  },
});
const { data : compteData } = useCustom<PostUniqueCheckResponse>({
  url: `/api/comptes`,
  method: "get",
  config: {
      query: {
        type: "compte",
      },
  },
});
const { data : modeData } = useCustom<PostUniqueCheckResponse>({
  url: `/api/settings`,
  method: "get",
  config: {
      query: {
        type: "mode_paiement",
      },
  },
});
const { data : beneficaireData } = useCustom<PostUniqueCheckResponse>({
  url: `/api/settings`,
  method: "get",
  config: {
      query: {
        type: "bénéficiaire",
      },
  },
});



  const [state, dispatch] = React.useReducer(reducer, initState)
  const [open, setOpen] = React.useState(false)
  const [states, setStates] = React.useState<any>()
  const [show, setShow] = React.useState(false)
  const [typeId, setTypeId] = React.useState<any>(1)
  const modePaimentValues = ["Chèque","espèce","banque"]
  const handleClickOpen = () => {
    setShow(true)
  }

  const handleClose = () => {
    setOpen(false)
    setStates({})
  }

  const handleChange = (event: any) => {
    if (event.target.type === "number"){
      setStates({ ...states, [event.target.name]: Number(event.target.value) })
    }
    else {
      setStates({ ...states, [event.target.name]: event.target.value })
    }
    
  }

  const getData = () => {
    
    axios
      .get(
        `${fetchurl}/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`,{withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          dispatch({ type: 'ROWS', payload: res.data })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    getData()
  }, [state.refresh, refreshParent])
 
  const ops= [{id:1, nom:"Dépenses",type:"Dépense"}, 
  {id:2, nom:"Paiement client",type:"Paiement client"},
  {id:3, nom:"paiement fournisseur", type:"Paiement fournisseur"},
  {id:4, nom:"Remboursement client", type:"Remboursement client"},
  {id:5, nom:"remboursement Fournisseur", type:"Remboursement fournisseur"},
  {id:6, nom:"Dépôt", type:"Dépôt"},
  {id:7, nom:"virement de fonds", type:"Virement de fonds"},
]
const boxRef = React.useRef();
React.useEffect(() => {
  window.onclick = (event) => {
    {/* @ts-ignore */}
    if ( event.target &&  event.target.contains(boxRef.current)
      && event.target !== boxRef.current) {     
      setShow(false)
    }
  }
}, []);
const handleCloseUpdate = () => {
  setOpenUpdate(false);
};
const handleChangeUpdate = (event: any) => {
  if (event.target.type === "number"){
    setItem({ ...item, [event.target.name]: Number(event.target.value) });
  }
  else {
    setItem({ ...item, [event.target.name]: event.target.value });
  }
  
};
React.useEffect(() => {
  console.log(item)
  if (Object.keys(item).length > 0){
    setTypeId(ops.find((x : any) => x?.type === item?.type)?.id)
    
  }
}, [item])
  return (
    <Box sx={{ width: '100%' }}>
      {info?.title && <h1>{info?.title}</h1>}
      {info?.description && <p>{info?.description}</p>}
      {add && (
        <>
          <Box sx={{ width:"100%",display: "flex", alignItems:"center",gap:"20px", justifyContent:"flex-end", margin:"15px 0", }}>
            <Box sx={{position:"relative"}}>
            <Button
                variant="contained"
                startIcon={info?.addIcon}
                onClick={handleClickOpen}
                color="secondary"
                sx={{borderRadius:"4px 4px 0 0", width:"100%"}}
            >
                {info?.addText || 'Ajouter'}
            </Button>
            {show && <Box ref={boxRef} sx={{display:"flex", flexDirection:"column",position:"absolute",top:"100%",right:"0%", zIndex:"100", background:"#fff", boxShadow:"-3px 4px 7px rgba(0, 0, 0, 0.25)", borderRadius:"0 0 10px 10px"}}>
              {ops.map((op) => {
                return (<Box key={op.id} sx={{padding:"10px 10px", textTransform:"capitalize", cursor:"pointer","&:hover": {
                  backgroundColor: "#E8FFFB",
                }}} onClick={() => {
                  setTypeId(op.id)
                  setOpen(true)
                  setShow(false)
                }}>
                  {op.nom}
                </Box>)
              })}
            </Box>}
            </Box>
          </Box>
          

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ textTransform:"uppercase"}}>{ops[typeId -1]?.nom}</DialogTitle>

            <DialogContent>
              <DialogContentText>{info?.DialogDescription}</DialogContentText>
              <Box sx={{ display:"flex", flexDirection:"column", gap:"10px", marginTop: "10px", minWidth: 500}}>
              <>
              
                  {
                    typeId === 1 && <FormControl fullWidth>
                    <InputLabel id="categorie">Catégorie</InputLabel>
                      <Select
                      labelId='categorie'
                      name="categorie"
                        label="Catégorie"
                        onChange={handleChange}
                      >
                        {/*@ts-ignore */}
                        {categorieData?.data?.rows.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                            )
                        })}
                        
                      </Select>
                      </FormControl>
                  }
                  {
                    (typeId === 2 || typeId === 4) && <FormControl fullWidth>
                    <InputLabel id="client">Client</InputLabel>
                      <Select
                      labelId='client'
                      name="ClientId"
                        label="Client"
                        onChange={handleChange}
                      >
                        {/*@ts-ignore */}
                        {clientsData?.data?.rows.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.Client.id}>{v.Client.raisonSocial}</MenuItem>
                            )
                        })}
                        
                      </Select>
                      </FormControl>
                  }
                  {
                    (typeId === 3 || typeId === 5) && <FormControl fullWidth>
                    <InputLabel id="fournisseur">Fournisseur</InputLabel>
                      <Select
                      labelId='fournisseur'
                        name="fournisseurId"
                        label="Fournisseur"
                        onChange={handleChange}
                      >
                        {/*@ts-ignore */}
                        {fournisseurData?.data?.rows.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.id}>{v.raisonSocial}</MenuItem>
                            )
                        })}
                        
                      </Select>
                      </FormControl>
                  }
                  <TextField
                    name="titre"
                    label="titre"
                    type="string"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="compte">Compte</InputLabel>
                      <Select
                      labelId='compte'
                        name="compteId"
                        label="Compte"
                        onChange={handleChange}
                      >
                        {/*@ts-ignore */}
                        {compteData?.data?.rows.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
                            )
                        })}
                        
                      </Select>
                      </FormControl>
                    {typeId === 7 && <FormControl fullWidth>
                    <InputLabel id="compteDest">Compte de destination</InputLabel>
                      <Select
                      labelId='compteDest'
                        name="compteDestinationId"
                        label="Compte de destination"
                        onChange={handleChange}
                      >
                        {/*@ts-ignore */}
                        {compteData?.data?.rows.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
                            )
                        })}
                        
                      </Select>
                      </FormControl>}
                      {typeId !== 7 && <FormControl fullWidth>
                      <InputLabel id="modalite_paiement">Modalité de paiement</InputLabel>
                        <Select
                        labelId='modalite_paiement'
                          name="modalitePaiement"
                          label="Modalité de paiement"
                          disabled={typeId === 7}
                          onChange={handleChange}
                        >
                          {modePaimentValues.map((v: any) => {
                              return (
                                  <MenuItem key={v.id} value={v}>{v}</MenuItem>
                              )
                          })}
                          
                        </Select>
                      </FormControl>}
                      {(typeId === 2 || typeId === 4) && <FormControl fullWidth>
                      <InputLabel id="mode_paiement">Mode de paiement</InputLabel>
                        <Select
                        labelId='mode_paiement'
                          name="modePaiement"
                          label="Mode de paiement"
                          onChange={handleChange}
                        >
                          {/*@ts-ignore */}
                          {modeData?.data?.rows.map((v: any) => {
                              return (
                                  <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                              )
                          })}
                          
                        </Select>
                      </FormControl> }
                    {typeId !== 7 && <TextField
                    name="referencePaiement"
                    label="référence de paiement"
                    type="string"
                    disabled={states?.modalitePaiement !== "Chèque"}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                  />}
                  {(typeId === 1 || typeId === 3 || typeId=== 4 || typeId===7) &&<TextField
                    name="montantEmis"
                    label="Montant"
                    type="number"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{'DZD'}</InputAdornment>
                      ),
                    }}
                  />}
                  {(typeId === 2 || typeId === 5 || typeId=== 6) &&<TextField
                    name="montantRecu"
                    label="Montant"
                    type="number"
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">{'DZD'}</InputAdornment>
                      ),
                    }}
                  />}
                  {typeId && typeId > 1 && typeId < 6  && <TextField
                    name="representant"
                    label="Représentant"
                    type="string"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                  />}
                  {typeId === 1 && <FormControl fullWidth>
                    <InputLabel id="beneficaire">Bénéficaire</InputLabel>
                      <Select
                      labelId='beneficaire'
                        name="beneficiaire"
                        label="Bénéficaire"
                        onChange={handleChange}
                      >
                        {/*@ts-ignore */}
                        {beneficaireData?.data?.rows.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                            )
                        })}
                        
                      </Select>
                      </FormControl>}
                    {typeId === 6 && <FormControl fullWidth>
                    <InputLabel id="deposant">Déposant</InputLabel>
                      <Select
                      labelId='deposant'
                        name="deposant"
                        label="Déposant"
                        onChange={handleChange}
                      >
                        {/*@ts-ignore */}
                        {beneficaireData?.data?.rows.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                            )
                        })}
                        
                      </Select>
                      </FormControl>}

              </>
                
                
                </Box>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  if (addFunction) {
                    addFunction({...states,modalitePaiement: typeId === 7 ? "Virement de fonds": states.modalitePaiement,type: ops[typeId-1].type})
                  }
                  handleClose()
                }}
              >
                Enregistrer
              </Button>
            </DialogActions>
          </Dialog>
          
        </>
      )}
      {edit && (
        <Dialog open={openUpdate || false} onClose={handleCloseUpdate}>
        <DialogTitle sx={{ textTransform:"uppercase"}}>{ops[typeId -1]?.nom}</DialogTitle>

        <DialogContent>
          <DialogContentText>{info?.DialogDescription}</DialogContentText>
          <Box sx={{ display:"flex", flexDirection:"column", gap:"10px", marginTop: "10px", minWidth: 500}}>
          <>
          
              {
                typeId === 1 && <FormControl fullWidth>
                <InputLabel id="categorie">Catégorie</InputLabel>
                  <Select
                  labelId='categorie'
                  name="categorie"
                    label="Catégorie"
                    value={item.categorie}
                    onChange={handleChangeUpdate}
                  >
                    {/*@ts-ignore */}
                    {categorieData?.data?.rows.map((v: any) => {
                        return (
                            <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                        )
                    })}
                    
                  </Select>
                  </FormControl>
              }
              {
                (typeId === 2 || typeId === 4) && <FormControl fullWidth>
                <InputLabel id="client">Client</InputLabel>
                  <Select
                  labelId='client'
                  name="ClientId"
                    label="Client"
                    value={item.ClientId}
                    onChange={handleChangeUpdate}
                  >
                    {/*@ts-ignore */}
                    {clientsData?.data?.rows.map((v: any) => {
                        return (
                            <MenuItem key={v.id} value={v.Client.id}>{v.Client.raisonSocial}</MenuItem>
                        )
                    })}
                    
                  </Select>
                  </FormControl>
              }
              {
                (typeId === 3 || typeId === 5) && <FormControl fullWidth>
                <InputLabel id="fournisseur">Fournisseur</InputLabel>
                  <Select
                  labelId='fournisseur'
                    name="fournisseurId"
                    label="Fournisseur"
                    value={item.fournisseurId}
                    onChange={handleChangeUpdate}
                  >
                    {/*@ts-ignore */}
                    {fournisseurData?.data?.rows.map((v: any) => {
                        return (
                            <MenuItem key={v.id} value={v.id}>{v.raisonSocial}</MenuItem>
                        )
                    })}
                    
                  </Select>
                  </FormControl>
              }
              <TextField
                name="titre"
                label="titre"
                type="string"
                value={item.titre}
                onChange={handleChangeUpdate}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{''}</InputAdornment>
                  ),
                }}
              />
              <FormControl fullWidth>
                <InputLabel id="compte">Compte</InputLabel>
                  <Select
                  labelId='compte'
                    name="compteId"
                    label="Compte"
                    value={item.compteId}
                    onChange={handleChangeUpdate}
                  >
                    {/*@ts-ignore */}
                    {compteData?.data?.rows.map((v: any) => {
                        return (
                            <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
                        )
                    })}
                    
                  </Select>
                  </FormControl>
                {typeId === 7 && <FormControl fullWidth>
                <InputLabel id="compteDest">Compte de destination</InputLabel>
                  <Select
                  labelId='compteDest'
                    name="compteDestinationId"
                    label="Compte de destination"
                    value={item.compteDestinationId}
                    onChange={handleChangeUpdate}
                  >
                    {/*@ts-ignore */}
                    {compteData?.data?.rows.map((v: any) => {
                        return (
                            <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
                        )
                    })}
                    
                  </Select>
                  </FormControl>}
                  {typeId !== 7 && <FormControl fullWidth>
                  <InputLabel id="modalite_paiement">Modalité de paiement</InputLabel>
                    <Select
                    labelId='modalite_paiement'
                      name="modalitePaiement"
                      label="Modalité de paiement"
                      value={item.modalitePaiement}
                      disabled={typeId === 7}
                      onChange={handleChangeUpdate}
                    >
                      {modePaimentValues.map((v: any) => {
                          return (
                              <MenuItem key={v} value={v}>{v}</MenuItem>
                          )
                      })}
                      
                    </Select>
                  </FormControl>}
                  {(typeId === 2 || typeId === 4) && <FormControl fullWidth>
                  <InputLabel id="mode_paiement">Mode de paiement</InputLabel>
                    <Select
                    labelId='mode_paiement'
                      name="modePaiement"
                      label="Mode de paiement"
                      value={item.modePaiement}
                      onChange={handleChangeUpdate}
                    >
                      {/*@ts-ignore */}
                      {modeData?.data?.rows.map((v: any) => {
                          return (
                              <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                          )
                      })}
                      
                    </Select>
                  </FormControl> }
                {typeId !== 7 && <TextField
                name="referencePaiement"
                label="référence de paiement"
                type="string"
                disabled={states?.modalitePaiement !== "Chèque"}
                value={item.referencePaiement}
                onChange={handleChangeUpdate}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{''}</InputAdornment>
                  ),
                }}
              />}
              {(typeId === 1 || typeId === 3 || typeId=== 4 || typeId===7) &&<TextField
                name="montantEmis"
                label="Montant"
                type="number"
                value={item.montantEmis}
                onChange={handleChangeUpdate}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">{'DZD'}</InputAdornment>
                  ),
                }}
              />}
              {(typeId === 2 || typeId === 5 || typeId=== 6) &&<TextField
                name="montantRecu"
                label="Montant"
                type="number"
                value={item.montantRecu}
                onChange={handleChangeUpdate}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">{'DZD'}</InputAdornment>
                  ),
                }}
              />}
              {typeId && typeId > 1 && typeId < 6  && <TextField
                name="representant"
                label="Représentant"
                type="string"
                value={item.representant}
                onChange={handleChangeUpdate}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{''}</InputAdornment>
                  ),
                }}
              />}
              {typeId === 1 && <FormControl fullWidth>
                <InputLabel id="beneficaire">Bénéficaire</InputLabel>
                  <Select
                  labelId='beneficaire'
                    name="beneficiaire"
                    label="Bénéficaire"
                    value={item.beneficiaire}
                    onChange={handleChangeUpdate}
                  >
                    {/*@ts-ignore */}
                    {beneficaireData?.data?.rows.map((v: any) => {
                        return (
                            <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                        )
                    })}
                    
                  </Select>
                  </FormControl>}
                {typeId === 6 && <FormControl fullWidth>
                <InputLabel id="deposant">Déposant</InputLabel>
                  <Select
                  labelId='deposant'
                    name="deposant"
                    label="Déposant"
                    value={item.deposant}
                    onChange={handleChangeUpdate}
                  >
                    {/*@ts-ignore */}
                    {beneficaireData?.data?.rows.map((v: any) => {
                        return (
                            <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                        )
                    })}
                    
                  </Select>
                  </FormControl>}

          </>
            
            
            </Box>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseUpdate}>Annuler</Button>
          <Button
            variant="contained"
            type="submit"
            onClick={() => {
              if (editFunction) {
                editFunction({...item,modalitePaiement: typeId === 7 ? "Virement de fonds": item.modalitePaiement})
              }
              handleCloseUpdate()
            }}
          >
            Mettre a jour
          </Button>
        </DialogActions>
      </Dialog>
      )}
      {/* The Data Grid Section */}
      <Box sx={{width:"100%", height:500}}>
      <DataGrid
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={(data) =>
          dispatch({ type: 'FILTER', payload: data.items })
        }
        onSortModelChange={(data) =>
          dispatch({
            type: 'SORT',
            payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
          })
        }
        pagination
        paginationMode="server"
        onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
        onPageSizeChange={(data) =>
          dispatch({ type: 'PAGESIZE', payload: data })
        }
        rows={state.rows}
        rowCount={state.total}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        page={state.page}
        columns={columns}
        autoHeight={state.total < 5 ? true: false}
        pageSize={state.pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        {...datagridProps}
      />
      </Box>
    </Box>
  )
}
