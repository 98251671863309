import { useState } from 'react'
import axios from 'axios'
import DataGridSettings from 'components/Datagrids/DataGridSettings'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, } from '@pankod/refine-mui'
import Swal from "sweetalert2"
import { Columns, Info, PropsInfo,PropsDialog } from 'interfaces/datagrid'
import {  useCtxt } from "contexts/app.context";
const infoUniteDeMesure: Info = {
    title: "Unité de mesure",
    addText: 'Ajouter',
    addIcon: <AddIcon />,
    DialogTitle: 'Nouvelle unité de mesure',
    DialogUpdate: "Mettre a jour la catégorie d'article",
    type: "unité_mesure"
  }
  const infoCategorie: Info = {
    title: "Catégorie d'article",
    addText: 'Ajouter',
    addIcon: <AddIcon />,
    DialogTitle: "Nouvelle catégorie d'article",
    DialogUpdate: "Mettre a jour l'unité de mesure",
    type: "catégorie_article"
  }
  const infoColisage: Info = {
    title: "Colisage",
    addText: 'Ajouter',
    addIcon: <AddIcon />,
    DialogTitle: "Nouveau colisage",
    DialogUpdate: "Mettre a jour un colisage",
    type:"colisage"
  }
  
const Article = () => {
  const { ctxt:{user} } = useCtxt();
  const propsInfo: PropsInfo = {
    add: (user?.role === "admin" || user?.permission.parametres[3]=== "1") ? true : false,
    delete: (user?.role === "admin" || user?.permission.parametres[2]=== "1") ? true : false,
    edit: (user?.role === "admin" || user?.permission.parametres[1]=== "1") ? true : false
  }
    const [refresh, setrefresh] = useState(false)
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [item, setItem] = useState<any>({});
    
    const propsDialog1: PropsDialog = {
      openUpdate: open1,
      setOpenUpdate: setOpen1,
      item: item,
      setItem: setItem,
    }
    const propsDialog2: PropsDialog = {
      openUpdate: open2,
      setOpenUpdate: setOpen2,
      item: item,
      setItem: setItem,
    }
    const propsDialog3: PropsDialog = {
      openUpdate: open3,
      setOpenUpdate: setOpen3,
      item: item,
      setItem: setItem,
    }
    
    const deleteOne = (id : Number, name: string) => {
      Swal.fire({
        titleText: `Supprimer ${name}  ?`,
        text: `Souhaitez-vous vraiment supprimer ${name} ? Cette opération est irréversible.`,
        showCancelButton: true,
        cancelButtonText: `Annuler`,
        confirmButtonText: 'Supprimer',
        reverseButtons: true,
        customClass: {
          title: 'sw-delete-title',
          cancelButton: 'sw-delete-cancel',
          confirmButton: 'sw-delete-confirm',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/settings/${id}`,{withCredentials: true})
            .then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `${name} a bien été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
                setrefresh(!refresh)
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `${name} n'a pas été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${name} n'a pas été supprimé`,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } 
      })
      
    };
    
    const addOne = (values: any, type: string) => {
      axios.post('/api/settings', {...values, type: type}, {withCredentials: true}).then((res) => {
        if (res.status === 201) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `${values.name} a bien été ajouté`,
            showConfirmButton: false,
            timer: 1500,
          });
          setrefresh(!refresh)
        } 
        else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.name} n'a pas été ajouté`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((e) => {
        console.log(e)
        Swal.fire({
          position: "center",
          icon: "error",
          title: `le parametre n'a pas été ajouté`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
  
    }
    const updateOne = (values: any) => {
      axios.put(`/api/settings/${values.id}`, {name:values.name}, {withCredentials: true}).then((res) => {
        if (res.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `${values.name} a bien été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
          setrefresh(!refresh)
        }
         else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `le parametre n'a pas été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
          if (res.status === 400) {
            console.log('bad req')
          }
         } 
      }).catch((e) => {
        console.log(e)
        Swal.fire({
          position: "center",
          icon: "error",
          title: `le parametre n'a pas été mis a jour`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
  
    }
    const columnsUniteDeMesure: Columns[] = [
      { field: 'id', headerName: 'ID', type: 'number', hide: true },
      {
        field: 'name',
        headerName: 'Nom',
        type: 'string',
        editable: false,
        add: true,
        edit: true,
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: 'string',
        sortable: false,
        filterable: false,
        editable: false,
        hide: !propsInfo.edit && !propsInfo.delete,
        renderCell: (params) => {
          return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
            {propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
            setItem(params.row)
            setOpen1(true);
          }}>
            { infoUniteDeMesure.editIcon ? infoUniteDeMesure.editIcon : <EditIcon />  }
          </div>}
          {propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
            deleteOne(params.row.id, params.row.name)
          }}>
            { infoUniteDeMesure.deleteIcon ? infoUniteDeMesure.deleteIcon : <DeleteIcon />  }
          </div>}
          </Box>;
        }
      }
    ]
    
    const columnsCategorie: Columns[] = [
      { field: 'id', headerName: 'ID', type: 'number', hide: true },
      {
        field: 'name',
        headerName: 'Nom',
        type: 'string',
        editable: false,
        add: true,
        edit: true,
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        type: 'string',
        sortable: false,
        filterable: false,
        editable: false,
        hide: !propsInfo.edit && !propsInfo.delete,
        renderCell: (params) => {
          return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
            {propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
            setItem(params.row)
            setOpen2(true);
          }}>
            { infoCategorie.editIcon ? infoCategorie.editIcon : <EditIcon />  }
          </div>}
          {propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
            deleteOne(params.row.id, params.row.name)
          }}>
            { infoCategorie.deleteIcon ? infoCategorie.deleteIcon : <DeleteIcon />  }
          </div>}
          </Box>;
        }
      }
    ]
    const columnsColisage: Columns[] = [
        { field: 'id', headerName: 'ID', type: 'number', hide: true },
        {
          field: 'name',
          headerName: 'Nom',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          flex: 1,
        },
        {
          field: "actions",
          headerName: "Actions",
          type: 'string',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfo.edit && !propsInfo.delete,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
              setItem(params.row)
              setOpen3(true);
            }}>
              { infoColisage.editIcon ? infoColisage.editIcon : <EditIcon />  }
            </div>}
            {propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
              deleteOne(params.row.id, params.row.name)
            }}>
              { infoColisage.deleteIcon ? infoColisage.deleteIcon : <DeleteIcon />  }
            </div>}
            </Box>;
          }
        }
      ]
  return (
    <Box>
        <Box sx={{display:"flex",gap:"20px"}}>
            <Box sx={{ flex:"1"}}>
                <DataGridSettings
                    refreshParent={refresh}
                    {...propsInfo}
                    {...propsDialog1}
                    addFunction={addOne}
                    editFunction={updateOne}
                    fetchurl="/api/settings/?type=catégorie_article&"
                    columns={columnsCategorie}
                    info={infoCategorie}
                />
            </Box>
            <Box sx={{ flex:"1"}}>
            <DataGridSettings
                refreshParent={refresh}
                {...propsInfo}
                {...propsDialog2}
                addFunction={addOne}
                editFunction={updateOne}
                fetchurl="/api/settings/?type=unité_mesure&"
                columns={columnsUniteDeMesure}
                info={infoUniteDeMesure}
            />
            </Box>
        </Box>
        <Box sx={{width:"100%"}}>
        <DataGridSettings
            refreshParent={refresh}
            {...propsInfo}
            {...propsDialog3}
            addFunction={addOne}
            editFunction={updateOne}
            fetchurl="/api/settings/?type=colisage&"
            columns={columnsColisage}
            info={infoColisage}
        />
        </Box>
    </Box>
  )
}

export default Article