import * as React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,DialogContentText, DataGrid,TextField, frFR, FormHelperText,
   InputAdornment,IconButton, Typography, FormControl, Switch, Select, MenuItem, InputLabel, GridToolbarContainer, GridToolbarExport, GridRowId } from "@pankod/refine-mui";
import axios, { AxiosResponse } from 'axios'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { BACKENDINFO, IData } from 'interfaces/datagrid'
import Swal from 'sweetalert2';
import {  useForm, SubmitHandler } from "@pankod/refine-react-hook-form";
import {  number, object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
}

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      }

    default:
      return state
  }
}
const registerSchema = object({
  first_name: string()
    .nonempty('Ce champ est obligatoire')
    .max(32, 'Maximum de characters est 32'),
    last_name: string()
    .nonempty('Ce champ est obligatoire')
    .max(32, 'Maximum de characters est 32'),
    phone_number: string()
    .nonempty('Ce champ est obligatoire').min(10, 'format invalide')
    .max(10, 'format invalide'),
    email: string().nonempty('Email is required').email('Email is invalid'),
    roleId: number().min(0),
    password: string()
      .nonempty('Mot de passe est obligatoire')
      .min(8, 'Mot de passe doit etre plus que 8 characters')
      .max(32, 'Mot de passe doit etre moins que 32 characters'),
});
type RegisterInput = TypeOf<typeof registerSchema>;

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
export default function DataGridUsers({
  columns,
  datagridProps,
  info,
  add,
  edit,
  fetchurl,
  refreshParent,
  addFunction,
  editFunction,
  item,
  setItem,
  openUpdate,
  setOpenUpdate,
  setrefreshParent

}: Partial<IData>) {
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });
  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);
  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {

    if (addFunction && Object.keys(errors).length === 0) {
      addFunction({...values, active: checked })
      handleClose()
    } 
    
  };
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const [state, dispatch] = React.useReducer(reducer, initState)
  const [open, setOpen] = React.useState(false)
  const [selectValues, setSelectValues] = React.useState([])
  const [refresh, setRefresh] = React.useState(false)
  const [checked, setChecked] = React.useState(false);

  const handleChangeCheck = (event : any) => {
    setChecked(event.target.checked)
  };
  const handleChangeCheckUpdate = (event : any) => {
    setItem({ ...item, [event.target.name]: event.target.checked })
  };
 
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    reset();
  }

  //Update dependacies
  
  const handleChangeUpdate = (event: any) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const getData = () => {
    console.log(`${fetchurl}/?filters=${JSON.stringify(
      state.filter
    )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
      state.page
    }`)
    axios
      .get(
        `${fetchurl}/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`, {withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          dispatch({ type: 'ROWS', payload: res.data })
        }
      })
      .catch((e) => {
        console.log(e)
      })
      axios
      .get(
        `/api/roles?filters=[]&sorts=[]`, {withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          let t = res.data.rows.map((v: any) => {
            return {id: v.id,role:v.role}
          })
          setSelectValues(t)
          setRefresh(!refresh)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    getData()
  }, [state.refresh, refreshParent])
 
  const deleteBulk = () => {
    Swal.fire({
      titleText: `Supprimer les utilisateurs sélectionnés  ?`,
      text: `Souhaitez-vous vraiment supprimer ces utilisateurs ? Cette opération est irréversible.`,
      showCancelButton: true,
      cancelButtonText: `Annuler`,
      confirmButtonText: 'Supprimer',
      reverseButtons: true,
      customClass: {
        title: 'sw-delete-title',
        cancelButton: 'sw-delete-cancel',
        confirmButton: 'sw-delete-confirm',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`/api/auth/bulk/employees/?usersId=${JSON.stringify(selectionModel)}`,{withCredentials: true})
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: `Les utilisateurs ont bien été supprimé`,
                showConfirmButton: false,
                timer: 1500,
              });
              setrefreshParent(!refreshParent)
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: `Les utilisateurs n'ont pas été supprimé`,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Les utilisateurs n'ont pas été supprimé`,
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } 
    })
      
    };
    const [updatePass, setUpdatePass] = React.useState(false)
    const [passValue, setPassValue] = React.useState("")
    const [response, setResponse] = React.useState("")
    const [bienUpdate, setBienUpdate] = React.useState(false)
    const changerPass = () => {
      axios.put(`/api/auth/employees/password/${item.id}`, {password: passValue}, {withCredentials: true}).then((res) => {
        if (res.status === 200) {
          setResponse("Le mot de passe a bien été mis a jour")
          setBienUpdate(true)
          setUpdatePass(false)
          setPassValue("")
        }
        else {
          setResponse("Le mot de passe n' pas été mis a jour")
          setBienUpdate(false)
        }
      }).catch((e) => {
        console.log(e)
        setResponse("Le mot de passe n' pas été mis a jour")
        setBienUpdate(false)
      })
      setTimeout(() => {
        setResponse("")
      },3000)
    }
  return (
    <Box sx={{ width: '100%' }}>
      {/* The Dialog Section */}
      {/* Dialog Button */}
      {info?.title && <h1>{info?.title}</h1>}
      {info?.description && <p>{info?.description}</p>}
      {add && (
        <>
        <Box sx={{width: "100%", display:"flex", justifyContent:"flex-end"}}>
            <Box sx={{ display: "flex", alignItems:"center",gap:"20px", justifyContent:"flex-end", padding:"15px 0"}}>
            { selectionModel.length > 0 && <Button
                variant="contained"
                sx={{ background:"#F7EEEE", border:"2px solid #F4433680",borderRadius:"4px", color:"#F44336", fontWeight:"bold"}}
                onClick={deleteBulk}
            >
                Supprimer ({selectionModel.length})
            </Button>}
            <Button
                variant="contained"
                startIcon={info?.addIcon}
                color="secondary"
                onClick={handleClickOpen}
            >
                {info?.addText || 'Ajouter'}
            </Button>
          </Box>
        </Box>
          

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{info?.DialogTitle}</DialogTitle>
               {/*@ts-ignore */}
            <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}
              >
            <DialogContent>
              <DialogContentText>{info?.DialogDescription}</DialogContentText>
              <Box sx={{ display:"flex", flexDirection:"column", gap:"10px", marginTop: "10px"}}>
              {columns
                ?.filter((e) => e.add)
                .map((column) => (
                  <Box key={column.field}>
                  {column.add && (column.type==="string" || column.type==="number" ) && <TextField
                  fullWidth
                    id={column.field}
                    label={column.headerName}
                    {...column.TextFieledProps}
                    required
                    error={!!errors[column?.field]}
                    helperText={errors[column?.field] && ` ${errors[column?.field]?.message}`}
                    {...register(column?.field)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                  />}
                  {column.add && column.type==="select" && 
                  <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                <Select
                  id={column.field}
                  label="Role"
                  required
                    error={!!errors[column?.field]}
                    {...register(column?.field)}
                >
                  {selectValues.map((v: any) => {
                      return (
                          <MenuItem key={v.id} value={v.id}>{v.role}</MenuItem>
                      )
                  })}
                  
                </Select>
                <FormHelperText error>{errors[column?.field] && ` ${errors[column?.field]?.message}`}</FormHelperText>
                </FormControl>
               }
                  {column.add && column.type==="password" && <TextField fullWidth
                id={column.field}
                type={showPassword ? 'text':'password'}
                label={column.headerName}
                required
                error={!!errors[column?.field]}
                helperText={errors[column?.field] && ` ${errors[column?.field]?.message}`}
                {...register(column?.field)}
                {...column.TextFieledProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> }
              {column.add && column.type==="checkbox" && 
              <Box sx={{display:"flex", gap:"10px",alignItems:"center"}}>
                <Box sx={{display:"flex", gap:"10px",alignItems:"center"}}>
                <Typography>{column.headerName}</Typography>
                <Switch
                  checked={checked}
                  onChange={handleChangeCheck}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  />
              
              </Box>
              </Box>
               }
              </Box>
                ))}
              </Box>
              
                
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
              <Button
                variant="contained"
                type="submit"
              >
                Enregistrer
              </Button>
            </DialogActions>
            </Box>
          </Dialog>
          {/* Update dialog */}
          {edit && <Dialog open={openUpdate || false} onClose={handleCloseUpdate} >
        <DialogTitle>{info?.DialogUpdate}</DialogTitle>
        <DialogContent>
          <DialogContentText>{info?.DialogUpdateDescription}</DialogContentText>
          {columns?.map((column) => (
            <Box sx={{display:"flex", marginTop: "10px",flexDirection:"column", gap:"10px"}} key={column.field}>
            {column.edit && (column.type==="string" || column.type==="number" ) && <TextField
                
                type={column.type || "text"}
                name={column.field || ""}
                value={item[column.field] || ""}
                label={column.headerName}
                onChange={handleChangeUpdate}
                {...column.TextFieledProps}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{''}</InputAdornment>
                  ),
                }}
              /> }
               {column.edit && column.type==="select" && 
               <FormControl fullWidth>
               <InputLabel>Role</InputLabel>
                <Select
                key={column.field}
                  id={column.field}
                  value={item[column?.field]}
                  name={column?.field}
                  label="Role"
                  onChange={handleChangeUpdate}
                >
                  {selectValues.map((v: any) => {
                      return (
                          <MenuItem key={v.id} value={v.id}>{v.role}</MenuItem>
                      )
                  })}
                  
                </Select>
                </FormControl>
               }
               {column.edit && column.type==="password"  && <Box key={column.field}>
               <Box  sx={{ display:"flex", gap:"5px", alignItems:"center"}}>
               <TextField
                type={showPassword ? 'text':'password'}
                value={passValue}
                label={column.headerName}
                onChange={(e) => {
                  setPassValue(e.target.value)
                }}
                disabled={!updatePass}
                {...column.TextFieledProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
               <Button variant="contained" size="small" sx={{
                height: 40,
                borderRadius: "3px",
                fontSize: "15px",
                fontWeight: "500",
                }} onClick={() => {
                  setUpdatePass(!updatePass)
                }}>{updatePass ? "Annuler":"Changer"}</Button>
                {updatePass && <Button variant="contained" size="small" sx={{
                height: 40,
                borderRadius: "3px",
                fontSize: "15px",
                fontWeight: "500",
                }} onClick={changerPass}>Confirmer</Button>}
               </Box> 
               {
                response !== "" && <Typography color={bienUpdate ? "primary": "#F90000"} >{response}</Typography>
               }
               </Box>
               }
               
              {column.edit && column.type==="checkbox" && <Box sx={{display:"flex", gap:"10px",alignItems:"center"}}>
                <Typography>{column.headerName}</Typography>
                <Switch
                  name={column.field}
                  checked={item[column.field]}
                  onChange={handleChangeCheckUpdate}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  />
              </Box>}
            </Box>
            
            
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdate}>Annuler</Button>
          <Button onClick={() => {
            if (editFunction){
              editFunction(item)
            }
            handleCloseUpdate()
          }}>Mettre a jour</Button>
        </DialogActions>
      </Dialog>}
        </>
      )}
      {/* The Data Grid Section */}
      <Box sx={{width:"100%",height:500}}>
      <DataGrid
        checkboxSelection 
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={(data) =>
          dispatch({ type: 'FILTER', payload: data.items })
        }
        onSortModelChange={(data) =>
          dispatch({
            type: 'SORT',
            payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
          })
        }
        pagination
        paginationMode="server"
        onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
        onPageSizeChange={(data) =>
          dispatch({ type: 'PAGESIZE', payload: data })
        }
        rows={state.rows}
        rowCount={state.total}
        page={state.page}
        columns={columns}
        pageSize={state.pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        {...datagridProps}
        autoHeight={state.total < 5 ? true: false}
        onSelectionModelChange={(selection) => {
          setSelectionModel(selection);
        }}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      </Box>
    </Box>
  )
}
