import { useState } from 'react'
import axios from 'axios'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, } from '@pankod/refine-mui'
import Swal from "sweetalert2"
import { Columns, Info, PropsInfo,PropsDialog } from 'interfaces/datagrid'
import DataGridProduits from 'components/Datagrids/DataGridProduits'
import { useCtxt } from 'contexts/app.context'
const infoRole: Info = {
    addText: 'Nouvel article',
    addIcon: <AddIcon />,
    DialogTitle: 'Ajouter un produit',
    DialogUpdate: "Modifier un article",
    DialogDescription: "La création d'un produit et sa configuration",
    DialogUpdateDescription: "La modification d'un produit et sa configuration"
  }
const Produits = () => {
  const { ctxt:{user} } = useCtxt();
  const propsInfo: PropsInfo = {
    add: (user?.role === "admin" || user?.permission.produits[3]=== "1") ? true : false,
    delete: (user?.role === "admin" || user?.permission.produits[2]=== "1") ? true : false,
    edit: (user?.role === "admin" || user?.permission.produits[1]=== "1") ? true : false
  }
    const [refresh, setrefresh] = useState(false)
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<any>({});
    
    const propsDialog: PropsDialog = {
      openUpdate: open,
      setOpenUpdate: setOpen,
      item: item,
      setItem: setItem,
    }
    const columns: Columns[] = [
        { field: 'id', headerName: 'ID', type: 'autre', hide: true },
        {
          field: 'name',
          headerName: 'Nom',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          width: 200
        },
        {
          field: 'image',
          headerName: 'Url',
          type: 'autre',
          editable: false,
          hide: true,
          add: true,
          edit: true,
        },
        {
            field: 'categoryId',
            headerName: 'Catégorie',
            type: 'select',
            editable: false,
            add: true,
            edit: true,
            width: 200,
            valueGetter: (params) => {
              return params.row.category.name ?? ""
            } 
          },
          {
            field: 'unit',
            headerName: 'Unité de mesure',
            type: 'select',
            editable: false,
            add: true,
            edit: true,
            width: 200
          },
        {
          field: 'collisage',
          headerName: 'Colisage',
          type: 'select',
          editable: false,
          add: true,
          edit: true,
          width: 150
        },
        {
            field: 'price',
            headerName: 'prix de vente',
            type: 'number',
            hide: true,
            editable: false,
            add: true,
            edit: true,
        },
        {
            field: 'pricePromotion',
            headerName: 'prix de promo',
            type: 'number',
            hide: true,
            editable: false,
            add: true,
            edit: true,
        },
        {
            field: 'note',
            headerName: 'Note',
            type: 'string',
            editable: false,
            hide: true,
            add: true,
            edit: true,
          },
          
        {
          field: 'active',
          headerName: "Etat de l'article",
          type: 'checkbox',
          editable: false,
          add: true,
          edit: true,
          flex: 1,
          renderCell: (params) => {
            return <>
            {params.row.active && <Box sx={{background:"#92CE08", padding:"5px 10px", borderRadius:"16px", color:"#fff"}}>Actif</Box>}
            {!params.row.active && <Box sx={{background:"#EB3E3E", padding:"5px 10px", borderRadius:"16px", color:"#fff"}}>Inactif</Box>}
            </>
          }
        },
        {
          field: "actions",
          headerName: "Actions",
          type: 'autre',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfo.edit && !propsInfo.delete,
          width: 100,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {
                propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
                  setItem(params.row)
                  setOpen(true);
                }}>
                  { infoRole.editIcon ? infoRole.editIcon : <EditIcon />  }
                </div>
              }
            {
              propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
                deleteOne(params.row.id,params.row.name)
              }}>
                { infoRole.deleteIcon ? infoRole.deleteIcon : <DeleteIcon />  }
              </div>
            }
            </Box>;
          }
        }
      ]
    const deleteOne= (id : Number, name: string) => {
      Swal.fire({
        titleText: `Supprimer ${name}  ?`,
        text: `Souhaitez-vous vraiment supprimer ${name} ? Cette opération est irréversible.`,
        showCancelButton: true,
        cancelButtonText: `Annuler`,
        confirmButtonText: "Supprimer l'article",
        reverseButtons: true,
        customClass: {
          title: 'sw-delete-title',
          cancelButton: 'sw-delete-cancel',
          confirmButton: 'sw-delete-confirm',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/products/${id}`,{withCredentials: true})
            .then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `${name} a bien été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
                setrefresh(!refresh)
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `${name} n'a pas été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${name} n'a pas été supprimé`,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } 
      })
        
      };
      const addOne = (values: any) => {
        console.log(values)
        let formData = new FormData()
        formData.append(
          "image", values.image
        );
        columns.filter((col) => (col.type !== "autre" && values[col.field])).forEach((col) => {
          formData.append(col.field, values[col.field])
        })
        axios.post('/api/products', formData, {withCredentials: true}).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.name} a bien été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          } 
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.name} n'a pas été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }).catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.name} n'a pas été ajouté`,
            showConfirmButton: false,
            timer: 1500,
          });
          console.log(e)
        })
    
      }
      const updateOne = (values: any) => {
        let formData = new FormData()
        formData.append(
          "image", values.image
        );
        console.log(values)
        columns.filter((col) => col.type !== "autre" && values[col.field]).forEach((col) => {
          
          formData.append(col.field, values[col.field])
        })
        axios.put(`/api/products/${values.id}`, formData, {withCredentials: true}).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.name} a bien été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          }
           else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.name} n'a pas été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            if (res.status === 400) {
              console.log('bad req')
            }
           } 
        }).catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.name} n'a pas été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
  return (
    <Box>
        <DataGridProduits
            refreshParent={refresh}
            {...propsInfo}
            {...propsDialog}
            addFunction={addOne}
            editFunction={updateOne}
            fetchurl="/api/products/dashboard"
            columns={columns}
            info={infoRole}
            setrefreshParent={setrefresh}
        />
    </Box>
  )
}

export default Produits