import React from "react";
import { LayoutProps } from "@pankod/refine-core";
import { Box } from "@pankod/refine-mui";

import { Sider as DefaultSider } from "../sider";
import Navbar from "components/common/Navbar";
import { Header as DefaultHeader } from "../header";
export const Layout: React.FC<LayoutProps> = ({
  Sider,
  Header,
  OffLayoutArea,
  children,
}) => {
  const HeaderToRender = Header ?? DefaultHeader;

  return (
    <Box display="flex" flexDirection="column" className="container">
      <HeaderToRender />
      <Box
        sx={{
          display: "flex",
          flexDirection: "raw",
          flex: "auto",
          overflowY: "auto",
          height: "100%",
        }}
      >
        <Navbar />
        <Box
          component="main"
          sx={{
            p: { xs: 1, md: 2, lg: 3 },
            flexGrow: 1,
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
      {OffLayoutArea && <OffLayoutArea />}
    </Box>
  );
};
