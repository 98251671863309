import * as React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,DialogContentText, DataGrid, FormHelperText, GridToolbarContainer, GridToolbarExport  ,TextField,
   InputAdornment,IconButton, Typography, FormControl, Select, Switch, MenuItem, InputLabel, frFR } from "@pankod/refine-mui";
import axios, { AxiosResponse } from 'axios'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { BACKENDINFO, IData } from 'interfaces/datagrid'
import { useCustom } from '@pankod/refine-core';
import {  useForm, SubmitHandler } from "@pankod/refine-react-hook-form";
import {  number, object, string, TypeOf, any } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';



const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
}

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      }

    default:
      return state
  }
}
const registerSchema = object({
  raisonSocial: string()
    .nonempty('La raison sociale est obligatoire')
    .max(32, 'Maximum de characters est 32'),
    gerant: string()
    .nonempty('Le gerant est obligatoire')
    .max(32, 'Maximum de characters est 32'),
    conditionPaiement: string()
    .nonempty('Condition de paiement est obligatoire'),
    phone_number: string()
    .nonempty('Ce champ est obligatoire').min(10, 'format invalide')
    .max(10, 'format invalide'),
    soldeInitial: number({
      required_error: "solde initiale est obligatoire",
      invalid_type_error: "solde actuel est obligatoire",
    }).min(0,'Solde Initial doit etre >= 0'),
    soldeMax: any(),
    email: string().nonempty('Email is required').email('Email is invalid'),
    password: string()
      .nonempty('Mot de passe est obligatoire')
      .min(8, 'Mot de passe doit etre plus que 8 characters')
      .max(32, 'Mot de passe doit etre moins que 32 characters'),
})
type RegisterInput = TypeOf<typeof registerSchema>;

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
interface PostUniqueCheckResponse {
  isAvailable: boolean;
}
export default function DataGridClients({
  columns,
  datagridProps,
  info,
  add,
  edit,
  fetchurl,
  refreshParent,
  addFunction,
  editFunction,
  item,
  setItem,
  openUpdate,
  setOpenUpdate

}: Partial<IData>) {
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });
  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);
  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    if (addFunction && Object.keys(errors).length === 0) {
      if (Number.isNaN(values.soldeMax)){
        values.soldeMax = 999999999
      }
      addFunction({...values,active: checked,latitude: latitude.toString(),adresse:adresse,
      longitude: longitude.toString(), soldeInitial: Number(values.soldeInitial),soldeMax: Number(values.soldeMax)})
      handleClose()
    } 
    
  };
  const [state, dispatch] = React.useReducer(reducer, initState)
  const [open, setOpen] = React.useState(false)
  const [checked, setChecked] = React.useState(true);
  const [latitude, setLatitude] = React.useState(35.6986139);
  const [longitude, setLongitude] = React.useState(-0.6184084)
  const [adresse, setAdresse] = React.useState("")
  const [refresh, setRefresh] = React.useState(false)
  const handleChangeCheck = (event : any) => {
    setChecked(event.target.checked)
  };
  const handleChangeCheckUpdate = (event : any) => {
    setItem({ ...item, [event.target.name]: event.target.checked })
  };
  const { data : modeData } = useCustom<PostUniqueCheckResponse>({
    url: `/api/settings/`,
    method: "get",
    config: {
        query: {
          type: "mode_paiement",
        },
    },
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
 
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setChecked(false)
    reset();
  }

  //Update dependacies
  
  const handleChangeUpdate = (event: any) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const getData = () => {
    axios
      .get(
        `${fetchurl}/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`,{withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          console.log(res.data)
          dispatch({ type: 'ROWS', payload: res.data })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    getData()
  }, [state.refresh, refreshParent])

  const [updatePass, setUpdatePass] = React.useState(false)
    const [passValue, setPassValue] = React.useState("")
    const [response, setResponse] = React.useState("")
    const [bienUpdate, setBienUpdate] = React.useState(false)
    const changerPass = () => {
      axios.put(`/api/auth/clients/password/${item.id}`, {password: passValue}, {withCredentials: true}).then((res) => {
        if (res.status === 200) {
          setResponse("Le mot de passe a bien été mis a jour")
          setBienUpdate(true)
          setUpdatePass(false)
          setPassValue("")
        }
        else {
          setResponse("Le mot de passe n' pas été mis a jour")
          setBienUpdate(false)
        }
      }).catch((e) => {
        console.log(e)
        setResponse("Le mot de passe n' pas été mis a jour")
        setBienUpdate(false)
      })
      setTimeout(() => {
        setResponse("")
      },3000)
    }
    const handleChangeGoogleMaps = (ev : any) => {
      setLatitude(ev?.latLng?.lat())
      setLongitude(ev?.latLng?.lng());

    }
    React.useEffect(() => {
      axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`).then((res) => {
        if (res.status === 200) {
          setAdresse(res.data.results[0].formatted_address)
          setRefresh(!refresh)
        }
      }).catch((e) => {
        console.log("erreur get adress ",e)
      })
    },[latitude,longitude])
    React.useEffect(() => {
      
    }, [refresh])
    React.useEffect(() => {
      if (Object.keys(item).length > 0) {
        setLongitude(parseFloat(item?.Client?.longitude))
        setLatitude(parseFloat(item.Client?.latitude))
      } 
    }, [item])
  return (
    <Box sx={{ width: '100%' }} className="produits-container" >
      {/* The Dialog Section */}
      {/* Dialog Button */}
      {info?.title && <h1>{info?.title}</h1>}
      {info?.description && <p>{info?.description}</p>}
      {add && (
        <>
        <Box sx={{width: "100%", display:"flex", justifyContent:"space-between"}}>
            <Box>
            </Box>
            <Box sx={{ display: "flex", alignItems:"center",gap:"20px", justifyContent:"flex-end", padding:"15px 0"}}>
            <Button
                variant="contained"
                startIcon={info?.addIcon}
                onClick={handleClickOpen}
                color="secondary"
            >
                {info?.addText || 'Ajouter'}
            </Button>
          </Box>
        </Box>
          

          <Dialog open={open} onClose={handleClose} maxWidth={false}>
            <DialogTitle>{info?.DialogTitle}</DialogTitle>
            {/*@ts-ignore */}
            <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}
              >
            <DialogContent>
              <DialogContentText>{info?.DialogDescription}</DialogContentText>
              <Box sx={{width:"100%", display:"grid", gridTemplateColumns:"1fr 2fr", gap:"10px 20px",marginTop:"10px"}}>
              {columns
                ?.filter((e) => e.add)
                .map((column) => (
                  <Box key={column.field} sx={{gridRow:column.field ==="googleMaps"? "span 4":"span 1"}}>
                  {column.add && column.type==="string" &&  <TextField fullWidth
                    label={column.headerName}
                    type={column.type}
                    required
                    error={!!errors[column?.field]}
                    helperText={errors[column?.field] && ` ${errors[column?.field]?.message}`}
                    {...register(column?.field)}
                    {...column.TextFieledProps}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                  />}
                  {column.add && column.type==="autre" && column.field==="adresse" &&  <TextField fullWidth
                    label={column.headerName}
                    type="string"
                    required
                    value={adresse}
                    onChange={(e) => {
                      setAdresse(e.target.value)
                    }}
                    {...column.TextFieledProps}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                  />}
                  {column.add && column.type==="autre" && column.field==="googleMaps" &&
                    <LoadScript
                      googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_KEY}`}
                    >
                      <GoogleMap
                        mapContainerStyle={{width:"100%",height:"100%"}}
                        center={{lat: latitude, lng: longitude}}
                        zoom={10}
                        options={{
                          disableDefaultUI:true,
                          fullscreenControl: true,
                        }}
                        onClick={ev => {
                          handleChangeGoogleMaps(ev)
                        }}
                      >
                         <Marker position={{ lat: latitude, lng: longitude}} />
                      </GoogleMap>
                    </LoadScript>
                    }
                  {column.add &&  column.type==="number"  && <TextField fullWidth
                    label={column.headerName}
                    type={column.type}
                    required={column.field !=="soldeMax"}
                    error={!!errors[column?.field]}
                    helperText={errors[column?.field] && ` ${errors[column?.field]?.message}`}
                    {...register(column?.field, { valueAsNumber: true })}
                    {...column.TextFieledProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">{'DZD'}</InputAdornment>
                      ),
                    }}
                  />}
                  {column.add && column.type==="password" && <TextField fullWidth
                id="outlined-start-adornment"
                type={showPassword ? 'text' : 'password'}
                label={column.headerName}
                required
                error={!!errors[column?.field]}
                helperText={errors[column?.field] && ` ${errors[column?.field]?.message}`}
                {...register(column?.field)}
                {...column.TextFieledProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> }
              {column.add && column.type==="select" && 
              <FormControl fullWidth>
              <InputLabel id={column.field}>{column.headerName}</InputLabel>
                <Select
                fullWidth
                  labelId={column.field}
                  label="Condition de paiement"
                  required
                    error={!!errors[column?.field]}
                    {...register(column?.field)}
                  
                >
                  {/*@ts-ignore */}
                  {modeData?.data?.rows.map((v: any) => {
                      return (
                          <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                      )
                  })}
                  
                </Select>
                <FormHelperText error>{errors[column?.field] && ` ${errors[column?.field]?.message}`}</FormHelperText>
                </FormControl>
               }
              {column.add && column.type==="checkbox" && 
              <Box sx={{display:"flex", gap:"10px",alignItems:"center"}}>
                <Typography>{column.field}</Typography>
                <Switch
                  checked={checked}
                  onChange={handleChangeCheck}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  />
              
              </Box>
               }
               {column.field ==="soldeMax" && <FormHelperText error>{errors["soldeCheck"] && ` ${errors["soldeCheck"]?.message}`}</FormHelperText>}
              </Box>
                  
                ))}
                
                
                </Box>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
              <Button
                variant="contained"
                type="submit"
              >
                Enregistrer
              </Button>
            </DialogActions>
            </Box>
          </Dialog>
          {/* Update dialog */}
          {edit && <Dialog open={openUpdate || false} onClose={handleCloseUpdate} maxWidth={false} >
        <DialogTitle>{info?.DialogUpdate}</DialogTitle>
        <DialogContent>
          <DialogContentText>{info?.DialogUpdateDescription}</DialogContentText>
          <Box sx={{width:"100%", display:"grid", gridTemplateColumns:"1fr 2fr", gap:"10px 20px",marginTop:"10px"}}>
              {columns
                ?.filter((e) => e.edit)
                .map((column) => (
                  <Box key={column.field} sx={{gridRow:column.field ==="googleMaps"? "span 4":"span 1"}}>
                  {column.edit && (column.type==="string" || column.type==="number" ) && <TextField
                    fullWidth
                    id="outlined-start-adornment"
                    name={column.field}
                    label={column.headerName}
                    type={column.type}
                    value={item[column.field]}
                    onChange={handleChangeUpdate}
                    {...column.TextFieledProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">{column.type ==="number" ? "DZD" : ""}</InputAdornment>
                      ),
                    }}
                  />}
                 
              {column.edit && column.type==="select" && 
              <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                <Select
                key={column.field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="conditionPaiement"
                  label="Condition"
                  value={item.conditionPaiement}
                  onChange={handleChangeUpdate}
                >
                  {/*@ts-ignore */}
                  {modeData?.data?.rows.map((v: any) => {
                      return (
                          <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                      )
                  })}
                  
                </Select>
                </FormControl>
               }
               {column.edit && column.type==="autre" && column.field==="googleMaps" &&
                    <LoadScript
                      googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_KEY}`}
                    >
                      <GoogleMap
                        mapContainerStyle={{width:"100%",height:"100%"}}
                        center={{lat: latitude, lng: longitude}}
                        zoom={10}
                        options={{
                          disableDefaultUI:true,
                          fullscreenControl: true,
                        }}
                        onClick={ev => {
                          handleChangeGoogleMaps(ev)
                        }}
                      >
                         <Marker position={{ lat: latitude, lng: longitude}} />
                      </GoogleMap>
                    </LoadScript>
                    }
                    {column.edit && column.type==="autre" && column.field==="adresse" &&  <TextField fullWidth
                    label={column.headerName}
                    type="string"
                    required
                    value={adresse}
                    onChange={(e) => {
                      setAdresse(e.target.value)
                    }}
                    {...column.TextFieledProps}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                    />
                  }
                    
              {column.edit && column.type==="checkbox" && 
              <Box sx={{display:"flex", gap:"10px",alignItems:"center", paddingTop:"30px"}}>
                <Typography>{column.headerName}</Typography>
                <Switch
                  name={column.field}
                  checked={item[column.field]}
                  onChange={handleChangeCheckUpdate}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  />
              
              </Box>
               }
              </Box>
                  
                ))}
                
                
                </Box>
                <Box>
               <Box  sx={{ display:"flex", gap:"5px", alignItems:"center", marginTop: "20px"}}>
               <TextField
                type={showPassword ? 'text':'password'}
                value={passValue}
                label="Mot de passe"
                onChange={(e) => {
                  setPassValue(e.target.value)
                }}
                disabled={!updatePass}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
               <Button variant="contained" size="small" sx={{
                height: 40,
                borderRadius: "3px",
                fontSize: "15px",
                fontWeight: "500",
                }} onClick={() => {
                  setUpdatePass(!updatePass)
                }}>{updatePass ? "Annuler":"Changer"}</Button>
                {updatePass && <Button variant="contained" size="small" sx={{
                height: 40,
                borderRadius: "3px",
                fontSize: "15px",
                fontWeight: "500",
                }} onClick={changerPass}>Confirmer</Button>}
               </Box> 
               {
                response !== "" && <Typography color={bienUpdate ? "primary": "#F90000"} >{response}</Typography>
               }
               </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdate}>Annuler</Button>
          <Button onClick={() => {
            if (editFunction){
              delete item.Client
              editFunction({...item,latitude: latitude.toString(),
              longitude: longitude.toString(), adresse: adresse, soldeInitial: Number(item.soldeInitial),soldeMax: Number(item.soldeMax)})
            }
            handleCloseUpdate()
          }}>Mettre a jour</Button>
        </DialogActions>
      </Dialog>}
        </>
      )}
      {/* The Data Grid Section */}
      <Box sx={{width:"100%",height:500}}>
      <DataGrid
        checkboxSelection 
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={(data) =>
          dispatch({ type: 'FILTER', payload: data.items })
        }
        onSortModelChange={(data) =>
          dispatch({
            type: 'SORT',
            payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
          })
        }
        pagination
        paginationMode="server"
        onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
        onPageSizeChange={(data) =>
          dispatch({ type: 'PAGESIZE', payload: data })
        }
        rows={state.rows}
        rowCount={state.total}
        page={state.page}
        columns={columns}
        pageSize={state.pageSize}
        autoHeight={state.total < 5 ? true: false}
        rowsPerPageOptions={[5, 10, 20, 50]}
        {...datagridProps}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      </Box>
    </Box>
  )
}
