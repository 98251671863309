import { useState } from 'react'
import axios from 'axios'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, } from '@pankod/refine-mui'
import Swal from "sweetalert2"
import { Columns, Info, PropsInfo,PropsDialog } from 'interfaces/datagrid'
import DataGridClients from 'components/Datagrids/DataGridClients'
import { useCtxt } from 'contexts/app.context'
const infoRole: Info = {
    addText: 'Nouveau client',
    addIcon: <AddIcon />,
    DialogTitle: 'Ajouter un client',
    DialogUpdate: "Modifier un client",
    DialogDescription: "La création d'un profile client et sa configuration",
    DialogUpdateDescription: "La modification d'un profile client et sa configuration"
  }
  
const Clients = () => {
  const { ctxt:{user} } = useCtxt();
  const propsInfo: PropsInfo = {
    add: (user?.role === "admin" || user?.permission.clients[3]=== "1") ? true : false,
    delete: (user?.role === "admin" || user?.permission.clients[2]=== "1") ? true : false,
    edit: (user?.role === "admin" || user?.permission.clients[1]=== "1") ? true : false
  }
    const [refresh, setrefresh] = useState(false)
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<any>({});
    
    const propsDialog: PropsDialog = {
      openUpdate: open,
      setOpenUpdate: setOpen,
      item: item,
      setItem: setItem,
    }
    const columns: Columns[] = [
        { field: 'id', headerName: 'ID', type: 'number', hide: true },
        {
          field: 'soldeInitial',
          headerName: 'Solde actuel',
          type: 'number',
          editable: false,
          add: true,
          edit: true,
          width: 150,
          valueGetter: (params) => {
            return params.row.Client?.soldeInitial ? `${params.row.Client?.soldeInitial} DZD` :""
          }
        },
        {
          field: 'raisonSocial',
          headerName: 'Raison social',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          width: 200,
          valueGetter: (params) => {
            return params.row.Client?.raisonSocial ?? ""
          }
        },
        {
          field: 'soldeMax',
          headerName: 'Solde Max',
          type: 'number',
          editable: false,
          add: true,
          edit: true,
          width: 150,
          valueGetter: (params) => {
            return params.row.Client?.soldeMax ?`${params.row.Client?.soldeMax} DZD`: "" 
          }
      },
        {
            field: 'gerant',
            headerName: 'Gérant',
            type: 'string',
            editable: false,
            add: true,
            edit: true,
            width: 200,
            valueGetter: (params) => {
              return params.row.Client?.gerant ?? ""
            }
          },
          
          {
            field: 'conditionPaiement',
            headerName: 'Mode de paiement',
            type: 'select',
            editable: false,
            add: true,
            edit: true,
            width: 200,
            hide: true,
          },
          {
            field: 'email',
            headerName: 'Email',
            type: 'string',
            editable: false,
            hide:true,
            add: true,
            edit: true,
            width: 200
          },
          {
            field: 'googleMaps',
            headerName: 'maps',
            type: 'autre',
            editable: false,
            hide: true,
            add: true,
            edit: true,
            width: 200,
            valueGetter: (params) => {
              return params.row.Client?.adresse ?? ""
            }
          },
        {
          field: 'adresse',
          headerName: 'Lieu',
          type: 'autre',
          editable: false,
          add: true,
          edit: true,
          width: 200,
          valueGetter: (params) => {
            return params.row.Client?.adresse ?? ""
          }
        },
        
          {
            field: 'phone_number',
            headerName: 'Numéro de téléphone',
            type: 'string',
            hide: true,
            editable: false,
            add: true,
            edit: true
          },
        {
            field: 'password',
            headerName: 'Mot de passe',
            type: 'password',
            hide: true,
            editable: false,
            add: true,
            edit: false,
          },
          {
            field: 'active',
            headerName: 'Etat du compte',
            type: 'checkbox',
            hide: true,
            editable: false,
            add: true,
            edit: true,
            flex: 1,
          },
        {
          field: "actions",
          headerName: "Actions",
          type: 'string',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfo.edit && !propsInfo.delete,
          width: 100,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
                let a = {...params.row, ...params.row.Client}
              setItem(a)
              setOpen(true);
            }}>
              { infoRole.editIcon ? infoRole.editIcon : <EditIcon />  }
            </div>}
            {propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
              deleteOne(params.row.id,params.row.Client?.gerant ?? "")
            }}>
              { infoRole.deleteIcon ? infoRole.deleteIcon : <DeleteIcon />  }
            </div>}
            </Box>;
          }
        }
      ]
      const deleteOne = (id : Number, name: string) => {
        Swal.fire({
          titleText: `Supprimer ${name}  ?`,
          text: `Souhaitez-vous vraiment supprimer ${name} ? Cette opération est irréversible.`,
          showCancelButton: true,
          cancelButtonText: `Annuler`,
          confirmButtonText: 'Supprimer',
          reverseButtons: true,
          customClass: {
            title: 'sw-delete-title',
            cancelButton: 'sw-delete-cancel',
            confirmButton: 'sw-delete-confirm',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete(`/api/auth/clients/${id}`,{withCredentials: true})
              .then((res) => {
                if (res.status === 200) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `${name} a bien été supprimé`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  setrefresh(!refresh)
                } else {
                  Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `${name} n'a pas été supprimé`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              })
              .catch((err) => {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `${name} n'a pas été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          } 
        })
        
      };
      
      const addOne = (values: any) => {
        axios.post('/api/auth/addClient', values, {withCredentials: true}).then((res) => {
          if (res.status === 201) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.raisonSocial} a bien été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          } else if (res.status === 400) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.raisonSocial} n'a pas été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
            console.log('bad req')
          }
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.raisonSocial} n'a pas été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((e)=> {
          console.log("error",e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.raisonSocial} n'a pas été ajouté`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
      const updateOne = (values: any) => {
        console.log(values)
        axios.put(`/api/auth/updateClient/${values.userId}`, values, {withCredentials: true}).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.raisonSocial} a bien été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          }
           else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.raisonSocial} n'a pas été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            if (res.status === 400) {
              console.log('bad req')
            }
           } 
        })
        .catch((e)=> {
          console.log("error",e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.raisonSocial} n'a pas été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
  return (
    <Box >
        <DataGridClients
            refreshParent={refresh}
            {...propsInfo}
            {...propsDialog}
            addFunction={addOne}
            editFunction={updateOne}
            fetchurl="/api/auth/clients"
            columns={columns}
            info={infoRole}
        />
    </Box>
  )
}

export default Clients