import { useState } from 'react'
import axios from 'axios'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, } from '@pankod/refine-mui'
import Swal from "sweetalert2"
import { Columns, Info, PropsInfo,PropsDialog } from 'interfaces/datagrid'
import DataGridUsers from 'components/Datagrids/DataGridUsers'
import { useCtxt } from 'contexts/app.context'
const infoRole: Info = {
    addText: 'Nouvelle utilisateur',
    addIcon: <AddIcon />,
    DialogTitle: 'Ajouter un utilisateur',
    DialogUpdate: "Modifier un utilisateur",
    DialogDescription: "La création d'un profile utilisateur et sa configuration",
    DialogUpdateDescription: "La modification d'un profile utilisateur et sa configuration"
  }
const Users = () => {
  const { ctxt:{user} } = useCtxt();
  const propsInfo: PropsInfo = {
    add: (user?.role === "admin" || user?.permission.gestionUtilisateurs[3]=== "1") ? true : false,
    delete: (user?.role === "admin" || user?.permission.gestionUtilisateurs[2]=== "1") ? true : false,
    edit: (user?.role === "admin" || user?.permission.gestionUtilisateurs[1]=== "1") ? true : false
  }
    const [refresh, setrefresh] = useState(false)
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<any>({});
    
    const propsDialog: PropsDialog = {
      openUpdate: open,
      setOpenUpdate: setOpen,
      item: item,
      setItem: setItem,
    }
    const columns: Columns[] = [
        { field: 'id', headerName: 'ID', type: 'number', hide: true },
        {
          field: 'last_name',
          headerName: 'Nom',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          width: 200
        },
        {
            field: 'first_name',
            headerName: 'Prénom',
            type: 'string',
            editable: false,
            add: true,
            edit: true,
            width: 200
          },
          {
            field: 'email',
            headerName: 'Email',
            type: 'string',
            editable: false,
            add: true,
            edit: true,
            width: 200
          },
        {
          field: 'phone_number',
          headerName: 'Numéro de téléphone',
          type: 'string',
          hide: true,
          editable: false,
          add: true,
          edit: true,
          width: 200
        },
        {
          field: 'roleId',
          headerName: 'Role',
          type: 'select',
          editable: false,
          add: true,
          edit: true,
          width: 200,
          renderCell: (params) => {
            return <Box sx={{background:"rgba(0, 0, 0, 0.08)",padding:"5px 10px", borderRadius:"16px"}}>
              {params.row.permission?.role}
            </Box>;
          }
        },
        {
          field: 'password',
          headerName: 'Mot de passe',
          type: 'password',
          hide: true,
          editable: false,
          add: true,
          edit: true,
          width: 200
        },
        
        {
          field: 'active',
          headerName: 'Etat du compte',
          type: 'checkbox',
          editable: false,
          add: true,
          edit: true,
          flex: 1,
          renderCell: (params) => {
            return <>
            {params.row.active && <Box sx={{background:"#92CE08", padding:"5px 10px", borderRadius:"16px", color:"#fff"}}>Actif</Box>}
            {!params.row.active && <Box sx={{background:"#EB3E3E", padding:"5px 10px", borderRadius:"16px", color:"#fff"}}>Inactif</Box>}
            </>
          }
        },
        {
          field: "actions",
          headerName: "Actions",
          type: 'string',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfo.edit && !propsInfo.delete,
          width: 100,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {
                propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
                  setItem(params.row)
                  setOpen(true);
                }}>
                  { infoRole.editIcon ? infoRole.editIcon : <EditIcon />  }
                </div>
              }
            {
              propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
                deleteOne(params.row.id,params.row.last_name)
              }}>
                { infoRole.deleteIcon ? infoRole.deleteIcon : <DeleteIcon />  }
              </div>
            }
            </Box>;
          }
        }
      ]
    const deleteOne= (id : Number, name: string) => {
      Swal.fire({
        titleText: `Supprimer ${name}  ?`,
        text: `Souhaitez-vous vraiment supprimer ${name} ? Cette opération est irréversible.`,
        showCancelButton: true,
        cancelButtonText: `Annuler`,
        confirmButtonText: 'Supprimer',
        reverseButtons: true,
        customClass: {
          title: 'sw-delete-title',
          cancelButton: 'sw-delete-cancel',
          confirmButton: 'sw-delete-confirm',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/auth/employees/${id}`,{withCredentials: true})
            .then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `${name} a bien été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
                setrefresh(!refresh)
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `${name} n'a pas été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${name} n'a pas été supprimé`,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } 
      })
        
      };
      const addOne = (values: any) => {
        console.log(values)
        axios.post('/api/auth/addEmployee', values, {withCredentials: true}).then((res) => {
          if (res.status === 201) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.first_name} a bien été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          }
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.first_name} n'a pas été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }).catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.first_name} n'a pas été ajouté`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
      const updateOne = (values: any) => {
        console.log(values)
        axios.put(`/api/auth/updateEmployee/${values.id}`, values, {withCredentials: true}).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.first_name} a bien été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          }
           else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.first_name} n'a pas été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            if (res.status === 400) {
              console.log('bad req')
            }
           } 
        }).catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.first_name} n'a pas été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
  return (
    <Box >
        <DataGridUsers
            refreshParent={refresh}
            {...propsInfo}
            {...propsDialog}
            addFunction={addOne}
            editFunction={updateOne}
            fetchurl="/api/auth/employees"
            columns={columns}
            info={infoRole}
            setrefreshParent={setrefresh}
        />
    </Box>
  )
}

export default Users