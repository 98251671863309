import { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { Box } from '@pankod/refine-mui'
import { Columns, Info, PropsInfo,PropsDialog } from 'interfaces/datagrid'
import DataGridMvtStock from 'components/Datagrids/DataGridMvtStock'
import { useCtxt } from 'contexts/app.context'
import moment from 'moment'
const infoRole: Info = {
    addText: 'Nouvelle opération',
    addIcon: <AddIcon />,
  }
 
const MvtStock = () => {
  const { ctxt:{user} } = useCtxt();
  const propsInfo: PropsInfo = {
    add: (user?.role === "admin" || user?.permission.mouvementStock[3]=== "1") ? true : false,
    delete: (user?.role === "admin" || user?.permission.mouvementStock[2]=== "1") ? true : false,
    edit: (user?.role === "admin") ? true : false
  }
    const [refresh, setrefresh] = useState(false)
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<any>({});
    
    const propsDialog: PropsDialog = {
      openUpdate: open,
      setOpenUpdate: setOpen,
      item: item,
      setItem: setItem,
    }
    const columns: Columns[] = [
        { field: 'id', headerName: 'N° ordre', type: 'number' },
        {
          field: 'type',
          headerName: "Type d'opération",
          type: 'string',
          editable: false,
          width: 200,
        },
        {
          field: 'date',
          headerName: 'Date',
          type: 'date',
          editable: false,
          width: 200,
          valueGetter: (params) => {
            return params.row.type === "Régularisation stock" ? moment(params.row.created_at).format("DD/MM/YYYY") : moment(params.row.date).format("DD/MM/YYYY")
          } 
        },
        {
          field: 'tiers',
          headerName: 'Tiers',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          width: 200,
          valueGetter: (params) => {
            switch (params.row.type) {
              case "Régularisation stock":
                return params.row.motif
              case "Expédition":
                return params.row.Client?.raisonSocial
              case "Réception":
                return params.row.fournisseur?.raisonSocial
              default :
                return ""
            }
          } 
        },
        {
          field: 'user',
          headerName: "Utilisateur",
          type: 'string',
          editable: false,
          flex: 1,
          valueGetter: (params) => {
            return `créer par ${params.row.user.first_name} ${params.row.user.last_name}`
          } 
        },
        {
          field: "actions",
          headerName: "Actions",
          type: 'string',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfo.edit,
          width: 100,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
            <div style={{"cursor":"pointer"}} onClick={() => {
              setItem(params.row)
              setOpen(true);
            }}>
              { infoRole.editIcon ? infoRole.editIcon : <EditIcon />  }
            </div>
            </Box>;
          }
        }
      ]
      
      const addOne = (values: any) => {
        console.log(values)
       
    
      }
      const updateOne = (values: any) => {
        console.log(values)
        
    
      }
  return (
    <Box >
        <DataGridMvtStock
            refreshParent={refresh}
            setrefreshParent={setrefresh}
            {...propsInfo}
            {...propsDialog}
            addFunction={addOne}
            editFunction={updateOne}
            fetchurl="/api/stock/mouvement"
            columns={columns}
            info={infoRole}
        />
    </Box>
  )
}

export default MvtStock