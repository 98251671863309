import * as React from 'react'
import axios from 'axios'
import { BACKENDINFO, IEtatStock, Columns } from 'interfaces/datagrid'
import Swal from 'sweetalert2'
import DeleteIcon from '@mui/icons-material/Delete'
import {  useForm, SubmitHandler } from "@pankod/refine-react-hook-form";
import {   object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, DataGrid,TextField, frFR,
  InputAdornment, FormControl, Select, MenuItem, InputLabel, Autocomplete, useAutocomplete, FormHelperText } from "@pankod/refine-mui";
const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
}

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        rows: [...action.payload],
        total: state.rows.length
      }
      case 'INIT': 
      return {
        page: 0,
        pageSize: 10,
        total: 20,
        filter: [],
        sort: [],
        rows: [],
        refresh: false,
      }
    default:
      return state
  }
}
const registerSchema = object({
  mouvement: string()
    .nonempty('Mouvement est obligatoire'),
    motif: string()
    .nonempty('Le gerant est obligatoire'),

})
type RegisterInput = TypeOf<typeof registerSchema>;
interface IProduit {
  id: number;
  name: string;
}
interface IClient {
  id: number;
  enStock: number;
  product: IProduit;

}
export default function DataGridProductsEtatStock({
  datagridProps,
  refreshParent,
  open,
  setOpen,
  title,
  setrefreshParent,
  item,
  setItem
}: IEtatStock) {
  const columns: Columns[] = [
    { field: 'id', headerName: 'N° ordre', type: 'number', hide: true },
    {
      field: 'name',
      headerName: "Nom",
      type: 'string',
      editable: false,
      width: 200,
      valueGetter: (params) => {
        return params.row.product?.name ?? ""
      } 
    },
    {
      field: 'quantite',
      headerName: "Quantité",
      type: 'number',
      editable: false,
      width: 200,
      valueGetter: (params) => {
        return params.row.enStock ?? 0
      } 
    },
    {
      field: 'mouvement',
      headerName: "Mouvement",
      type: 'number',
      editable: true,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: 'string',
      sortable: false,
      filterable: false,
      editable: false,
      width: 100,
      hide: false,
      renderCell: (params) => {
        return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
        <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
                let t =state.rows.filter((p) => p.id !== params.id)
                dispatch({ type: 'ROWS', payload: t })
              }}>
                 <DeleteIcon />  
              </div>
        </Box>;
      }
    }
  ]
  const [state, dispatch] = React.useReducer(reducer, initState)
  const [value, setValue] = React.useState("")
  const { autocompleteProps : autoCompleteProduits } = useAutocomplete<IClient>({
    resource: "stock/auto_complete",
    onSearch: (value) => [
      {
          field: "name",
          operator: "contains",
          value,
      },
  ],
    });
  
  const handleChange = (event: any) => {
    setStates({ ...states, [event.target.name]: event.target.value })
  }
  const [states, setStates] = React.useState<any>()
  const mouvementValues = [{id: 1,name:"Entrée"},
  {id: 2,name:"Sortie"}
]
const handleRecherche = (e: any) => {
  setValue(e.target.value)
}

const handleClose = () => {
  setOpen(false)
  dispatch({ type: 'INIT' })
  reset();
}

const {
  register,
  formState: { errors, isSubmitSuccessful },
  reset,
  handleSubmit,
} = useForm<RegisterInput>({
  resolver: zodResolver(registerSchema),
});
React.useEffect(() => {
  if (isSubmitSuccessful) {
    reset();
  }
}, [isSubmitSuccessful, reset]);
const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
  if ( Object.keys(errors).length === 0) {
    enregistrer(values)
    handleClose()
  } 
  
};
const enregistrer = (values : RegisterInput) => {
  let t = state.rows.map((p) => {
    return {nom: p.product?.name,quantity: p.enStock,mouvement: p.mouvement,productId: p.productId}
  })
  console.log(t)
  axios.post('/api/stock/mouvement', {
    type:"Régularisation stock",
    ...values,
    detailsMouvementStock: t

  }, {withCredentials: true}).then((res) => {
    if (res.status === 201){
      Swal.fire({
        position: "center",
        icon: "success",
        title: `le mouvement a bien été régulé`,
        showConfirmButton: false,
        timer: 1500,
      });
      setrefreshParent(!refreshParent)
    }
    else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `le stock n' pas été régulé`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  })
  .catch((error) => {
    console.log(error)
    if(error.response.status === 406) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `le mouvement doit être inférieur à la quantité du stock`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `le stock n' pas été régulé`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  })
}
const handleChangeUpdate = (event: any) => {
  setItem({ ...item, [event.target.name]: event.target.value });
};
React.useEffect(() => {
  if (Object.keys(item).length > 0){
    console.log(item)
    if (item.details_mouvement_stocks.length > 0) {
      let array = item.details_mouvement_stocks.map((pr : any) => {
        return {...pr, product:{name:  pr.nom}, enStock : pr.quantity}
      })
      dispatch({ type: 'ROWS', payload: array })
    }
    
  }
  
},[item])
const update = () => {
  let t = state.rows.map((p) => {
    return {nom: p.product?.name,quantity: p.enStock,mouvement: p.mouvement,productId: p.productId}
  })
  axios.put(`/api/stock/mouvement/${item.id}`, {
    type:"Régularisation stock",
    ...item,
    detailsMouvementStock: t

  }, {withCredentials: true}).then((res) => {
    if (res.status === 200){
      Swal.fire({
        position: "center",
        icon: "success",
        title: `la régularisation a bien été mis a jour`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `le régularisation n'a pas été mis a jour`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  })
  .catch((error) => {
    console.log(error)
    Swal.fire({
      position: "center",
      icon: "error",
      title: `le régularisation n'a pas été mis a jour`,
      showConfirmButton: false,
      timer: 1500,
    });
  })
  handleClose()
}
  return (
    <Dialog open={open} onClose={handleClose} className="stock-container">
            <DialogTitle sx={{ textTransform:"uppercase"}}>{title}</DialogTitle>
            {Object.keys(item).length > 0 && <>
            
            <DialogContent>
            
              <Box sx={{ display:"flex", flexDirection:"column", gap:"10px", marginTop: "10px", minWidth: 500}}>
              <>
                <FormControl fullWidth>
                    <InputLabel id="mouvement">Mouvement</InputLabel>
                      <Select
                      labelId='mouvement'
                        label="Mouvement"
                        name="mouvement"
                        value={item.mouvement}
                        onChange={handleChangeUpdate}
                      >
                        {mouvementValues.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                            )
                        })}
                        
                      </Select>
                </FormControl>
                <TextField
                    label="Motif"
                    type="string"
                    name="motif"
                    value={item.motif}
                    onChange={handleChangeUpdate}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                  />
                <Autocomplete
                  {...autoCompleteProduits}
                  getOptionLabel={(item) => JSON.stringify(item) }
                  renderOption={(option: any) => 
                  <Box sx={{padding:"5px 10px", cursor:"pointer", "&:hover": {
                    backgroundColor: "#E8FFFB",
                  }}}
                  onClick={() => {
                    let t = state.rows
                    let trv = false
                    t.map((p) => {
                      if (p.id === JSON.parse(option.key).id){
                        trv = true
                      }
                    })
                    if (!trv){
                      dispatch({ type: 'ROWS', payload: [...state.rows, {...JSON.parse(option.key), mouvement: 0}] })
                    }
                    
                  }}
                  >{JSON.parse(option.key).product?.name}</Box>
                }
                  placeholder="Choisir un produit"
                  renderInput={(params) => {
                    return <TextField
                    {...params}
                    name="produit"
                    label="Recherche"
                    onChange={handleRecherche}
                    variant="outlined"
                    />}}
              />
                
                <Box sx={{ height:400, width: '100%' }}>
                    
                  {/* The Data Grid Section */}
                  <DataGrid
                    sortingMode="server"
                    filterMode="server"
                    onFilterModelChange={(data) =>
                      dispatch({ type: 'FILTER', payload: data.items })
                    }
                    onSortModelChange={(data) =>
                      dispatch({
                        type: 'SORT',
                        payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
                      })
                    }
                    pagination
                    paginationMode="server"
                    onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
                    onPageSizeChange={(data) =>
                      dispatch({ type: 'PAGESIZE', payload: data })
                    }
                    onCellEditCommit={(params : any) => {
                      let t = state.rows.map((p) => {
                        if (p.id === params?.id){
                          p.mouvement =params?.value
                        }
                        return p
                      })
                      dispatch({ type: 'ROWS', payload: t })

                    }}
                    rows={state.rows}
                    rowCount={state.total}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    page={state.page}
                    columns={columns}
                    pageSize={state.pageSize}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    autoHeight={state.total < 5 ? true: false}
                    {...datagridProps}
                  />
                </Box>

                  

              </>
                
                
                </Box>
              
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
              <Button
                variant="contained"
                onClick={update}
              >
                Mettre a jour
              </Button>
            </DialogActions>
            </> }
            {Object.keys(item).length === 0 && <>
            {/*@ts-ignore */}
            <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}
              >
            <DialogContent>
            
              <Box sx={{ display:"flex", flexDirection:"column", gap:"10px", marginTop: "10px", minWidth: 500}}>
              <>
                <FormControl fullWidth>
                    <InputLabel id="mouvement">Mouvement </InputLabel>
                      <Select
                      labelId='mouvement'
                        label="Mouvement"
                        required
                        error={!!errors["mouvement"]}
                        {...register("mouvement")}
                      >
                        {mouvementValues.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                            )
                        })}
                        
                      </Select>
                      <FormHelperText error>{errors["mouvement"] && ` ${errors["mouvement"]?.message}`}</FormHelperText>
                </FormControl>
                <TextField
                    label="Motif"
                    type="string"
                    required
                    error={!!errors["motif"]}
                    helperText={errors["motif"] && ` ${errors["motif"]?.message}`}
                    {...register("motif")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{''}</InputAdornment>
                      ),
                    }}
                  />
                <Autocomplete
                  {...autoCompleteProduits}
                  getOptionLabel={(item) => JSON.stringify(item) }
                  renderOption={(option: any) => 
                  <Box sx={{padding:"5px 10px", cursor:"pointer", "&:hover": {
                    backgroundColor: "#E8FFFB",
                  }}}
                  onClick={() => {
                    let t = state.rows
                    let trv = false
                    t.map((p) => {
                      if (p.id === JSON.parse(option.key).id){
                        trv = true
                      }
                    })
                    if (!trv){
                      dispatch({ type: 'ROWS', payload: [...state.rows, {...JSON.parse(option.key), mouvement: 0}] })
                    }
                    
                  }}
                  >{JSON.parse(option.key).product?.name}</Box>
                }
                  placeholder="Choisir un produit"
                  renderInput={(params) => {
                    return <TextField
                    {...params}
                    name="produit"
                    label="Recherche"
                    onChange={handleRecherche}
                    variant="outlined"
                    />}}
              />
                
                <Box sx={{ maxHeight:400, width: '100%' }}>
                    
                  {/* The Data Grid Section */}
                  <DataGrid
                    sortingMode="server"
                    filterMode="server"
                    onFilterModelChange={(data) =>
                      dispatch({ type: 'FILTER', payload: data.items })
                    }
                    onSortModelChange={(data) =>
                      dispatch({
                        type: 'SORT',
                        payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
                      })
                    }
                    pagination
                    paginationMode="server"
                    onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
                    onPageSizeChange={(data) =>
                      dispatch({ type: 'PAGESIZE', payload: data })
                    }
                    onCellEditCommit={(params : any) => {
                      let t = state.rows.map((p) => {
                        if (p.id === params?.id){
                          p.mouvement =params?.value
                        }
                        return p
                      })
                      dispatch({ type: 'ROWS', payload: t })

                    }}
                    rows={state.rows}
                    rowCount={state.total}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    page={state.page}
                    columns={columns}
                    pageSize={state.pageSize}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    autoHeight={state.total < 5 ? true: false}
                    {...datagridProps}
                  />
                </Box>

                  

              </>
                
                
                </Box>
              
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
              <Button
                variant="contained"
                type="submit"
              >
                Enregistrer
              </Button>
            </DialogActions>
            </Box>
            </> }
            
          </Dialog>
    
    
  )
}
