import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  LightTheme,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-mui";
import { Title, Sider, Layout, Header } from "components/layout";
import { Login } from "pages/login";
import Settings from "pages/Settings";
import Dashboard from "pages/Dashboard";
import Users from "pages/Users";
import Clients from "pages/Clients";
import Tresorie from "pages/Tresorie";
import Produits from "pages/Produits"
import Fournisseurs from "pages/Fournisseurs";
import MvtStock from "pages/MvtStock";
import EtatStock from "pages/EtatStock";
import Commandes from "pages/Commandes";
import dataProvider from "@pankod/refine-simple-rest";
import { authProvider } from "./authProvider";
import routerProvider from "@pankod/refine-react-router-v6";
import { AppProvider } from "contexts/app.context";
import axios from "axios";

function App() {
  
  const axiosInstance = axios.create();
  axiosInstance.defaults.withCredentials = true
const overridedLightTheme = {
    ...LightTheme,
    palette: {
        ...LightTheme.palette,
        primary: {
            main: "#92CE08",
            contrastText: "#fff"
        },
        secondary: {
            main: "#fff",
            secondary: "#000",
            contrastText: "#000"
        },
        third: {
          main: "#F9A400"
        },
        error: {
          main: "#C80000",
          lighter: "#FFE8E8"
        },
        text: {
          disabled: "#c1c1c1",
          primary: "#000",
          secondary: "#373737",
        }
    },
};
  return (
    <ThemeProvider theme={overridedLightTheme}>
      <AppProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          /* @ts-ignore */
          dataProvider={dataProvider("https://freshfood.kbdev.co/api",axiosInstance)}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          resources={[
            {
              name: "dashboard",
              list: Dashboard,
            },
            {
              name: "commande",
              list: Commandes,
            },
            {
              name: "produits",
              list: Produits,
            },
            {
              name: "mouvementStock",
              list: MvtStock,
            },
            {
              name: "etatStock",
              list: EtatStock,
            },
            {
              name: "tresorerie",
              list: Tresorie,
            },
            {
              name: "clients",
              list: Clients,
            },
            {
              name: "fournisseurs",
              list: Fournisseurs,
            },
            {
              name: "users",
              list: Users,
            },
            {
              name: "parametres",
              list: Settings,
            },
          ]}
          Title={Title}
          Sider={Sider}
          Layout={Layout}
          Header={Header}
          routerProvider={routerProvider}
          authProvider={authProvider}
          LoginPage={Login}
        />
      </RefineSnackbarProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
