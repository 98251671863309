import * as React from 'react'
import { BACKENDINFO, Columns, IMvtStock } from 'interfaces/datagrid'
import DeleteIcon from '@mui/icons-material/Delete'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useCustom } from '@pankod/refine-core';
import Swal from 'sweetalert2';
import axios, { AxiosResponse } from 'axios'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, DataGrid,TextField, frFR,
   FormControl, Select, MenuItem, InputLabel, Autocomplete, useAutocomplete } from "@pankod/refine-mui";
const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
}

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        rows: [...action.payload],
        total: state.rows.length
      }
      case 'INIT': 
        return {
          page: 0,
          pageSize: 10,
          total: 20,
          filter: [],
          sort: [],
          rows: [],
          refresh: false,
        }
    default:
      return state
  }
}
interface IProduit {
  id: number;
  name: string;
  collisage: string;
  unit: string;
}
interface PostUniqueCheckResponse {
  isAvailable: boolean;
}
export default function DataGridProductsMvt({
  datagridProps,
  refreshParent,
  open,
  setOpen,
  type,
  title,
  item,
  setItem,
  setrefreshParent,

}: IMvtStock) {
  const columns: Columns[] = [
    { field: 'id', headerName: 'N° ordre', type: 'number', hide: true },
    {
      field: 'name',
      headerName: "Nom",
      type: 'string',
      editable: false,
      width: 200,
    },
    {
      field: 'unit',
      headerName: "Unité de mesure",
      type: 'string',
      editable: false,
      width: 200,
    },
    {
      field: 'collisage',
      headerName: "Colisage",
      type: 'string',
      editable: false,
      width: 200,
    },
    {
      field: 'quantity',
      headerName: "Quantité",
      type: 'number',
      editable: true,
      width: 200,
    },
    {
      field: "prix",
      headerName: type === 1 ? "Prix d'achat":"Prix de vente",
      type: 'number',
      editable: true,
      hide: type === 2,
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: 'string',
      sortable: false,
      filterable: false,
      editable: false,
      width: 100,
      hide: false,
      renderCell: (params) => {
        return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
        <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
                let t =state.rows.filter((p) => p.id !== params.id)
                dispatch({ type: 'ROWS', payload: t })
              }}>
                 <DeleteIcon />  
              </div>
        </Box>;
      }
    }
  ]
  const [date, setDate] = React.useState(null);
  const [state, dispatch] = React.useReducer(reducer, initState)
  const [commandes, setCommandes] = React.useState<any[]>([])
  const { data : fournisseurData } = useCustom<PostUniqueCheckResponse>({
    url: `/api/fournisseurs`,
    method: "get",
  });
  const { data : livreurData } = useCustom<PostUniqueCheckResponse>({
    url: `/api/auth/livreurs`,
    method: "get",
  });
  const { data : clientsData } = useCustom<PostUniqueCheckResponse>({
    url: `/api/auth/clients`,
    method: "get",
  });

  const { autocompleteProps : autoCompleteProduits } = useAutocomplete<IProduit>({
    resource: "products/dashboard/auto_complete",
    onSearch: (value) => [
      {
          field: "name",
          operator: "contains",
          value,
      },
  ],
    });
  const handleChange = (event: any) => {

    if (event.target.name ==="clientId"){
      setStates({ ...states, [event.target.name]: event.target.value.Client.id })
      axios.get(`/api/orders/user/${event.target.value.id}`,{withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          setCommandes(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
    }
    else if  (event.target.name ==="commandeId"){
      commandes.map((cmd) => {
        if (cmd.id === event.target.value) {
          dispatch({type: 'ROWS', payload: cmd.orders_details})
        }
      })
      setStates({ ...states, [event.target.name]: event.target.value })
    }
    else {
      setStates({ ...states, [event.target.name]: event.target.value })
    }
    
  }
  const [states, setStates] = React.useState<any>()
  
const handleRecherche = (e: any) => {
  console.log(e.target.value)
}

const handleClose = () => {
  setOpen(false)
  dispatch({ type: 'INIT' })
  setDate(null)
  setStates({})
}
const enregistrer = () => {
  if (type === 1) {
    let t = state.rows.map((p) => {
      return {nom: p.name,unit: p.unit,collisage: p.collisage,quantity:p.quantity,prix: p.prix, productId:p.id}
    })
    axios.post('/api/stock/mouvement', {
      type:"Réception",
      fournisseurId: states.fournisseurId,
      date: date,
      detailsMouvementStock: t
  
    }, {withCredentials: true}).then((res) => {
      if (res.status === 201){
        Swal.fire({
          position: "center",
          icon: "success",
          title: `le mouvement a bien été ajouté`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `le mouvement n' pas été ajouté`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({
        position: "center",
        icon: "error",
        title: `le mouvement n' pas été ajouté`,
        showConfirmButton: false,
        timer: 1500,
      });
    })
  }
  if (type === 2) {
    let t = state.rows.map((p) => {
      return {nom: p.name,unit: p.unit,collisage: p.collisage,quantity:p.quantity, productId:p.productId, prix: p.pricePromotion ? p.pricePromotion : p.price}
    })
    console.log(t)
    axios.post('/api/stock/mouvement', {
      type:"Expédition",
      date: date,
      ClientId: states.clientId,
      orderId: states.commandeId,
      livreurId: states.livreurId,
      detailsMouvementStock: t
  
    }, {withCredentials: true}).then((res) => {
      if (res.status === 201){
        Swal.fire({
          position: "center",
          icon: "success",
          title: `le mouvement a bien été ajouté`,
          showConfirmButton: false,
          timer: 1500,
        });
        setrefreshParent(!refreshParent)
      }
      else if (res.status === 406){
        Swal.fire({
          position: "center",
          icon: "error",
          title: `Quantité indisponible dans le stock`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `le mouvement n'a pas été ajouté`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({
        position: "center",
        icon: "error",
        title: `le mouvement n'a pas été ajouté`,
        showConfirmButton: false,
        timer: 1500,
      });
    })
  }
  handleClose()
}
const handleChangeUpdate = (event: any) => {
  setItem({ ...item, [event.target.name]: event.target.value });
};
React.useEffect(() => {
  if (Object.keys(item).length > 0){
    console.log(item)
    setDate(item.date)
    if (item.details_mouvement_stocks.length > 0) {
      let array = item.details_mouvement_stocks.map((pr : any) => {
        return {...pr, name: pr.nom}
      })
      dispatch({ type: 'ROWS', payload: array })
    }
  }
  
},[item])
const update = () => {
  let t = state.rows.map((p) => {
    return {nom: p.name,unit: p.unit,collisage: p.collisage,quantity:p.quantity,prix: p.prix, productId:(p.productId || p.id)}
  })
  console.log(t)
  axios.put(`/api/stock/mouvement/${item.id}`, {
    type:"Réception",
    fournisseurId: item.fournisseurId,
    date: date,
    detailsMouvementStock: t

  }, {withCredentials: true}).then((res) => {
    if (res.status === 200){
      Swal.fire({
        position: "center",
        icon: "success",
        title: `le mouvement a bien été mis a jour`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `le mouvement n'a pas été mis a jour`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  })
  .catch((error) => {
    console.log(error)
    Swal.fire({
      position: "center",
      icon: "error",
      title: `le mouvement n'a pas été mis a jour`,
      showConfirmButton: false,
      timer: 1500,
    });
  })
  handleClose()
}
  return (
    <Dialog open={open} onClose={handleClose} className="stock-container">
            <DialogTitle sx={{ textTransform:"uppercase"}}>{title}</DialogTitle>
            <>
            {Object.keys(item).length === 0 && <>
              <DialogContent>
            
            <Box sx={{ display:"flex", flexDirection:"column", gap:"10px", marginTop: "10px", minWidth: 500}}>
          <>
            {
                type === 1 && <>
                    <FormControl fullWidth>
                <InputLabel id="fournisseur">Fournisseur</InputLabel>
                  <Select
                  labelId='fournisseur'
                    name="fournisseurId"
                    label="Fournisseur"
                    onChange={handleChange}
                  >
                    {/*@ts-ignore */}
                    {fournisseurData?.data?.rows.map((v: any) => {
                        return (
                            <MenuItem key={v.id} value={v.id}>{v.raisonSocial}</MenuItem>
                        )
                    })}
                    
                  </Select>
                  </FormControl>
                     
                </>
              }
              {
                type === 2 && <>
                    <FormControl fullWidth>
                <InputLabel id="client">Client</InputLabel>
                  <Select
                  labelId='client'
                    name="clientId"
                    label="Client"
                    onChange={handleChange}
                  >
                    {/*@ts-ignore */}
                    {clientsData?.data?.rows.map((v: any) => {
                        return (
                            <MenuItem key={v.id} value={v}>{v.Client.raisonSocial}</MenuItem>
                        )
                    })}
                    
                  </Select>
                  </FormControl>
                  <FormControl fullWidth>
                <InputLabel id="commande">Commande</InputLabel>
                  <Select
                  labelId='commande'
                    name="commandeId"
                    label="Commande"
                    onChange={handleChange}
                  >
                    {commandes?.map((v: any) => {
                        return (
                            <MenuItem key={v.id} value={v.id}>{v.id}</MenuItem>
                        )
                    })}
                    
                  </Select>
                  </FormControl>
                  <FormControl fullWidth>
                <InputLabel id="livreur">Livreur</InputLabel>
                  <Select
                  labelId='livreur'
                    name="livreurId"
                    label="Livreur"
                    onChange={handleChange}
                  >
                    {/*@ts-ignore */}
                    {livreurData?.data?.map((v: any) => {
                        return (
                            <MenuItem key={v.id} value={v.id}>{v.first_name} {v.last_name}</MenuItem>
                        )
                    })}
                    
                  </Select>
                  </FormControl>
                     
                </>
              }
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={type === 2? "Date d'expédition":"Date de réception"}
                            value={date}
                            onChange={(newValue) => {
                            setDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
            {type === 1 && <Autocomplete
              {...autoCompleteProduits}
              getOptionLabel={(item) => JSON.stringify(item) }
              renderOption={(option: any) => 
              <Box sx={{padding:"5px 10px", cursor:"pointer", "&:hover": {
                backgroundColor: "#E8FFFB",
              }}}
              onClick={() => {
                let t = state.rows
                let trv = false
                t.map((p) => {
                  if (p.id === JSON.parse(option.key).id){
                    trv = true
                  }
                })
                if (!trv){
                  dispatch({ type: 'ROWS', payload: [...state.rows, {...JSON.parse(option.key), prix: 0, quantity: 0}] })
                }
                
              }}
              >{JSON.parse(option.key)?.name}</Box>
            }
              placeholder="Choisir un produit"
              renderInput={(params) => {
                return <TextField
                {...params}
                name="produit"
                label="Recherche"
                onChange={handleRecherche}
                variant="outlined"
                required
                />}}
          />}
            
            <Box sx={{ maxHeight:400, width: '100%' }}>
  
              {/* The Data Grid Section */}
              <DataGrid
                sortingMode="server"
                filterMode="server"
                onFilterModelChange={(data) =>
                  dispatch({ type: 'FILTER', payload: data.items })
                }
                onSortModelChange={(data) =>
                  dispatch({
                    type: 'SORT',
                    payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
                  })
                }
                pagination
                paginationMode="server"
                onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
                onPageSizeChange={(data) =>
                  dispatch({ type: 'PAGESIZE', payload: data })
                }
                onCellEditCommit={(params : any) => {
                  let t = state.rows.map((p) => {
                    if (p.id === params?.id){
                      p[params.field] =params?.value
                    }
                    return p
                  })
                  console.log(t)
                  dispatch({ type: 'ROWS', payload: t })

                }}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                rows={state.rows}
                rowCount={state.total}
                page={state.page}
                columns={columns}
                pageSize={state.pageSize}
                rowsPerPageOptions={[5, 10, 20, 50]}
                autoHeight={state.total < 5 ? true: false}
                {...datagridProps}
              />
            </Box>

              

          </>
            
            
            </Box>
            
          
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
          <Button
            variant="contained"
            onClick={enregistrer}
          >
            Enregistrer
          </Button>
        </DialogActions>
            </>}

            {Object.keys(item).length > 0 && <>
              <DialogContent>
            
            <Box sx={{ display:"flex", flexDirection:"column", gap:"10px", marginTop: "10px", minWidth: 500}}>
              <>
                {
                    type === 1 && <>
                        <FormControl fullWidth>
                    <InputLabel id="fournisseur">Fournisseur</InputLabel>
                      <Select
                      labelId='fournisseur'
                        name="fournisseurId"
                        label="Fournisseur"
                        value={item.fournisseurId}
                        onChange={handleChangeUpdate}
                      >
                        {/*@ts-ignore */}
                        {fournisseurData?.data?.rows.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.id}>{v.raisonSocial}{v.id}</MenuItem>
                            )
                        })}
                        
                      </Select>
                      </FormControl>
                         
                    </>
                  }
                  {
                    type === 2 && <>
                        <FormControl fullWidth>
                    <InputLabel id="client">Client</InputLabel>
                      <Select
                      labelId='client'
                        name="clientId"
                        label="Client"
                        value={item.clientId}
                        onChange={handleChangeUpdate}
                      >
                        {/*@ts-ignore */}
                        {clientsData?.data?.rows.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
                            )
                        })}
                        
                      </Select>
                      </FormControl>
                      <FormControl fullWidth>
                    <InputLabel id="commande">Commande</InputLabel>
                      <Select
                      labelId='commande'
                        name="commandeId"
                        label="Commande"
                        value={item.commandeId}
                        onChange={handleChangeUpdate}
                      >
                        {/*@ts-ignore */}
                        {clientsData?.data?.rows.map((v: any) => {
                            return (
                                <MenuItem key={v.id} value={v.id}>{v.name}</MenuItem>
                            )
                        })}
                        
                      </Select>
                      </FormControl>
                         
                    </>
                  }
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date de réception"
                                value={date}
                                onChange={(newValue) => {
                                setDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                {type === 1 && <Autocomplete
                  {...autoCompleteProduits}
                  getOptionLabel={(item) => JSON.stringify(item) }
                  renderOption={(option: any) => 
                  <Box sx={{padding:"5px 10px", cursor:"pointer", "&:hover": {
                    backgroundColor: "#E8FFFB",
                  }}}
                  onClick={() => {
                    let t = state.rows
                    let trv = false
                    t.map((p) => {
                      if (p.id === JSON.parse(option.key).id){
                        trv = true
                      }
                    })
                    if (!trv){
                      dispatch({ type: 'ROWS', payload: [...state.rows, {...JSON.parse(option.key), prix: 0, quantity: 0}] })
                    }
                    
                  }}
                  >{JSON.parse(option.key)?.name}</Box>
                }
                  placeholder="Choisir un produit"
                  renderInput={(params) => {
                    return <TextField
                    {...params}
                    name="produit"
                    label="Recherche"
                    onChange={handleRecherche}
                    variant="outlined"
                    required
                    />}}
              />}
                
                <Box sx={{ height:400, width: '100%' }}>
      
                  {/* The Data Grid Section */}
                  <DataGrid
                    sortingMode="server"
                    filterMode="server"
                    onFilterModelChange={(data) =>
                      dispatch({ type: 'FILTER', payload: data.items })
                    }
                    onSortModelChange={(data) =>
                      dispatch({
                        type: 'SORT',
                        payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
                      })
                    }
                    pagination
                    paginationMode="server"
                    onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
                    onPageSizeChange={(data) =>
                      dispatch({ type: 'PAGESIZE', payload: data })
                    }
                    onCellEditCommit={(params : any) => {
                      console.log(params)
                      let t = state.rows.map((p) => {
                        if (p.id === params?.id){
                          p[params.field] =params?.value
                        }
                        return p
                      })
                      console.log(t)
                      dispatch({ type: 'ROWS', payload: t })

                    }}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    rows={state.rows}
                    rowCount={state.total}
                    page={state.page}
                    columns={columns}
                    pageSize={state.pageSize}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    autoHeight={state.total < 5 ? true: false}
                    {...datagridProps}
                  />
                </Box>

                  

              </>
                
                
                </Box>
            
          
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
          <Button
            variant="contained"
            onClick={update}
          >
            Mettre a jour
          </Button>
        </DialogActions>
            </>  
          }
            </>

            
          </Dialog>
    
    
  )
}
