import * as React from 'react'
import { Box, DataGrid, GridRowId, Typography, Button, frFR } from "@pankod/refine-mui";
import axios, { AxiosResponse } from 'axios'
import { BACKENDINFO, IDataCommandes } from 'interfaces/datagrid'
import moment from 'moment'
import CardCommande from 'components/Cards/CardCommande';
import AttachMoney from '@mui/icons-material/AttachMoney';
const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
}

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      }
    case 'UPDATE': 
      return {
        ...state,
        rows: []
      }
    default:
      return state
  }
}

export default function DataGridCommandes({
  columns,
  datagridProps,
  fetchurl,
  refreshParent,
  validate,
  annuler,
  changeState

}: Partial<IDataCommandes>) {
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const [state, dispatch] = React.useReducer(reducer, initState)
  const [item, setItem] = React.useState<any>()
  const [refresh, setRefresh] = React.useState(false)
  const [prix, setPrix] = React.useState(0)
  const [stateChanged, setStateChanged] = React.useState(false)
  const getData = () => {
    
    axios
      .get(
        `${fetchurl}/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`, {withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          console.log(res.data)
          dispatch({ type: 'ROWS', payload: res.data })
          if (res.data.rows.length > 0){
            setItem(res.data.rows[0])
          }
          
        }
      })
      .catch((e) => {
        console.log(e)
      })
      
  }
  React.useEffect(() => {
    getData()
  }, [state.refresh, refreshParent])
  React.useEffect(() => {
  }, [refresh, refreshParent])
const handleDelete = (id: number) => {
  let t = item?.orders_details
  t.map((pr: any,index: number) => {
      if (pr.id === id) {
          t.splice(index, 1);
      }
      return 0
 })
 setItem({...item, orders_details: t})
 setRefresh(!refresh)
}
React.useEffect(() => {
  let prixTotal = item?.orders_details.reduce(
      (total : any, pr: any) =>{
          return (pr.pricePromotion ? pr.pricePromotion : pr.price)*pr.quantity + total
          },
      0
    );
   
  setPrix(prixTotal)
}, [item,refresh])

  return (
    <Box className="commandes-container" sx={{display:"flex", gap:"20px"}}>
        <Box sx={{ height:600, flex:"auto" }}>
        
        {/* The Data Grid Section */}
        <DataGrid
          sortingMode="server"
          filterMode="server"
          onFilterModelChange={(data) =>
            dispatch({ type: 'FILTER', payload: data.items })
          }
          onSortModelChange={(data) =>
            dispatch({
              type: 'SORT',
              payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
            })
          }
          pagination
          paginationMode="server"
          onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
          onPageSizeChange={(data) =>
            dispatch({ type: 'PAGESIZE', payload: data })
          }
          rows={state.rows}
          rowCount={state.total}
          page={state.page}
          columns={columns}
          pageSize={state.pageSize}
          onRowClick={(row : any) => {
            setItem(row.row)
            setRefresh(!refresh)
            setStateChanged(false)
          }}
          selectionModel={selectionModel}
          autoHeight={state.total < 5 ? true: false}
          onSelectionModelChange={(selection) => {
            if (selection.length > 1) {
              const selectionSet = new Set(selectionModel);
              const result = selection.filter((s) => !selectionSet.has(s));

              setSelectionModel(result);
            } else {
              setSelectionModel(selection);
            }
          }}
          rowsPerPageOptions={[5, 10, 20, 50]}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          {...datagridProps}
        />
      </Box>
      <Box sx={{ padding:"18px 20px", borderRadius:"10px", background: "#fff", width:"35%"}}>
          <Box sx={{ marginBottom:"30px"}}>
            <Box sx={{ display:"flex", justifyContent:"space-between"}}>
              <Typography>#COM{item?.id}</Typography>
              <Typography>Il y a {moment(moment().diff(moment(item?.createdAt))).format("d")} jours</Typography>
            </Box>
            <Box sx={{ display:"flex", justifyContent:"space-between"}}>
              <Typography>Client</Typography>
              <Typography>{item?.user?.last_name}</Typography>
            </Box>
            <Box sx={{ display:"flex", justifyContent:"space-between"}}>
              <Typography>Numéro</Typography>
              <Typography>{item?.user?.phone_number}</Typography>
            </Box>
            <Box sx={{ display:"flex", justifyContent:"space-between"}}>
              <Typography>Solde restant</Typography>
              <Typography>{item?.user?.Client?.soldeInitial} DA</Typography>
            </Box>
            <Box sx={{ display:"flex", justifyContent:"space-between"}}>
              <Typography>Adresse</Typography>
              <Typography>{item?.user?.Client?.adresse}</Typography>
            </Box>
          </Box>
          <Box sx={{display:"flex", flexDirection:"column", gap:"15px"}}>
          {
            item?.orders_details.map((product : any,index : number) => {
              return (
                <CardCommande key={product.id} product={product} index={index} decrease={() => {
                  if (item?.orders_details[index].quantity > 0){
                      let t = item?.orders_details
                    t[index].quantity -= 1
                    setItem({...item, orders_details: t})
                    setRefresh(!refresh)
                    setStateChanged(true)
                    }
              }}  increase={() => {
                let t = item?.orders_details
                  t[index].quantity += 1
                  setItem({...item, orders_details: t})
                  setRefresh(!refresh)
                  setStateChanged(true)
              }} 
              handleDelete={handleDelete}
                etat={item?.state} />
              )
            })
          }
          </Box>
          
        {item?.type !== "Livré" && <Box sx={{ display: "flex",gap: "10px", flexDirection:"column" }}>
          <Box sx={{ display: "flex", alignItems:"center", justifyContent: "space-between", background: "#ffffff", padding:"10px 15px",borderRadius:"10px"}}>
              <Typography sx={{textTransform: "uppercase", fontWeight: "bold", display:"flex", alignItems: "center"}}><AttachMoney /> SOUS-Total</Typography>
              <Typography>{`${prix} DA`}</Typography>
          </Box>
            <Button
            fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={item?.state !== "En attente" && item?.state !=="En expedition"}
              sx={{
              height: 40,
              borderRadius: "3px",
              fontSize: "13px",
              margin:"0 auto",
              fontWweight: "900",
              padding: "30px 46px"
              }}
              onClick={() => {
                if (!stateChanged) {
                  if (changeState) {
                    if (item?.state === "En attente"){
                      changeState(item?.id,"En préparation")
                    }
                    if (item?.state === "En expedition"){
                      changeState(item?.id,"Livrée")
                    }
                  }
                }
                else {
                  if (validate) validate({...item, totalPrice: prix})
                }
              }}
              >
                  {item?.state === "En expedition" ? "Livré la commande": "Valider la commande"}
             </Button>
             {(item?.state === "En attente" ||item?.state === "En préparation" || item?.state === "En expedition")  && <Button
            fullWidth
                variant="contained"
                size="large"
                color="secondary"
                sx={{
                  height: 40,
                  borderRadius: "3px",
                  fontSize: "13px",
                  margin:"0 auto",
                  fontWweight: "500",
                  padding: "30px 46px"
                  }}
                
                onClick={() => {
                  if (annuler) {
                    if (item?.state ==="En expedition"){
                      if (changeState) changeState(item?.id,"Retournée")
                    }
                    else {
                      annuler(item?.id)
                    }
                  }
                }}
            >
                {item?.state === "En expedition" ? "Retourné": "Annuler"}
            </Button>}
        </Box>}
      </Box>
    </Box>
  )
}
