import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

import {
  Stack,
  Typography,Box
} from "@pankod/refine-mui";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { useNavigate } from "@pankod/refine-react-router-v6";
import {  useCtxt } from "contexts/app.context";
import clients from "../../assets/icons/clients_icon.svg"
import commandes from "../../assets/icons/commande_icon.svg"
import etat from "../../assets/icons/etat_icon.svg"
import fournisseur from "../../assets/icons/fournisseur_icon.svg"
import mvt from "../../assets/icons/mvt_icon.svg"
import products from "../../assets/icons/products_icon.svg"
import settings from "../../assets/icons/settings_icon.svg"
import users from "../../assets/icons/users_icon.svg"
import tresorerie from "../../assets/icons/tresorerie_icon.svg"
const Navbar = () => {
  const { ctxt:{user} } = useCtxt();
    
    const navigate = useNavigate();
    const links = [
      {id: 1,name:"dashboard",label: "Dashboard", icon: etat, navigate: "/dashboard"},
      {id: 2,name:"commande",label: "Commande", icon: commandes, navigate: "/commande"},
      {id: 3,name:"produits",label: "Produit", icon: products, navigate: "/produits"},
      {id: 4,name:"mouvementStock",label: "Mouvement de stock", icon: mvt, navigate: "/mouvementStock"},
      {id: 5,name:"etatStock",label: "Etat de stock", icon: etat, navigate: "/etatStock"},
      {id: 6,name:"tresorerie",label: "Trésorerie", icon: tresorerie, navigate: "/tresorerie"},
      {id: 7,name:"clients",label: "Clients", icon: clients, navigate: "/clients"},
      {id: 8,name:"fournisseurs",label: "fournisseur", icon: fournisseur, navigate: "/fournisseurs"},
      {id: 9,name:"gestionUtilisateurs",label: "Gestion utilisateurs", icon: users, navigate: "/users"},
      {id: 10,name:"parametres",label: "paramètres", icon: settings, navigate: "/parametres"},
    ]
  return (
     <Stack sx={{ padding: "10px 20px"}}>
      <>
      <Box sx={{display:"flex", flexDirection:"column",gap:"10px", background:"#fff",padding:"12px 14px", margin:"10px 0", borderRadius:"10px"}}>
          {links.filter((l) => {
            if ( user?.role === "admin" || user?.permission[l.name][0]==="1"){
              return true;
            }
            return false
          }).map((link) => {return (
            <Box key={link.id} sx={{ display:"flex", gap:"10px", alignItems:"center", justifyContent: "space-between", padding:"13px 17px",borderRadius:"9px",background: window.location.pathname === link.navigate ? "#D4FFF7": "#fff",cursor:"pointer","&:hover": {
              backgroundColor: "#E8FFFB",
            },}} onClick={() => {navigate(`${link.navigate}`)}}>
              <Box sx={{display:"flex", gap:"10px"}}>
              <img src={link.icon} alt="icon image" />
              <Typography sx={{fontSize: "15px", fontWeight: "bold", width:"max-content", textTransform: "uppercase"}}>{link.label}</Typography>
              </Box>
              <KeyboardArrowRightOutlinedIcon />
          </Box>
          )})}
          
      </Box>
      </>
      
  </Stack>
  )
}

export default Navbar