import * as React from 'react'
import { Box, Button, DataGrid,frFR,GridToolbarContainer, GridToolbarExport } from "@pankod/refine-mui";
import axios, { AxiosResponse } from 'axios'
import { BACKENDINFO, IData } from 'interfaces/datagrid'
import DataGridProductsEtatStock from './DataGridProductsEtatStock';
const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      }

    default:
      return state
  }
}


export default function DataGridEtatStock({
  columns,
  datagridProps,
  info,
  add,
  edit,
  fetchurl,
  refreshParent,
  setrefreshParent
}: Partial<IData>) {
  
  

 


  const [state, dispatch] = React.useReducer(reducer, initState)
  
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }


const getData = () => {
  axios
    .get(
      `${fetchurl}/?filters=${JSON.stringify(
        state.filter
      )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
        state.page
      }`, {withCredentials: true}
    )
    .then((res: AxiosResponse<any, any>) => {
      if (res.status === 200) {
        dispatch({ type: 'ROWS', payload: res.data })
      }
    })
    .catch((e) => {
      console.log(e)
    })
}
React.useEffect(() => {
  getData()
}, [state.refresh, refreshParent])
  return (
    <Box sx={{ width: '100%' }}>
      {/* The Dialog Section */}
      {/* Dialog Button */}
      {info?.title && <h1>{info?.title}</h1>}
      {info?.description && <p>{info?.description}</p>}
      {add && (
        <>
          <Box sx={{ width:"100%",display: "flex", alignItems:"center",gap:"20px", justifyContent:"flex-end", margin:"15px 0", }}>
            <Box sx={{position:"relative"}}>
            <Button
                variant="contained"
                startIcon={info?.addIcon}
                onClick={handleClickOpen}
                color="secondary"
                sx={{borderRadius:"4px 4px 0 0", width:"100%"}}
            >
                {info?.addText || 'Ajouter'}
            </Button>
            </Box>
          </Box>
          
          <DataGridProductsEtatStock
                    refreshParent={refreshParent}
                    title={info?.DialogTitle}
                    open={open}
                    setOpen={setOpen}
                    setrefreshParent={setrefreshParent}
                    item={{}}
                    setItem={() => {}}
                />
          
        </>
      )}
      {/* The Data Grid Section */}
      <Box sx={{width:"100%",height:500}}>
      <DataGrid
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={(data) =>
          dispatch({ type: 'FILTER', payload: data.items })
        }
        onSortModelChange={(data) =>
          dispatch({
            type: 'SORT',
            payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
          })
        }
        pagination
        paginationMode="server"
        onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
        onPageSizeChange={(data) =>
          dispatch({ type: 'PAGESIZE', payload: data })
        }
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rows={state.rows}
        rowCount={state.total}
        page={state.page}
        columns={columns}
        pageSize={state.pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        autoHeight={state.total < 5 ? true: false}
        {...datagridProps}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      </Box>
    </Box>
  )
}
