import { useState } from 'react'
import axios from 'axios'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, } from '@pankod/refine-mui'
import Swal from "sweetalert2"
import { Columns, Info, PropsInfo,PropsDialog } from 'interfaces/datagrid'
import DataGridRole from 'components/Datagrids/DataGridRole'
import {  useCtxt } from "contexts/app.context";
const infoRole: Info = {
    addText: 'Ajouter Un role',
    addIcon: <AddIcon />,
    DialogTitle: 'Ajouter un role',
    DialogUpdate: "Modifier un role",
    DialogDescription: "La création d'un rôle et sa configuration",
    DialogUpdateDescription: "La création d'un rôle et sa configuration"
  }
  const links = [
    {id: 1,affichage: "Dashboard",label:"dashboard",  },
    {id: 2,affichage: "Commande",label:"commande",},
    {id: 3,affichage: "Produit",label:"produits", },
    {id: 4,affichage: "Mouvement de stock",label:"mouvementStock", },
    {id: 5,affichage: "Etat de stock",label:"etatStock",  },
    {id: 6,affichage: "Trésorie",label:"tresorerie", },
    {id: 7,affichage: "Clients",label:"clients" },
    {id: 8,affichage: "fournisseur",label:"fournisseurs" },
    {id: 9,affichage: "Gestion utilisateurs",label:"gestionUtilisateurs" },
    {id: 10,affichage: "paramètres",label:"parametres" },
  ]
const Role = () => {
  const { ctxt:{user} } = useCtxt();
  const propsInfo: PropsInfo = {
    add: (user?.role === "admin" || user?.permission.parametres[3]=== "1") ? true : false,
    delete: (user?.role === "admin" || user?.permission.parametres[2]=== "1") ? true : false,
    edit: (user?.role === "admin" || user?.permission.parametres[1]=== "1") ? true : false
  }
    const [refresh, setrefresh] = useState<boolean>(false)
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<any>({});
    
    const propsDialog: PropsDialog = {
      openUpdate: open,
      setOpenUpdate: setOpen,
      item: item,
      setItem: setItem,
    }
    const columns: Columns[] = [
        { field: 'id', headerName: 'ID', type: 'number', hide: true },
        {
          field: 'role',
          headerName: 'Nom de role',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          width: 200
        },
        {
            field: 'permissions',
            headerName: 'Permissions',
            type: 'autre',
            editable: false,
            sortable: false,
            filterable: false,
            add: false,
            edit: true,
            flex: 1,
            renderCell: (params) => {
                return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
                    {links.map((l : any) => {
                      if(params.row[l.label].includes("1")){
                        return (
                          <Box sx={{background:"rgba(0, 0, 0, 0.08)",padding:"5px 10px"}}>{l.affichage}</Box>
                      )
                      }
                      return false
                        
                    })}
                </Box>;
            }
        },
        {
          field: "actions",
          headerName: "Actions",
          type: 'string',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfo.edit && !propsInfo.delete,
          width: 100,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {propsInfo.edit && <div style={{"cursor":"pointer"}} onClick={() => {
              setItem(params.row)
              setOpen(true);
            }}>
              { infoRole.editIcon ? infoRole.editIcon : <EditIcon />  }
            </div>}
            {propsInfo.delete && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
              deleteOne(params.row.id, params.row.role)
            }}>
              { infoRole.deleteIcon ? infoRole.deleteIcon : <DeleteIcon />  }
            </div>}
            </Box>;
          }
        }
      ]
      const deleteOne = (id : Number, name: string) => {
        Swal.fire({
          titleText: `Supprimer ${name}  ?`,
          text: `Souhaitez-vous vraiment supprimer ${name} ? Cette opération est irréversible.`,
          showCancelButton: true,
          cancelButtonText: `Annuler`,
          confirmButtonText: 'Supprimer',
          reverseButtons: true,
          customClass: {
            title: 'sw-delete-title',
            cancelButton: 'sw-delete-cancel',
            confirmButton: 'sw-delete-confirm',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            axios.delete(`/api/roles/${id}`,{withCredentials: true})
              .then((res) => {
                if (res.status === 200) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `${name} a bien été supprimé`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  setrefresh(!refresh)
                } else {
                  Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `${name} n'a pas été supprimé`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              })
              .catch((err) => {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `${name} n'a pas été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          } 
        })
        
      };
      
      const addOne = (values: any) => {
        let permissions : any ={}
        links.map((l) => {
          let arr = values.v[l.id -1]
          arr.shift()
          permissions[l.label] = arr.join("")
          return false
        })
        permissions.role = values.states.role? values.states.role : ""
        console.log(permissions)
        axios.post('/api/roles', {...permissions}, {withCredentials: true}).then((res) => {
          if (res.status === 201) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${permissions.role} a bien été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          } else if (res.status === 400) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${permissions.role} n'a pas été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
            console.log('bad req')
          }
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${permissions.role} n'a pas été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
    
      }
      const updateOne = (values: any) => {
        console.log(values)
        let permissions : any ={}
        links.map((l) => {
          let arr = values.v[l.id -1]
          arr.shift()
          permissions[l.label] = arr.join("")
          return false
        })
        permissions.role = values.item.role? values.item.role : ""
        permissions.id = values.item.id
        console.log(permissions)
        axios.put(`/api/roles/${values.item.id}`, {...permissions}, {withCredentials: true}).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${permissions.role} a bien été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          }
           else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${permissions.role} n'a pas été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            if (res.status === 400) {
              console.log('bad req')
            }
           } 
        })
    
      }
  return (
    <Box >
        <DataGridRole
            refreshParent={refresh}
            setrefreshParent={setrefresh}
            {...propsInfo}
            {...propsDialog}
            addFunction={addOne}
            editFunction={updateOne}
            fetchurl="/api/roles"
            columns={columns}
            info={infoRole}
        />
    </Box>
  )
}

export default Role