import { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box,FormControl, InputLabel, Select, MenuItem, Typography } from '@pankod/refine-mui'
import Swal from "sweetalert2"
import { Columns, Info, PropsInfo,PropsDialog } from 'interfaces/datagrid'
import DataGridTresorie from 'components/Datagrids/DataGridTresorie'
import { useCtxt } from 'contexts/app.context'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import moment from 'moment'
import axios, { AxiosResponse } from 'axios'
import EditIcon from '@mui/icons-material/Edit'

const infoRole: Info = {
    addText: 'Nouvelle opération',
    addIcon: <AddIcon />,
    deleteIcon: <HighlightOffIcon />
  }
 
const Tresorie = () => {
  const { ctxt:{user} } = useCtxt();
  const propsInfo: PropsInfo = {
    add: (user?.role === "admin" || user?.permission.tresorerie[3]=== "1") ? true : false,
    delete: (user?.role === "admin" || user?.permission.tresorerie[2]=== "1") ? true : false,
    edit: (user?.role === "admin") ? true : false
  }
    const [refresh, setrefresh] = useState(false)
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<any>({});
    
    const propsDialog: PropsDialog = {
      openUpdate: open,
      setOpenUpdate: setOpen,
      item: item,
      setItem: setItem,
    }
    const columns: Columns[] = [
        { field: 'id', headerName: 'N° opération', type: 'number', width: 150 },
        {
          field: 'tiers',
          headerName: 'Tiers',
          type: 'string',
          editable: false,
          sortable: false,
          add: true,
          edit: true,
          width: 200,
          valueGetter: (params) => {
            switch (params.row?.type){
              case 'Dépense':
                return params.row?.beneficiaire
              case 'Paiement client':
                return params.row.Client?.raisonSocial
              case 'Remboursement client':
                return params.row.Client?.raisonSocial
              case 'Paiement fournisseur':
                return params.row.fournisseur?.raisonSocial
              case 'Remboursement fournisseur':
                return params.row.fournisseur?.raisonSocial
              case 'Dépôt':
                return params.row?.deposant
              case 'Virement de fonds':
                return "Virement de fonds"
              default:
                return ""
            }
          }
        },
        {
          field: 'titre',
          headerName: 'Titre',
          type: 'string',
          editable: false,
          add: true,
          edit: true,
          width: 200,
        },
        {
          field: 'type',
          headerName: 'Type',
          type: 'string',
          editable: false,
          width: 250,
        },
        {
          field: 'compte',
          headerName: 'Compte',
          type: 'select',
          editable: false,
          add: true,
          edit: true,
          width: 200,
          valueGetter: (params) => {
            return params.row.type === "Virement de fonds" ? `${params.row.compte?.name ?? ""} → ${params.row.compteDestination?.name ?? ""}`  :params.row.compte?.name ?? ""
          }
        },
        {
          field: 'montantRecu',
          headerName: 'Montant',
          type: 'number',
          editable: false,
          add: true,
          edit: true,
          width: 200,
          valueGetter: (params) => {
            return `${params.row.montantRecu > 0 ? params.row.montantRecu: params.row.montantEmis} DZ`
          }
        },
        {
          field: 'montantEmis',
          headerName: 'Montant Emis',
          type: 'number',
          hide: true,
          editable: false,
          add: true,
          edit: true,
          width: 200,
          valueGetter: (params) => {
            return `${params.row.montantEmis} DZ`
          }
        },
        {
          field: 'createdAt',
          headerName: 'Date',
          type: 'date',
          editable: false,
          width: 200,
          valueGetter: (params) => {
            return moment(params.row.createdAt).format("DD/MM/YYYY")
          }
        },
        {
          field: 'user',
          headerName: 'Utilisateur',
          type: 'string',
          editable: false,
          width: 200,
          valueGetter: (params) => {
            return params.row.user?.first_name ?? ""
          }
        },
        {
          field: 'etat',
          headerName: 'Etat',
          type: 'string',
          editable: false,
          width: 200,
          renderCell: (params) => {
            return <Box sx={{background: params.row?.etat=== "Enregistré" ? "#87BE07":"#FF4F4F", paddingLeft:"5px", color:"#fff", width:"100%", height:"100%", display:"flex",alignItems:"center"}}>
            {params.row?.etat}
              </Box>
          }
        },
        {
            field: 'modePaiment',
            headerName: 'mode de paiement',
            type: 'string',
            editable: false,
            add: true,
            edit: true,
            width: 200,
            hide: true,
          },
          {
            field: 'référencePaiement',
            headerName: 'référence de paiement',
            type: 'number',
            editable: false,
            add: true,
            edit: true,
            width: 150,
            hide: true,
          },
          
        {
          field: "actions",
          headerName: "Actions",
          type: 'string',
          sortable: false,
          filterable: false,
          editable: false,
          hide: !propsInfo.delete,
          width: 100,
          renderCell: (params) => {
            return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
              {
                propsInfo.edit && params.row?.etat=== "Enregistré" && <div style={{"cursor":"pointer"}} onClick={() => {
                  setItem(params.row)
                  setOpen(true);
                }}>
                  { infoRole.editIcon ? infoRole.editIcon : <EditIcon />  }
                </div>
              }
            {params.row?.etat=== "Enregistré" && <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
              deleteOne(params.row.id,params.row.titre ?? "")
            }}>
              { infoRole.deleteIcon ? infoRole.deleteIcon : <DeleteIcon />  }
            </div>}
            </Box>;
          }
        }
      ]
      const deleteOne = (id : Number, name: string) => {
        Swal.fire({
          titleText: `Annuler cette opération  ?`,
          text: `Souhaitez-vous vraiment annuler cette opération ? Cette opération est irréversible.`,
          showCancelButton: true,
          cancelButtonText: `Annuler`,
          confirmButtonText: 'Supprimer',
          reverseButtons: true,
          customClass: {
            title: 'sw-delete-title',
            cancelButton: 'sw-delete-cancel',
            confirmButton: 'sw-delete-confirm',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            axios.put(`/api/tresorerie/etat/${id}`,{},{withCredentials: true})
              .then((res) => {
                if (res.status === 200) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `${name} a bien été supprimé`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  setrefresh(!refresh)
                } else {
                  Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `${name} n'a pas été supprimé`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              })
              .catch((err) => {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `${name} n'a pas été supprimé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          } 
        })
        
      };
      
      const addOne = (values: any) => {
        console.log(values)
        axios.post('/api/tresorerie', values, {withCredentials: true}).then((res) => {
          if (res.status === 201) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.titre} a bien été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          }
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.titre} n'a pas été ajouté`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.titre} n'a pas été ajouté`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
      const updateOne = (values : any) => {
        console.log(values)
        axios.put(`/api/tresorerie/${values.id}`, values, {withCredentials: true}).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `${values.titre} a bien été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          }
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `${values.titre} n'a pas été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${values.titre} n'a pas été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
      }
      
      const [compteData, setCompteData] = useState<any>([])
      const getCompte = () => {
        axios.get("/api/comptes",{withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          setCompteData(res.data.rows)
        }
      })
      .catch((e) => {
        console.log(e)
      })
      }
      const [compte, setCompte] = useState<any>(0)
      useEffect(() => {
        getCompte()
        
      }, [ refresh])
      useEffect(() => {
        if (compteData.length > 0) setCompte(compteData[0])
        
        
      }, [ compteData])

  const handleChangeSelect = (event: any) => {
    setCompte(event.target.value);
  };
  
  return (
    <Box >
        <DataGridTresorie
            refreshParent={refresh}
            {...propsInfo}
            {...propsDialog}
            addFunction={addOne}
            editFunction={updateOne}
            fetchurl="/api/tresorerie"
            columns={columns}
            info={infoRole}
        />
      <Box sx={{marginTop: "100px", maxWidth:"300px", marginBottom:"50px"}}>
      <FormControl fullWidth>
        <InputLabel id="compte">Compte</InputLabel>
          <Select
          labelId='compte'
            name="compteId"
            label="Compte"
            value={compte}
            onChange={handleChangeSelect}
          >
            {/*@ts-ignore */}
            {compteData?.map((v: any) => {
                return (
                    <MenuItem key={v.id} value={v}>{v.name}</MenuItem>
                )
            })}
            
          </Select>
          </FormControl>
            <Box sx={{marginTop: "20px"}}>
              <Typography sx={{fontWeight:"bold", fontSize:"1.3rem"}}>Total : {compte?.balance} DA</Typography>              
            </Box>
      </Box>
    </Box>
  )
}

export default Tresorie