import {useState, useEffect} from 'react'
import {
    Box,
    Typography
  } from '@mui/material';
  import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
  import Add from '@mui/icons-material/Add';
  import Remove from '@mui/icons-material/Remove';
interface ICard  {
    product: any;
    index: number;
    etat: string;
    increase: any;
    decrease: any;
    handleDelete: any;
}
const CardCommande = ({index,product,etat,increase,decrease, handleDelete} : ICard) => {
  return (
    <Box sx={{ display:"flex",gap:"36px", alignItems:"center", width:"100%"}}>
        <Box sx={{ maxWidth:"120px"}}>
            <img src={`/uploads/${product.image}`} alt="product image" style={{width:"100%"}} />
        </Box>
        <Box sx={{flex: "auto", display: "flex", gap: "5px", flexDirection: "column", fontWeight: "bold"}}>
            <Typography fontWeight="bold">{product.name}</Typography>
            <Typography color="primary">{product.price} DZD</Typography>
            {etat === "En attente" && <Box sx={{ display: "flex", alignItems: "center", gap:"5px"}}>
                <Box sx={{width:"fit-content", background: "#92CE08", color: "#fff", padding: "6px 5px 0", cursor:"pointer", borderRadius: "4px"}} onClick={increase}>
                    <Add sx={{ fontSize: 15}} />
                </Box>
                <Box sx={{width: "20px", textAlign:"center"}}>
                <Typography>{`${product.quantity}`}</Typography>
                </Box>
                <Box sx={{width:"fit-content", background: "#92CE08", color: "#fff",padding: "6px 5px 0",cursor:"pointer", borderRadius: "4px"}} onClick={decrease}>
                    <Remove sx={{fontSize: 15}} />
                </Box>
            </Box>}
        </Box>
        {etat === "En attente" && <Box sx={{ display: "flex", gap: "5px"}}>
            <DeleteOutlinedIcon sx={{ cursor: "pointer"}} onClick={() => {
                handleDelete(product.id)
            }} />
        </Box>}
    </Box>
  )
}

export default CardCommande