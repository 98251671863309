import { useState } from 'react'
import axios from 'axios'
import { Box, Typography, } from '@pankod/refine-mui'
import Swal from "sweetalert2"
import { Columns, PropsInfo, } from 'interfaces/datagrid'
import DataGridCommandes from 'components/Datagrids/DataGridCommandes'
import { useCtxt } from 'contexts/app.context'
import stateAnnulee from "../assets/icons/stateAnnulee.svg"
import stateExpedition from "../assets/icons/stateExpedition.svg"
import stateLivre from "../assets/icons/stateLivre.svg"
import statePreparation from "../assets/icons/statePreparation.svg"
import stateAttente from "../assets/icons/stateAttente.svg"
import stateRetournee from "../assets/icons/stateRetournee.svg"

const Commandes = () => {
  const { ctxt:{user} } = useCtxt();
  const propsInfo: PropsInfo = {
    add:  false,
    delete: (user?.role === "admin" || user?.permission.commande[2]=== "1") ? true : false,
    edit: (user?.role === "admin" || user?.permission.commande[1]=== "1") ? true : false
  }
    const [refresh, setrefresh] = useState(false)
    
    const columns: Columns[] = [
        { field: 'id', headerName: 'N° commande', type: 'number', width: 150 },
        {
          field: 'user',
          headerName: 'Client',
          type: 'string',
          editable: false,
          width: 150,
          renderCell: (params) => {
            return <Box>
              {params.row.user.Client?.raisonSocial}
            </Box>;
          }
        },
        {
          field: 'quantite',
          headerName: 'Quantité',
          type: 'number',
          editable: false,
          width: 100,
          renderCell: (params) => {
            return <Box>
              {params.row.orders_details?.length} 
            </Box>;
          }
        },
        {
            field: 'totalPrice',
            headerName: 'Sous-total',
            type: 'string',
            editable: false,
            width: 150,
            renderCell: (params) => {
              return <Box>
                {params.row.totalPrice} DZD 
              </Box>;
            }
          },
          
        {
          field: 'state',
          headerName: 'Etat',
          type: 'string',
          editable: false,
          width: 150,
          renderCell: (params) => {
            switch (params.row.state){
              case 'En attente':
                return <Box sx={{display:"flex",gap:"5px"}}>
                  <img src={stateAttente} alt="state icon" />
                    <Typography sx={{color:"#E5B400", fontSize:"0.8rem"}}>{params.row.state}</Typography>
                </Box>
              case 'En préparation':
                return <Box sx={{display:"flex",gap:"5px"}}>
                <img src={statePreparation} alt="state icon" />
                  <Typography sx={{color:"#E56E00", fontSize:"0.8rem"}}>{params.row.state}</Typography>
              </Box>
              case 'Annulée':
                return <Box sx={{display:"flex",gap:"5px"}}>
                <img src={stateAnnulee} alt="state icon" />
                  <Typography sx={{color:"#E50000", fontSize:"0.8rem"}}>{params.row.state}</Typography>
              </Box>
              case 'Livrée':
                return <Box sx={{display:"flex",gap:"5px"}}>
                <img src={stateLivre} alt="state icon" />
                  <Typography sx={{color:"#E5B400", fontSize:"0.8rem"}}>{params.row.state}</Typography>
              </Box>
            case 'En expedition':
              return <Box sx={{display:"flex",gap:"5px"}}>
              <img src={stateExpedition} alt="state icon" />
                <Typography sx={{color:"#00BCE5", fontSize:"0.8rem"}}>En expédition</Typography>
            </Box>
            case 'Retournée':
              return <Box sx={{display:"flex",gap:"5px"}}>
              <img src={stateRetournee} alt="state icon" />
                <Typography sx={{color:"#E50000", fontSize:"0.8rem"}}>{params.row.state}</Typography>
            </Box>
              case '':
                return params.row.etat
              default:
                return ""
            }
          }
        },
      ]
      
    const annuler= (id : Number) => {
      Swal.fire({
        titleText: `Annuler commande ${id}  ?`,
        text: `Souhaitez-vous vraiment annuler la commande ${id} ? Cette opération est irréversible.`,
        showCancelButton: true,
        cancelButtonText: `Retour`,
        confirmButtonText: 'Annuler',
        reverseButtons: true,
        customClass: {
          title: 'sw-delete-title',
          cancelButton: 'sw-delete-cancel',
          confirmButton: 'sw-delete-confirm',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          axios.put(`/api/orders/etat/${id}`, {state: "Annulée"},{withCredentials: true})
            .then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: `La commande ${id} a bien été annulé`,
                  showConfirmButton: false,
                  timer: 1500,
                });
                setrefresh(!refresh)
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `Echou de mis a jour de la commande ${id}`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              console.log(err)
              Swal.fire({
                position: "center",
                icon: "error",
                title: `Echou de mis a jour de la commande ${id}`,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } 
      })
        
      };
      const validate = (values: any) => {
        console.log(values)
        axios.put(`/api/orders/dashboard/${values.id}`, values, {withCredentials: true}).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `La commande ${values.id} a bien été validé`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          } 
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `La commande ${values.id} n'a pas été mis a jour`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }).catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `La commande ${values.id} n'a pas été mis a jour`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
      const changeState = (id: number, state: string) => {
        axios.put(`/api/orders/etat/${id}`, {state: state}, {withCredentials: true}).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `La commande ${id} est ${state}`,
              showConfirmButton: false,
              timer: 1500,
            });
            setrefresh(!refresh)
          } 
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: `Echou de mis a jour de la commande ${id}`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }).catch((e) => {
          console.log(e)
          Swal.fire({
            position: "center",
            icon: "error",
            title: `Echou de mis a jour de la commande ${id}`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
    
      }
  return (
        <DataGridCommandes
            refreshParent={refresh}
            fetchurl="/api/orders"
            columns={columns}
            validate={validate}
            annuler={annuler}
            changeState={changeState}
        />
  )
}

export default Commandes