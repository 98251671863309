import {  useState } from "react";
import { useLogin } from "@pankod/refine-core";
import { Grid, Box, Stack, Button, Typography, Link,InputAdornment,TextField } from "@pankod/refine-mui";
import logo from "../assets/logo_large.svg"
import kb from "../assets/logo_kb_simple.svg"
import "../index.css"
import { CredentialResponse } from "../interfaces/google";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
export const Login: React.FC = () => {
  const { mutate: login } = useLogin<CredentialResponse>();
  const [states, setStates] = useState({});
  const handleChange = (event: any) => {
    setStates({ ...states, [event?.target?.name]: event?.target?.value });
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleSubmit = (e : any) => {
    e.preventDefault();
      login({...states})
    }
  return (
    <div className='login-container'>
      <Grid
        item
        xs={6}
        sx={{
          height: '100vh',
          //   position: 'fixed',
          right: 0,
          top: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: 200,
            height: 120,
            mb: 6,
          }}
        >
          <img src={logo} alt="ERP Logo" />
        </Box>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            mb: 6,
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center"}}>Vous n'avez pas de compte ?</Typography>
          <Link
            href="https://kb-web-kamb.vercel.app/"
            variant="h5"
            color="primary"
            underline='always'
            target="_blank"
            rel="noopener"
            align="center"
          >
            Appelez nous!
          </Link>
        </Stack>
        <Stack
          direction="column"
          spacing={3}
          // md={{
          //   width: 500,
          //   maxWidth: '100%'
          // }}
          sx={{
            width: 300,
            maxWidth: '100%',
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSubmit(e)
            }
          }}
        >
          <TextField
            required
              id="outlined-required"
              name="email"
              label="Email"
              placeholder="Email"
              type="text"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">{''}</InputAdornment>
                ),
              }}
              style={{backgroundColor: "#ffffff"}}
            />
            <TextField
            required
              id="outlined-required2"
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Mot de passe"
              label="Mot de passe"
              style={{backgroundColor: "#ffffff"}}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            sx={{
              height: 50,
            }}
            onClick={(e : any) => handleSubmit(e)}
          >
            S’identifier
          </Button>
        </Stack>
          <Box sx={{display: "flex", gap:"5px", color:"#BBBBBB"}}>
            <Typography>Développer par  </Typography>
            <Typography fontWeight="bold" >KB Développement</Typography>
          </Box>
          <Box
          sx={{
            mt: 6,
            display: "flex",
            flexDirection: "column"
          }}
          style={{alignItems: "center"}}
        >
          <Box sx={{
            width: 50,
          }}> 
            <img src={kb} alt="ERP Logo" />
          </Box>
          
          
        </Box>
        
        
      </Grid>
    </div>
  );
};
