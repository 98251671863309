
import { Box, Typography } from '@pankod/refine-mui'

import { useState } from 'react'

import Article from 'components/Settings/Article'
import Paiments from 'components/Settings/Paiments'
import Role from 'components/Settings/Role'


const Settings = () => {
   
  const [page, setPage] = useState(0)
  const pages =[{id: 0, label:"roles"},{id: 1, label:"paiements"},{id: 2, label:"articles"}]
  return (
    <Box sx={{width: "100%"}}>
        <Box sx={{width: "100%", display: "flex", background: "#ffffff"}}>
          {pages.map((p) => {return (
            <Box sx={{flex: 1,padding: "12px 0", cursor:"pointer" ,borderBottom:"2px solid", borderBottomColor: p.id === page ? "#92CE08":"#fff" }} key={p.id} onClick={() => {setPage(p.id)}}>
              <Typography sx={{ textTransform: "uppercase",fontWeight: "bold", textAlign: "center",color: p.id === page ? "#92CE08":"#00000099" }}>{p.label}</Typography>
            </Box>
          )})}
        </Box>
        <Box sx={{width: "100%"}}>
        {page === 0 && <Role />
          }
        {page === 1 && <Paiments />
          }
          {page === 2 && <Article />
          }
        </Box>
       
    </Box>
  )
}

export default Settings