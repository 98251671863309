import * as React from 'react'
import { Box, Button, DataGrid,
   frFR,GridToolbarContainer, GridToolbarExport } from "@pankod/refine-mui";
import axios, { AxiosResponse } from 'axios'
import { BACKENDINFO, IData, Columns } from 'interfaces/datagrid'
import DeleteIcon from '@mui/icons-material/Delete'
import DataGridProductsMvt from './DataGridProductsMvt';
import DataGridProductsEtatStock from './DataGridProductsEtatStock';
const initState: BACKENDINFO = {
  page: 0,
  pageSize: 10,
  total: 20,
  filter: [],
  sort: [],
  rows: [],
  refresh: false,
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'SORT':
      return {
        ...state,
        refresh: !state.refresh,
        sort: [...action.payload],
      }

    case 'FILTER':
      return {
        ...state,
        refresh: !state.refresh,

        filter: [...action.payload],
      }
    case 'PAGE':
      return {
        ...state,
        refresh: !state.refresh,
        page: action.payload,
      }
    case 'PAGESIZE':
      return {
        ...state,
        refresh: !state.refresh,
        pageSize: action.payload,
      }
    case 'ROWS':
      return {
        ...state,
        total: action.payload.count,
        rows: [...action.payload.rows],
      }

    default:
      return state
  }
}
export default function DataGridMvtStock({
  columns,
  datagridProps,
  info,
  add,
  edit,
  fetchurl,
  refreshParent,
  item,
  setItem,
  openUpdate,
  setOpenUpdate,
  setrefreshParent

}: Partial<IData>) {

  
  



  const [state, dispatch] = React.useReducer(reducer, initState)
  const [open, setOpen] = React.useState(false)
  const [refresh, setRefresh] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const [typeId, setTypeId] = React.useState<number>(1)
  const handleClickOpen = () => {
    setShow(true)
    //setOpen(true)
  }

  

  const getData = () => {
    
    axios
      .get(
        `${fetchurl}/?filters=${JSON.stringify(
          state.filter
        )}&sorts=${JSON.stringify(state.sort)}&size=${state.pageSize}&page=${
          state.page
        }`,{withCredentials: true}
      )
      .then((res: AxiosResponse<any, any>) => {
        if (res.status === 200) {
          console.log(res.data)
          dispatch({ type: 'ROWS', payload: res.data })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    getData()
  }, [state.refresh, refreshParent])
 
  const ops= [{id:1, nom:"Réception"},
  {id:2, nom:"Expédition"},
]
const boxRef = React.useRef();
React.useEffect(() => {
  window.onclick = (event) => {
    {/* @ts-ignore */}
    if ( event.target &&  event.target.contains(boxRef.current)
      && event.target !== boxRef.current) {     
      setShow(false)
    }
  }
}, []);



const cols: Columns[] = [
  { field: 'id', headerName: 'N° ordre', type: 'number', hide: true },
  {
    field: 'nom',
    headerName: "Nom",
    type: 'string',
    editable: false,
    width: 200,
  },
  {
    field: 'unite',
    headerName: "Unité de mesure",
    type: 'string',
    editable: false,
    width: 200,
  },
  {
    field: 'colisage',
    headerName: "Colisage",
    type: 'string',
    editable: false,
    width: 200,
  },
  {
    field: 'quantite',
    headerName: "Quantité",
    type: 'number',
    editable: true,
    width: 200,
  },
  {
    field: 'prix',
    headerName: "Prix d'achat",
    type: 'number',
    editable: true,
    flex: 1,
  },
  {
    field: "actions",
    headerName: "Actions",
    type: 'string',
    sortable: false,
    filterable: false,
    editable: false,
    width: 100,
    hide: false,
    renderCell: (params) => {
      return <Box sx={{ display: "flex", gap:"10px", alignItems:"center"}}>
      <div style={{"cursor":"pointer",color:"red"}} onClick={() => {
              
            }}>
               <DeleteIcon />  
            </div>
      </Box>;
    }
  }
]
  return (
    <Box sx={{ width: '100%' }}>
      {/* The Dialog Section */}
      {/* Dialog Button */}
      {info?.title && <h1>{info?.title}</h1>}
      {info?.description && <p>{info?.description}</p>}
      {add && (
        <>
          <Box sx={{ width:"100%",display: "flex", alignItems:"center",gap:"20px", justifyContent:"flex-end", margin:"15px 0", }}>
            <Box sx={{position:"relative"}}>
            <Button
                variant="contained"
                startIcon={info?.addIcon}
                onClick={handleClickOpen}
                color="secondary"
                sx={{borderRadius:"4px 4px 0 0", width:"100%"}}
            >
                {info?.addText || 'Ajouter'}
            </Button>
            {show && <Box ref={boxRef} sx={{display:"flex", width:"100%", flexDirection:"column",position:"absolute",top:"100%",right:"0%", zIndex:"100", background:"#fff", boxShadow:"-3px 4px 7px rgba(0, 0, 0, 0.25)", borderRadius:"0 0 10px 10px"}}>
              {ops.map((op) => {
                return (<Box key={op.id} sx={{padding:"10px 10px", textTransform:"capitalize", cursor:"pointer","&:hover": {
                  backgroundColor: "#E8FFFB",
                }}} onClick={() => {
                  setTypeId(op.id)
                  setOpen(true)
                  setShow(false)
                }}>
                  {op.nom}
                </Box>)
              })}
            </Box>}
            </Box>
          </Box>
          <DataGridProductsMvt
                    refreshParent={refresh}
                    setrefreshParent={setrefreshParent}
                    title={ops[typeId -1]?.nom}
                    open={open}
                    setOpen={setOpen}
                    type={typeId}
                    item={{}}
                    setItem={() => {}}
                />

          
        </>
      )}
      {/* Update dialog */}
      {edit && item.type !=="Régularisation stock" && <DataGridProductsMvt
                    refreshParent={refresh}
                    title={ops[typeId -1]?.nom}
                    open={openUpdate || false}
                    setOpen={setOpenUpdate}
                    type={typeId}
                    item={item}
                    setItem={setItem}
                />}
                {edit && item.type ==="Régularisation stock" && <DataGridProductsEtatStock
                    refreshParent={refresh}
                    title="Régularisation stock morad"
                    open={openUpdate || false}
                    setOpen={setOpenUpdate}
                    item={item}
                    setItem={setItem}
                />}
      {/* The Data Grid Section */}
      <Box sx={{width:"100%",height:500}}>
      <DataGrid
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={(data) =>
          dispatch({ type: 'FILTER', payload: data.items })
        }
        onSortModelChange={(data) =>
          dispatch({
            type: 'SORT',
            payload: [[data[0].field, data[0].sort?.toLocaleUpperCase()]],
          })
        }
        pagination
        paginationMode="server"
        onPageChange={(data) => dispatch({ type: 'PAGE', payload: data })}
        onPageSizeChange={(data) =>
          dispatch({ type: 'PAGESIZE', payload: data })
        }
        rows={state.rows}
        rowCount={state.total}
        page={state.page}
        columns={columns}
        pageSize={state.pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        {...datagridProps}
        autoHeight={state.total < 5 ? true: false}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      </Box>
    </Box>
  )
}
